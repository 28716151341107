<publicpage>
  <!-- <div class="my-search-box"> -->
  <!-- <p-fieldset legend="Search"> -->
  <!-- <p-card header="Search"> -->
  <div class="tile-header">Search</div>
  <div class="card-holder">

    <div class="barelist">
      <div>
        <div class="formgrid grid">
          <div class="col-12 sm:col-6 md:col-4 lg:col-2">Looking For</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-5">
            <p-selectButton [options]="genders" [(ngModel)]="selectedGender" optionLabel="name"
              optionValue="code"></p-selectButton>
          </div>
          <div class="col-5"></div>
        </div>
      </div>
      <br />
      <div>
        <div class="formgrid grid">
          <div class="col-12 sm:col-6 md:col-4 lg:col-2">Age</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-5">
            <p-dropdown [options]="startAge" [(ngModel)]="selectedStartAge" optionLabel="name" placeholder="From Age"
              [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="name"
              [showClear]="true"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-5">
            <p-dropdown [options]="endAge" [(ngModel)]="selectedEndAge" optionLabel="name" placeholder="End Age"
              [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="name"
              [showClear]="true"></p-dropdown>
          </div>
        </div>
      </div>
      <div>
        <br />
        <div class="formgrid grid">
          <div class="col-12 sm:col-6 md:col-4 lg:col-2">Height</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-5">

            <p-dropdown [options]="startHeight" [(ngModel)]="selectedStartHeight" optionLabel="name"
              placeholder="Start Height" [style]="{ width: '100%', overflow: 'visible' }" [filter]="true"
              filterBy="name" [showClear]="true"></p-dropdown>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-5">

            <p-dropdown [options]="endHeight" [(ngModel)]="selectedEndHeight" optionLabel="name"
              placeholder="End Height" [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="name"
              [showClear]="true"></p-dropdown>
          </div>
        </div>
      </div>
      <div>
        <br />
        <div class="formgrid grid">
          <div class="col-12 sm:col-6 md:col-4 lg:col-2">Community</div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-5">
            <p-selectButton [options]="community" [(ngModel)]="selectedCommunity" optionLabel="name"
              optionValue="code"></p-selectButton>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-2"></div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-3"></div>
        </div>
      </div>
      <div>
        <br />
        <div class="formgrid grid">
          <div class="col-12 sm:col-6 md:col-4 lg:col-2"></div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-6">
            <div class="field-checkbox">
              <p-checkbox [(ngModel)]="withPhoto" [binary]="true" inputId="forPhoto"></p-checkbox>
              <label for="forPhoto">{{ withPhotoText }}</label>
            </div>
          </div>
          <div class="col-12 sm:col-6 md:col-4 lg:col-4 text-right">
            <div *ngIf="resultCount > 0" class="result-count-display">
              Result Count: {{ resultCount }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <button pButton type="button" class="cb" (click)="search(true)" label="SEARCH"></button>
      </div>
    </div>


    <p-divider type="dotted" *ngIf="searchResult.length > 0"></p-divider>



    <!-- </p-card> -->
    <!-- </p-fieldset> -->
    <!-- </div> -->
  </div>


  <div>
    <!-- <ng-containter *ngIf="showLoader">
      <loader></loader>
    </ng-containter> -->
    <div *ngIf="searchResult.length > 0" class="grid-container">
      <div class="panel panel-default b-r-1">
        <div class="panel-heading my-title b-c-t-i remove-border">
          <h3 class="panel-title make-quick-sand-medium">SEARCH RESULT</h3>
        </div>
        <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
          <p-table [value]="searchResult" responsiveLayout="scroll" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" responsiveLayout="scroll" [rowsPerPageOptions]="[10, 25, 50]"
            [totalRecords]="totalRecords" (onPage)="onPageChange($event)" [lazy]="true" [first]="first"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
              <tr class="make-quick-sand-medium">
                <th pSortableColumn="CandidateName">
                  Name <p-sortIcon field="CandidateName"></p-sortIcon>
                </th>
                <th>Picture</th>
                <th pSortableColumn="Nationality">
                  Nationality <p-sortIcon field="Nationality"></p-sortIcon>
                </th>
                <th pSortableColumn="CandidateCity">
                  City <p-sortIcon field="CandidateCity"></p-sortIcon>
                </th>
                <th pSortableColumn="MemberShipType">
                  Membership Type
                  <p-sortIcon field="MemberShipType"></p-sortIcon>
                </th>
                <th pSortableColumn="CandidateDenomination">
                  Denomination
                  <p-sortIcon field="CandidateDenomination"></p-sortIcon>
                </th>
                <th pSortableColumn="CandidateHeight">
                  Height <p-sortIcon field="CandidateHeight"></p-sortIcon>
                </th>
                <th pSortableColumn="CandidateOccupation">
                  Occupation
                  <p-sortIcon field="CandidateOccupation"></p-sortIcon>
                </th>
                <th pSortableColumn="CandidateAge">
                  Age <p-sortIcon field="CandidateAge"></p-sortIcon>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-grid>
              <tr class="make-quick-sand-medium">
                <td>{{ grid.CandidateName }}</td>
                <td style="padding-left: 10px; width: 15%">
                  <img [src]="grid.RealImage" class="profile-image thumbnail-image-2" />
                </td>
                <td>{{ grid.Nationality }}</td>
                <td>{{ grid.CandidateCity }}</td>
                <td>{{ grid.MemberShipType }}</td>
                <td>{{ grid.CandidateDenomination }}</td>
                <td>{{ grid.CandidateHeight }}</td>
                <td>{{ grid.CandidateOccupation }}</td>
                <td>{{ grid.CandidateAge }}</td>
                <td>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
              <p-button type="button" styleClass="p-button-text"></p-button>
            </ng-template>
            <ng-template pTemplate="paginatorright">
              <p-button type="button" styleClass="p-button-text"></p-button>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="searchResult.length === 0 && noresult"> No Result</div>
      </div>
    </div>
    <div *ngIf="showLoader" class="overlay-loader">
      <div class="spinner"></div>
    </div>
  </div>
</publicpage>