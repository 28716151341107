import { Component, OnInit } from '@angular/core';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { RegisteredUser } from '../../view/view.components.domain';
import { ViewExternalService } from '../../view/view.component.service';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-viewotherprofile',
  templateUrl: './viewotherprofile.component.html',
  styleUrls: ['./viewotherprofile.component.sass']
})
export class ViewotherprofileComponent implements OnInit {
  public registeredUser: RegisteredUser;
  public ui: RegisteredUser;
  userloggedIn: string;
  userWhosInformationIsRequired: string;
  constructor(private commonservice: Commonservice, private viewExtService: ViewExternalService, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    this.commonservice.addCrib(false);
    const self = this;
    self.registeredUser = {} as RegisteredUser;
    self.userWhosInformationIsRequired = "";
    this._Activatedroute.params.subscribe(params => {
      self.userWhosInformationIsRequired = params['id'];
      //self.getUserInfo();
    });

  }
  async getUserInfo() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      this.viewExtService.getOtherUserProfile(this.userWhosInformationIsRequired, self.userloggedIn).subscribe(
        data => {
          self.run(data);
        },
        error => { }
      );
    });

  }
  public async run(data: any) {
    const self = this;
    self.ui = data;
  }
}
