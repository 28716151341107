import { Component, inject, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { SaveResponse } from "src/app/dto/SaveResponse";
import { GetEditProfileFamilyDto } from "src/app/dto/GetEditProfileFamilyDto";
import { Dropdown } from 'src/app/dto/Dropdown';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';

@Component({
  selector: 'app-edit-family-profile',
  templateUrl: './edit-family-profile.component.html',
  styleUrls: ['./edit-family-profile.component.css'],
  providers: [MessageService]
})
export class EditFamilyProfileComponent implements OnInit {
  @Input() epd: EditPageMegaBucket = {} as EditPageMegaBucket;
  ui: GetEditProfileFamilyDto;
  uic: EditPageMegaBucket;
  srm: SaveResponse;
  userloggedIn: string;
  showFatherCity = false;
  showFatherState = false;
  fatherCities: Dropdown[] = [];
  fatherStates: Dropdown[] = [];
  fatherCountries: Dropdown[] = [];
  fatherCountrySelectedValue: Dropdown;
  fatherCitySelectedValue: Dropdown;
  fatherStateSelectedValue: Dropdown;
  showMotherCity = false;
  showMotherState = false;
  mothersCities: Dropdown[] = [];
  mothersStates: Dropdown[] = [];
  mothersCountries: Dropdown[] = [];
  mothersCountrySelectedValue: Dropdown;
  mothersCitySelectedValue: Dropdown;
  mothersStateSelectedValue: Dropdown;
  fathersOccupation: Dropdown[] = [];
  mothersOccupation: Dropdown[] = [];
  fathersOccupationselectedValue: Dropdown;
  mothersOccupationselectedValue: Dropdown;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, public loadingService: LoaderService) { }

  ngOnInit(): void {
    const self = this;
    self.ui = {} as GetEditProfileFamilyDto;
    self.uic = self.epd;
    self.fathersOccupation = self.epd.Jobs;
    self.mothersOccupation = self.epd.Jobs;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.getDataFromDbCountry();
    });
  }
  getDataFromDbCountry() {
    const self = this;
    const ccn = self.commonService;
    this.loadingService.startLoading();
    ccn.getEditProfileFamily(self.userloggedIn).subscribe(
      {
        next: (s) => {
          self.uic.Countries = self.uic.Countries;
          self.fatherCountries = self.uic.Countries;
          self.mothersCountries = self.uic.Countries;
          self.fathersOccupation = self.uic.Jobs;
          self.mothersOccupation = self.uic.Jobs;
          self.ui = s;
          self.fatherCountries = self.uic.Countries;
          self.mothersCountries = self.uic.Countries;
          self.setSelected();
        },
        error: (error) => {
          // Handle error
        },
        complete: () => {
          this.loadingService.stopLoading();
        }
      }
    )
  }

  setSelected() {
    const self = this;

    if (self.ui.Fathers_Country != undefined) {
      let countrySelectedValueFromDb = self.uic.Countries.find(x => x.Value.toString() === self.ui.Fathers_Country.toString());
      self.fatherCountrySelectedValue = self.ui.Fathers_Country === undefined ? self.uic.Countries[0] : countrySelectedValueFromDb;
      self.loadStatesFather(self.fatherCountrySelectedValue?.Value);
    }
    else {
      self.fatherCountrySelectedValue = self.uic.Countries.find(x => x.Value === 0);
    }

    if (self.ui.Mothers_Country != undefined) {
      let countrySelectedValueFromDbMt = self.uic.Countries.find(x => x.Value.toString() === self.ui.Mothers_Country.toString());
      self.mothersCountrySelectedValue = self.ui.Mothers_Country === undefined ? self.uic.Countries[0] : countrySelectedValueFromDbMt;
      self.loadStatesMother(self.mothersCountrySelectedValue?.Value);
    }
    else {
      self.mothersCountrySelectedValue = self.uic.Countries.find(x => x.Value === 0);
    }

    if (self.ui.Father_Occupation != undefined) {
      let fathersOccupationSelected = self.uic.Jobs.find(x => x.Value.toString() === self.ui.Father_Occupation.toString());
      self.fathersOccupationselectedValue = self.ui.Father_Occupation === undefined ? self.uic.Jobs[0] : fathersOccupationSelected;
    }
    else {
      self.fathersOccupationselectedValue = self.uic.Jobs.find(x => x.Value === 0);
    }

    if (self.ui.Mother_Occupation != undefined) {
      let mothersOccupationSelected = self.uic.Jobs.find(x => x.Value.toString() === self.ui.Mother_Occupation.toString());
      self.mothersOccupationselectedValue = self.ui.Mother_Occupation === undefined ? self.uic.Jobs[0] : mothersOccupationSelected;
    }
    else {
      self.mothersOccupationselectedValue = self.uic.Jobs.find(x => x.Value === 0);
    }
  }

  onFatherCountryChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.fatherCountrySelectedValue = event.value;
      ccn.getStatesBasedOnCountry(self.fatherCountrySelectedValue.Value.toString()).subscribe(x => {
        if (x?.length > 0) {
          self.showFatherState = true;
          self.uic.States = [];
          self.uic.States.push(...x);
          self.fatherStates = [];
          self.fatherStates.push(...x);
          if (self.ui.Fathers_State != undefined) {
            let stateSelectedValueFromDb = self.uic.States.find(x => x.Value.toString() === self.ui.Fathers_State.toString());
            self.fatherStateSelectedValue = self.ui.Fathers_State === undefined ? self.uic.States[0] : stateSelectedValueFromDb;
          }
          else {
            self.fatherStateSelectedValue = self.uic.States.find(x => x.Value === 0);
          }
        }
        else {
          self.showFatherState = false;
        }
      });
    }
    else {
      self.fatherStateSelectedValue = { Name: "Select", Value: 0 };
      self.fatherCitySelectedValue = { Name: "Select", Value: 0 };
      self.showFatherState = false;
      self.showFatherCity = false;
    }
  }

  onMotherCountryChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.mothersCountrySelectedValue = event.value;
      ccn.getStatesBasedOnCountry(self.mothersCountrySelectedValue.Value.toString()).subscribe(x => {
        if (x?.length > 0) {
          self.showFatherState = true;
          self.uic.States = [];
          self.uic.States.push(...x);
          self.mothersStates = [];
          self.mothersStates.push(...x);
          if (self.ui.Mothers_State != undefined) {
            let stateSelectedValueFromDb = self.uic.States.find(x => x.Value.toString() === self.ui.Mothers_State.toString());
            self.mothersStateSelectedValue = self.ui.Mothers_State === undefined ? self.uic.States[0] : stateSelectedValueFromDb;
          }
          else {
            self.mothersStateSelectedValue = self.uic.States.find(x => x.Value === 0);
          }
        }
        else {
          self.showMotherState = false;
        }
      });
    }
    else {
      self.mothersStateSelectedValue = { Name: "Select", Value: 0 };
      self.mothersCitySelectedValue = { Name: "Select", Value: 0 };
      self.showMotherState = false;
      self.showMotherCity = false;
    }
  }

  onFatherStateChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.fatherStateSelectedValue = event.value;
      if (self.fatherStateSelectedValue != undefined) {
        ccn.getCitiesBasedOnState(self.fatherStateSelectedValue.Value.toString(), self.fatherCountrySelectedValue.Value.toString()).subscribe(x => {
          self.fatherCities = [];
          if (x.length > 0) {
            self.uic.Cities = [];
            self.uic.Cities.push(...x);
            self.fatherCities = [];
            self.fatherCities.push(...x);
            if (self.ui.Fathers_City != undefined) {
              let citySelectedValueFromDb = self.uic.Cities.filter(x => x.Value.toString() === self.ui.Fathers_City.toString())[0];
              self.fatherCitySelectedValue = self.ui.Fathers_City === undefined ? self.uic.Cities[0] : citySelectedValueFromDb;
            }
            else {
              self.fatherCitySelectedValue = self.uic.Cities.filter(x => x.Value === 0)[0];
            }

            self.showFatherState = true;
          }
          else {
            self.showFatherState = false;
          }
        });
      }
    }
    else {
      self.fatherCitySelectedValue = { Name: "Select", Value: 0 };
      self.showFatherState = false;
    }
  }

  onMotherStateChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.mothersStateSelectedValue = event.value;
      if (self.mothersStateSelectedValue != undefined) {
        ccn.getCitiesBasedOnState(self.mothersStateSelectedValue.Value.toString(), self.mothersCountrySelectedValue.Value.toString()).subscribe(x => {
          self.mothersCities = [];
          if (x.length > 0) {
            self.uic.Cities = [];
            self.uic.Cities.push(...x);
            self.mothersCities = [];
            self.mothersCities.push(...x);
            if (self.ui.Mothers_City != undefined) {
              let citySelectedValueFromDb = self.uic.Cities.filter(x => x.Value.toString() === self.ui.Mothers_City.toString())[0];
              self.mothersCitySelectedValue = self.ui.Mothers_City === undefined ? self.uic.Cities[0] : citySelectedValueFromDb;
            }
            else {
              self.mothersCitySelectedValue = self.uic.Cities.filter(x => x.Value === 0)[0];
            }

            self.showMotherState = true;
          }
          else {
            self.showMotherState = false;
          }
        });
      }
    }
    else {
      self.mothersCitySelectedValue = { Name: "Select", Value: 0 };
      self.showMotherState = false;
    }
  }

  loadStatesFather(country) {
    const self = this;
    if (country) {
      self.commonService.getStatesBasedOnCountry(country).subscribe(x => {
        if (x?.length > 0) {
          self.showFatherState = true;
          self.uic.States = [];
          self.uic.States.push(...x);
          self.fatherStates = [];
          self.fatherStates.push(...x);
          if (self.ui.Fathers_State != undefined) {
            let stateSelectedValueFromDb = self.uic.States.filter(x => x.Value.toString() === self.ui.Fathers_State.toString())[0];
            self.fatherStateSelectedValue = self.ui.Fathers_State === undefined ? self.uic.States[0] : stateSelectedValueFromDb;
          }
          else {
            self.fatherStateSelectedValue = self.uic.States.filter(x => x.Value.toString() === '0')[0];
          }
          self.loadFatherCities(self.fatherCountrySelectedValue?.Value?.toString(), self.fatherStateSelectedValue?.Value?.toString());
        }
        else {
          self.showFatherState = false;
        }
      });
    }
  }

  loadFatherCities(country, state) {
    const self = this;
    const ccn = self.commonService;
    if (state != undefined && country != undefined) {
      ccn.getCitiesBasedOnState(state, country).subscribe(x => {
        if (x.length > 0) {
          self.uic.Cities = [];
          self.uic.Cities.push(...x);
          self.fatherCities = [];
          self.fatherCities.push(...x);
          if (self.ui.Fathers_City != undefined) {
            let citySelectedValueFromDb = self.uic.Cities.find(x => x.Value.toString() === self.ui.Fathers_City.toString());
            self.fatherCitySelectedValue = self.ui.Fathers_City === undefined ? self.uic.Cities[0] : citySelectedValueFromDb;
          }
          else {
            self.fatherCitySelectedValue = self.uic.Cities.find(x => x?.Value?.toString() === '0');
          }

          self.showFatherCity = true;
        }
        else {
          self.showFatherCity = false;
        }
      });
    }
  }

  loadStatesMother(country) {
    const self = this;
    if (country) {
      self.commonService.getStatesBasedOnCountry(country).subscribe(x => {
        if (x?.length > 0) {
          self.showMotherState = true;
          self.uic.States = [];
          self.uic.States.push(...x);
          self.mothersStates = [];
          self.mothersStates.push(...x);
          if (self.ui.Mothers_State != undefined) {
            let stateSelectedValueFromDb = self.uic.States.filter(x => x.Value.toString() === self.ui.Mothers_State.toString())[0];
            self.mothersStateSelectedValue = self.ui.Mothers_State === undefined ? self.uic.States[0] : stateSelectedValueFromDb;
          }
          else {
            self.mothersStateSelectedValue = self.uic.States.filter(x => x.Value.toString() === '0')[0];
          }
          self.loadMotherCities(self.mothersCountrySelectedValue?.Value?.toString(), self.mothersStateSelectedValue?.Value?.toString());
        }
        else {
          self.showMotherState = false;
        }
      });
    }
  }

  loadMotherCities(country, state) {
    const self = this;
    const ccn = self.commonService;
    if (state != undefined && country != undefined) {
      ccn.getCitiesBasedOnState(state, country).subscribe(x => {
        if (x.length > 0) {
          self.uic.Cities = [];
          self.uic.Cities.push(...x);
          self.mothersCities = [];
          self.mothersCities.push(...x);
          if (self.ui.Mothers_City != undefined) {
            let citySelectedValueFromDb = self.uic.Cities.find(x => x.Value.toString() === self.ui.Mothers_City.toString());
            self.mothersCitySelectedValue = self.ui.Mothers_City === undefined ? self.uic.Cities[0] : citySelectedValueFromDb;
          }
          else {
            self.mothersCitySelectedValue = self.uic.Cities.find(x => x?.Value?.toString() === '0');
          }

          self.showMotherCity = true;
        }
        else {
          self.showMotherCity = false;
        }
      });
    }
  }

  getDataFromDb() {
    const self = this;
    self.commonService.getEditProfileFamily(self.userloggedIn).subscribe(
      data => {
        self.ui = data;
      },
      error => { }
    );
  }

  saveInformation() {
    const self = this;
    let setEditProfileFamilyDto: GetEditProfileFamilyDto = {} as GetEditProfileFamilyDto;
    setEditProfileFamilyDto.Father_Name = self.ui.Father_Name;
    setEditProfileFamilyDto.Father_HouseName = self.ui.Father_HouseName;
    setEditProfileFamilyDto.Fathers_Country = self.fatherCountrySelectedValue?.Value ?? self.ui.Fathers_Country;
    setEditProfileFamilyDto.Fathers_State = self.fatherStateSelectedValue?.Value ?? self.ui.Fathers_State;
    setEditProfileFamilyDto.Fathers_City = self.fatherCitySelectedValue?.Value ?? self.ui.Fathers_City;
    setEditProfileFamilyDto.Mothers_Country = self.mothersCountrySelectedValue?.Value ?? self.ui.Mothers_Country;
    setEditProfileFamilyDto.Mothers_State = self.mothersStateSelectedValue?.Value ?? self.ui.Mothers_State;
    setEditProfileFamilyDto.Mothers_City = self.mothersCitySelectedValue?.Value ?? self.ui.Mothers_City;
    setEditProfileFamilyDto.Father_Occupation = self.fathersOccupationselectedValue?.Value ?? self.ui.Father_Occupation;
    setEditProfileFamilyDto.Mother_Name = self.ui.Mother_Name;
    setEditProfileFamilyDto.Mother_HouseName = self.ui.Mother_HouseName;
    setEditProfileFamilyDto.Mother_Occupation = self.mothersOccupationselectedValue?.Value ?? self.ui.Mother_Occupation;
    setEditProfileFamilyDto.Brother_Married = self.ui.Brother_Married;
    setEditProfileFamilyDto.Brothers_Unmarried = self.ui.Brothers_Unmarried;
    setEditProfileFamilyDto.Brother_Priest = self.ui.Brother_Priest;
    setEditProfileFamilyDto.Sisters_Married = self.ui.Sisters_Married;
    setEditProfileFamilyDto.Sisters_Unmarried = self.ui.Sisters_Unmarried;
    setEditProfileFamilyDto.Sisters_Nun = self.ui.Sisters_Nun;
    setEditProfileFamilyDto.Candidate_Username = self.userloggedIn;

    this.commonService.setEditFamilyDetails(setEditProfileFamilyDto).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        self.getDataFromDb();
      },
      error => { }
    );
  }
}
