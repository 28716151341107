<div class="barelist standard-font tp-wrapper">


  <div>
    <div class="barelist login-port" *ngIf="showLoginPage">
      <div>
        <div class="grid">
          <div class="col-4 sub-title text-center">Username</div>
          <div class="col-8 p-r-20">
            <input class="super-input-box" name="username" placeholder="username" [(ngModel)]="username"
              ngDefaultControl />
          </div>
        </div>
      </div>
      <div class="p-t-5">
        <div class="grid">
          <div class="col-4 sub-title text-center">Password</div>
          <div class="col-8 p-r-20">
            <input type="password" class="super-input-box" placeholder="password" [(ngModel)]="password" name="password"
              ngDefaultControl />
          </div>
        </div>
      </div>
      <div class="p-t-5">
        <div class="grid">
          <div class="col-4"></div>
          <div class="col-8">
            <div class="grid">
              <div class="col-3">
                <button (click)="loginHome()" label="Login" class="cb">Login</button>
              </div>
              <div class="col-2">
                <p-button label="Reset" [text]="true" (click)="cancel()" />
              </div>

              <div class="col-7 text-right">
                <button (click)="SendToRegister()" class="cb-green">Register</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!showLoginPage" class="barelist reset-port grid ">

      <div class="reset-header">
        Reset password link
      </div>

      <div class="col-12 pl-5">
        <input class="super-input-box" name="email" placeholder="Email" [(ngModel)]="email" #emailInput="ngModel"
          ngModel required email ngDefaultControl />
        <div *ngIf="emailInput.invalid && emailInput.touched" class="error-message">
          <span *ngIf="emailInput.errors?.required">Email is required.</span>
          <span *ngIf="emailInput.errors?.email">Please enter a valid email address.</span>
        </div>
      </div>
      <div class="col-5  pl-5 flex flex-row md:align-content-start align-content-center flex-wrap gap-3">
        <div class="flex align-items-center justify-content-center">
          <button (click)="submitReset($event)" label="Login" class="btn-login"
            [disabled]="emailInput.invalid">Send</button>
        </div>
        <div class="flex align-items-center justify-content-center">
          <button (click)="backToHome($event)" label="Login" class="btn-login">Login</button>
        </div>
      </div>


    </div>



    <div class="parent-div">
      <img src="../../../../assets/image/couple.webp" alt="" [ngClass]="currentClass">

    </div>
  </div>
  <p-toast position="bottom-right"></p-toast>