import { Component, OnInit } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GetViewProfilePostDto } from "src/app/dto/GetViewProfilePostDto";

@Component({
  selector: 'view-profile-post',
  templateUrl: './view-profile-post.component.html',
  styleUrls: ['./view-profile-post.component.css']
})
export class ViewProfilePostComponent implements OnInit {
  userloggedIn: string;
  ui: GetViewProfilePostDto;
  showLoader = true;

  constructor(private commonService: Commonservice, private localStore: LocalStore) {
  }


  ngOnInit() {
    const self = this;
    self.ui = {} as GetViewProfilePostDto;
    self.showLoader = true;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getViewProfilePost(self.userloggedIn).subscribe(
        data => {
          self.ui = data;
          self.showLoader = false;
        },
        error => {}
      );
    });
   
  }

}
