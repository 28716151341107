<ng-containter *ngIf="showLoader">
    <container-loader></container-loader>
  </ng-containter>
  
  <div class="demo-tab-content" *ngIf="!showLoader">
    <div class="flex">
      <div class="col-12">
        <div class="flex  table-container">
          <div class="col-3 text-left">
            Where you want to get the monthly directory by post
          </div>
          <div class="col-9 address">{{ ui?.Address || "--" }}</div>
        </div>
        <div class="flex  table-container">
          <div class="col-3 text-left">Pincode</div>
          <div class="col-9">{{ ui?.PinCode || "--" }}</div>
        </div>
        <div class="flex  table-container">
          <div class="col-3 text-left">Country</div>
          <div class="col-9">
            {{ ui?.Country || "--" }}
          </div>
        </div>
        <div class="flex  table-container">
          <div class="col-3 text-left">
            State
          </div>
          <div class="col-9">
            {{ ui?.State || "--" }}
          </div>
        </div>
        <div class="flex  table-container">
          <div class="col-3 text-left">
            City
          </div>
          <div class="col-9">
            {{ ui?.City || "--" }}
          </div>
        </div>
        <div class="flex  table-container">
          <div class="col-3 text-left">
            Contact No
          </div>
          <div class="col-9">
            {{ ui?.ContactNo || "--" }}
          </div>
        </div>
        <div class="flex  table-container">
          <div class="col-3 text-left">Mobile Number</div>
          <div class="col-9">{{ ui?.MobileNumber || "--" }}</div>
        </div>
        <div class="flex  table-container">
          <div class="col-3 text-left">Postal Region</div>
          <div class="col-9">
            {{ ui?.PostalRegion || "--" }}
          </div>
        </div>
      </div>
    </div>
  </div>