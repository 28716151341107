<page>
  <ng-template>
    <div style="padding-top: 16px">
      <p-fieldset legend="VIEW OTHER PROFILE">
        <div class="barelist">
          <div>
            <userboard></userboard>
          </div>
          <div>
            <usermenuboard></usermenuboard>
          </div>
          <div>
            <usertabboard></usertabboard>
          </div>
        </div>
      </p-fieldset>
    </div>
  </ng-template>
</page>