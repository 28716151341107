import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LambdaService } from '../servicecenter/LambdaService';
@Injectable()
export class MenuService {
  connect = "";
  constructor(private httpClient: HttpClient, private lambdaService: LambdaService) {}
  getMenuFromServer() {
    this.connect = this.lambdaService.getLambdaUrl('AuthChatApiCall');
    const theresult = this.httpClient.get(this.connect + '/api/Menu/getMenu');
    return theresult;
  }
}
