<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Candidate's Annual Income</div>
  </div>
  <div class="col-4">
    <!-- <input
      pInputText
      placeholder="Candidate's Annual Income"
      [(ngModel)]="ui.Candidate_Annual_Income"
      style="width: 100%"
    /> -->
    <p-dropdown [options]="candidateAnnualIncome" [(ngModel)]="candidateAnnualIncomeSelected" optionLabel="Name"
    [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
    placeholder="Select a job type"></p-dropdown>
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Candidate's Share Assets</div>
  </div>
  <div class="col-4">
    <input
      pInputText
      placeholder="Candidate's Share Assets"
      [(ngModel)]="ui.Candidate_Assets"
      style="width: 100%"
    />
  </div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">
      About Candidate (School/ College/institution Studied,
      Acheivements,Hobbies, Family Background, etc)
    </div>
  </div>
  <div class="col-4">
    <textarea
      pInputText
      placeholder="About Candidate (School/ College/institution Studied, Acheivements,Hobbies, Family Background, etc)"
      [(ngModel)]="ui.Candidate_Sibling_Details"
      style="width: 100%; height: 100px"
    ></textarea>
  </div>
</div>
<div class="flex py-1">
  <div class="col-10"></div>
  <div class="col-2 text-right">
    <button pButton (click)="saveInformation()">SAVE</button>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>