import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TabCommunicationService {
    private broadcastChannel: BroadcastChannel;

  constructor() {
    this.broadcastChannel = new BroadcastChannel('session_data_channel');
  }

  sendMessage(data: any) {
    this.broadcastChannel.postMessage(data);
  }

  setSessionData(data: any, key: string) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  getSessionData(key: string): any {
    const storedData = sessionStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  }
  
  broadcastAllSessionData() {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      const data = this.getSessionData(key);
      if (data) {
        this.sendMessage(data);
      }
    }
  }
}
