<div class="main-page-layout">  
  <ng-container [ngSwitch]="currentDeviceState">

    <ng-container *ngSwitchCase="'desktop-landscape'">
      <ng-container *ngTemplateOutlet="desktopLandscapeHeader"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'mobile-portrait'">
      <ng-container *ngTemplateOutlet="mobilePortraitHeader"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'tablet-portrait'">
      <ng-container *ngTemplateOutlet="mobilePortraitHeader"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="desktopLandscapeHeader"></ng-container>
    </ng-container>

  </ng-container>
  <div class="public-page-content my-background-rest-of-page">
    <div class="top-menu">
      <publictopmenu></publictopmenu>
    </div>
    <div class="home-page-content">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="public-page-footer">
    <ng-container [ngSwitch]="currentDeviceState">

      <ng-container *ngSwitchCase="'desktop-landscape'">
        <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'mobile-portrait'">
        <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'tablet-portrait'">
        <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
      </ng-container>

    </ng-container>
  </div>
</div>

<ng-template #mobilePortraitFooter>
</ng-template>

<ng-template #desktopLandscapeFooter>
  <publicpagefooter></publicpagefooter>
</ng-template>

<ng-template #mobilePortraitHeader>
  <div class="public-page-header">
    <div class="horizontal-row sub-page-header   second-left-col">
      <div class="block-1-mobile">
        <div class="vertical-list first-left-col">
          <div class="header-one-mobile">Sacred Hearts Matrimony</div>
          <div class="header-two-mobile">a Premium Christian Matrimony</div>
        </div>
      </div>
      <div class="block-2">
        <div class="vertical-list ">
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #desktopLandscapeHeader>
  <div class="public-page-header">
    <div class="horizontal-row sub-page-header   second-left-col">
      <div class="block-1">
        <div class="vertical-list first-left-col">
          <div class="header-one">Sacred Hearts Matrimony</div>
          <div class="header-two">a Premium Christian Matrimony</div>
        </div>
      </div>
      <div class="block-2">
        <div class="vertical-list ">
        </div>
      </div>
    </div>
  </div>
</ng-template>