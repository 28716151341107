import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { homeRoutes } from './private/dashboard/home/home.routes';
import { hBTRoute } from './private/dashboard/bookmark/bookmark.routes';
import { expressedInterestRecievedRoutes } from './private/expressinterest/recieved/recieved.routes';
import { expressedInterestSentRoutes } from './private/expressinterest/sent/sent.routes';
import { mIComponentRoute } from './private/mymessages/inbox/inbox.routes';
import { misComponentRoute } from './private/mymessages/sent/sent.routes';
import { pDPRoute } from './private/profile/deleteprofile/deleteprofile.routes';
import { pERoute } from './private/profile/editprofile/editprofile.routes';
import { mPRoute } from './private/profile/modifypassword/modifypassword.routes';
import { rRRoute } from './private/profile/reregister/reregister.routes';
import { vPRoute } from './private/profile/view/view.routes';
import { QuickSearchRoute } from './private/search/quicks/quicks.routes';
import { AdvancedSearchRoute } from './private/search/advanced/advanced.routes';
import { StoragevaultRoute } from './private/vault/storagevault/storagevault.routes';
import { publichomeRoutes } from './public/publichome/publichome.routes';
import { loginRoutes } from './public/login/login.routes';
import { publicsearchRoutes } from './public/publicsearch/public.search.route';
import { PublicregisterRoutes } from './public/publicregister/publicregister.route';
import { PublicpaymentoptionsRoutes } from './public/publicpaymentoptions/publicpaymentoptions.routes';
import { publicContactUsRoutes } from './public/publiccontactus/publiccontactus.route';
import { publicAboutusRoutes } from './public/publicaboutus/publicaboutus.route';
import { viewotherprofileRoute } from './private/profile/thirdeye/viewotherprofile/viewotherprofile.route';
import { SearchByProfileRoute } from './private/search/privatesearchbyprofile/privatesearchbyprofile.route';
import { publicsearchbyprofileRoutes } from './public/publicsearchbyprofile/publicsearchbyprofile.route';
import { mymesssagetrashinboxComponentRoute } from './private/mymessages/my-message-inbox-trash/my-message-inbox-trash.route';
import { mymesssagetrashsentComponentRoute } from './private/mymessages/my-message-sent-trash/my-message-sent-trash.route';
import { eIRTRoute } from './private/expressinterest/express-interest-recieved-trash/express-interest-recieved-trash.route';
import { eISTRoute } from './private/expressinterest/express-interest-sent-trash/express-interest-sent-trash.route';
import { SearchByIdRoute } from './private/search/search-by-id/search-by-id.routes';
import { passwordResetRoute } from './public/publicreset/publicreset.route';
import { membershipComponentRoute } from './private/membership/membership.router';

const routes: Routes = [ {
  path: '',
  redirectTo: '/welcome',
  pathMatch: 'full',
},
...homeRoutes,
...hBTRoute,
...expressedInterestRecievedRoutes,
...expressedInterestSentRoutes,
...mIComponentRoute,
...misComponentRoute,
...pDPRoute,
...pERoute,
...mPRoute,
...rRRoute,
...vPRoute,
...QuickSearchRoute,
...AdvancedSearchRoute,
...StoragevaultRoute,
...publichomeRoutes,
...loginRoutes,
...publicsearchRoutes,
...PublicregisterRoutes,
...PublicpaymentoptionsRoutes,
...publicContactUsRoutes,
...publicAboutusRoutes,
...viewotherprofileRoute,
...SearchByProfileRoute,
...publicsearchbyprofileRoutes,
...mymesssagetrashinboxComponentRoute,
...mymesssagetrashsentComponentRoute,
...eIRTRoute,
...eISTRoute,
...SearchByIdRoute,
...passwordResetRoute,
...membershipComponentRoute
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }