import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'modelwindow',
    templateUrl: './confirmModel.component.html',
    styleUrls: ['./model.component.css']
})
export class ConfirmModelComponent implements OnInit {
    @Input() dataToDisplay;
    @Input() deleteId;
    @Output() confirmaction = new EventEmitter<number>();
    questionToAsk: string;
    ngOnInit(): void {
        const self = this;
        self.questionToAsk = this.dataToDisplay;
    }

    show(idToDelete: number[]) {
        const self = this;
        const modal = document.getElementById('myModalConfirmModel');
        self.deleteId = idToDelete;
        modal.style.display = 'block';
    }

    hide() {
        const modal = document.getElementById('myModalConfirmModel');
        modal.style.display = 'none';
    }
    CallParentMethod(passedValue: number) {
        const self = this;
        self.confirmaction.next(passedValue);
    }

    closewindow() {
        const modal = document.getElementById('myModalConfirmModel');
        modal.style.display = 'none';
    }
}
