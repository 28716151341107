import { Component, ContentChild, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { PrefetchBackGroundService } from '../servicecenter/PrefetchBackGroundService';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, OnDestroy {
  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<any>;
  currentDevice: string;

  currentScreen: string = "";

  constructor(private pbgs: PrefetchBackGroundService, private deviceService: DeviceDetectorService) {
  }

  ngOnInit(): void {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize.bind(this));
    window.addEventListener('orientationchange', this.checkScreenSize.bind(this));
  }

  private checkScreenSize(): void {
    this.currentDevice = this.deviceService?.deviceType?.toLowerCase() + "-" + this.deviceService?.orientation?.toLowerCase();
    this.pbgs.storeResolutionRelatedData(this.currentDevice);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkScreenSize.bind(this));
    window.removeEventListener('orientationchange', this.checkScreenSize.bind(this));
  }
}


