import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagesToDelete } from "../inbox/MessagesToDelete";
import { ResponseFromUserModel } from "../inbox/ResponseFromUserModel";
import { DeleteModel } from "src/app/dto/DeleteModel";
import { ExpressInterestModel } from "src/app/dto/ExpressInterestModel";
import { ExpressInterestSendModel } from "src/app/dto/ExpressInterestSendModel";
import { ViewMessageModel } from "src/app/dto/ViewMessageModel";
import { ExpressInterestCarrier } from "src/app/dto/ExpressInterestCarrier";
import { AddBookmarkModel } from "src/app/dto/AddBookmarkModel";
import { MessageRecorded } from "src/app/dto/MessageRecorded";
import { ConfirmDeleteCarrier } from "src/app/dto/ConfirmDeleteCarrier";
import { ResponseFromViewMessageModel } from "src/app/dto/ResponseFromViewMessageModel";
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';

import { ConfirmModelComponent } from 'src/app/common/reusable/model/confirmModel/model.component';
import { DisplayModelComponent } from 'src/app/common/reusable/model/DisplayComponent/model.component';
import { ResponseModelComponent } from 'src/app/common/reusable/model/confirmWithResponse/model.component';
import { ConfirmModelYesOrNoComponent } from 'src/app/common/reusable/model/confirmYesOrNo/model.component';
import { confirmResponseModel } from 'src/app/common/reusable/model/confirmWithResponse/model.component.domain';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { HostmodelComponent } from 'src/app/common/reusable/model/hostmodel/hostmodel.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { ConfirmdeletemodelComponent } from 'src/app/common/reusable/model/confirmdeletemodel/confirmdeletemodel.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import { CommonFunc } from 'src/app/common/reusable/servicecenter/CommonFunc';
import { PrefetchBackGroundService } from 'src/app/common/reusable/servicecenter/PrefetchBackGroundService';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';

@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html',
  styleUrls: ['./sent.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class MyMessagesSentComponent implements OnInit {
  messageData: MessageRecorded[];
  sortedData: MessageRecorded[];
  selectedMessageItems: MessageRecorded[] = [];
  userId: string;
  userloggedIn: string;
  mtd: ExpressInterestModel;
  cdcm: ConfirmDeleteCarrier;
  imageToDisplay: HostWindowModel;
  questiontoask: string;
  idToDelete: number[];
  deleteModel: DeleteModel;
  responseFromUserModel: ResponseFromUserModel;
  selectAll: boolean;
  setting: {};
  selectionMode;
  vmsg: ViewMessageModel;
  initialPage: Object;
  @ViewChild(ConfirmModelComponent, { static: false }) modelWindow: ConfirmModelComponent;
  @ViewChild(DisplayModelComponent, { static: false }) displayWindow: DisplayModelComponent;
  @ViewChild(ResponseModelComponent, { static: false }) responsewindow: ResponseModelComponent;
  @ViewChild(ConfirmModelYesOrNoComponent, { static: false }) confirmyestornowindow: ConfirmModelYesOrNoComponent;
  @ViewChild(HostmodelComponent, { static: false }) imageModelWindow: HostmodelComponent;
  @ViewChild(ConfirmdeletemodelComponent, { static: false }) cmc: ConfirmdeletemodelComponent;
  displayedColumns = ['UserImage', 'UserFromName', 'userExpressedDate', 'messageStatus', 'ActionColumn'];
  dataSource: any;
  first = 0;
  rows = 10;
  showDeleteModel = false;
  showMessage = false;
  confirmationTitle: string = "Confirmation";
  expressInterestModel: ExpressInterestSendModel;
  urm: ResponseFromViewMessageModel;
  showLargeImage = false;
  showLoader = false;
  searchText: string = "";
  visibleColumns = [];
  showSearchIcon = true;
  sendMessageText: string;
  totalRecords: number = 0;
  rowsPerPage: number = 10;
  pagedRecords: MessageRecorded[] = [];
  currentDeviceState: string = "";


  constructor(private commonService: Commonservice, private commonFunc: CommonFunc, private localStore: LocalStore, private datePipe: DatePipe, private messageService: MessageService, private confirmationService: ConfirmationService, private router: Router, private signalStateService: SignalStateService, private loaderService: GlobalLoaderService) { }
  initiateInbox() {
    const self = this;
    self.messageData = [];
    this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.bindGrid();
    self.questiontoask = 'Are you sure you want to delete?';
    self.idToDelete = [];
    self.deleteModel = {} as DeleteModel;
    self.selectAll = false;
    self.responseFromUserModel = {} as ResponseFromUserModel;
    self.selectionMode = { selectionMode: ['row'] };
    self.imageToDisplay = {} as HostWindowModel;
    self.cdcm = {} as ConfirmDeleteCarrier;
    self.cdcm.id = 0;
    self.cdcm.show = false;
    self.vmsg = {} as ViewMessageModel;
    let columns = ["userTo", "UserToName", "age", "height", "christaintype", "userExpressedDate", "messageStatus", "messageStatusChangeDate"];
    self.visibleColumns.push(...columns);
  }

  bindGrid() {
    const self = this;
    this.loaderService.showLoader();
    this.commonService.getMessageSent(self.userloggedIn).subscribe(
      data => {
        self.run(data);
        this.loaderService.hideLoader();
      },
      error => { }
    );
  }

  run(data: any) {
    const self = this;
    self.messageData = [];
    self.sortedData = [];
    if (data !== undefined) {
      if (data.length > 0) {
        _.forEach(data, function (value, key) {
          let myData: MessageRecorded;
          myData = {} as MessageRecorded;
          if (value.messageStatusChangeDate !== "Not yet") {
            value.messageStatusChangeDate = self.datePipe.transform(Date.now(), "yyyy-MM-dd");
          }
          myData = value;
          myData.showIconEngine = true;
          myData.pathToProfile = "./../viewotherprofile"
          self.messageData.push(myData);
          self.sortedData.push(myData);

          self.totalRecords = self.sortedData.length;

          self.loadPageData(0, self.rowsPerPage);
        });
      }
      else {
        this.pagedRecords = [];
        self.totalRecords = 0;
      }
    }
  }

  loadPageData(page: number, rows: number) {
    const start = page * rows;
    const end = start + rows;
    this.pagedRecords = this.sortedData.slice(start, end);
  }

  onPageChange(event: PageEvent) {
    this.first = event.first; 
    this.rows = event.rows; 
    const page = Math.floor(this.first / this.rows);
    this.loadPageData(page, this.rows);
  }
  
  ngOnInit() {
    const self = this;
    self.messageData = [];
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();  
    self.initiateInbox();
  }

  viewMessage(row) {
    const self = this;
    self.vmsg = {} as ViewMessageModel;
    self.vmsg.show = true;
    self.showMessage = true;
    self.vmsg.header = "Interest received from " + row.UserFromName.toUpperCase();
    self.vmsg.modelContent = row.textMessageFromUser;
    self.vmsg.showHeader = true;
    self.vmsg.showFooter = true;
    self.vmsg.ToUser = row.userToID;
    self.vmsg.TableId = 1;
    self.vmsg.FromUser = row.userFromID;
    self.vmsg.modelFooter = "DATE EXPRESSED: " + self.datePipe.transform(row.userExpressedDate, "yyyy-MM-dd");
  }

  openSingleDeleteModal(event: Event, item) {
    const self = this;
    self.idToDelete = item.Id;
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        self.deleteOutBoxItem(self.idToDelete);
      }
    });
  }

  rebindGrid(event) {
    const self = this;
    self.bindGrid();
  }

  bookmarkprofile(row) {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: row.userFrom,
      UserWhoIsGettingBookmarked: row.userTo
    }
    this.loaderService.showLoader();
    self.commonService.AddBookMark(bookmark).subscribe(
      data => {
        this.loaderService.hideLoader();
        if (data.InsertWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
          // this.pgbs.updateHomePageBookMarkInStore();
          // this.pgbs.updateBookMarkInStore();
        }
        else {
          this.messageService.add({ severity: 'info', summary: 'Service Message', detail: data.Message });
        }
      },
      error => {

      }
    );
  }

  expressInterestPop(event: Event, row: any) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = row.userTo;
    eic.userToName = row.UserToName?.toUpperCase();
    self.mtd = self.commonService.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      }
    });
  }

  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data.InsertWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'info', summary: 'Service Message', detail: data.Message });
        }
      });
    }
  }

  openMultiDeleteModal() {
    const self = this;
    let idsToDelete: MessagesToDelete = {} as MessagesToDelete;
    idsToDelete.Ids = [];
    let member = self.selectedMessageItems.length > 1 ? "members" : "member";
    if (self.selectedMessageItems.length > 0) {
      self.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure that you want to delete selected ' + member + ' proposals?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          self.selectedMessageItems.forEach(element => {
            idsToDelete.Ids.push(element.Id);
          });
          self.commonService.deleteMultipleItemsFromMyMessageSent(idsToDelete).subscribe(
            data => {
              data.DeleteWorked ? this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message }) : this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
              self.bindGrid();
            }
          );
        },
        reject: () => {
        }
      });
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "please select at least one item to delete" });
    }
  }
  runNow() {
    const self = this;
    self.selectAll = false;
    self.bindGrid();
  }

  replyMessage(item) {
    const self = this;
    self.responsewindow.show(item.interestFromUserId);
  }

  responseToMessage(event: confirmResponseModel) {
    const self = this;
    self.responseFromUserModel.userOnlineFromWhomMessageIsBeingSend = self.userloggedIn;
    self.responseFromUserModel.user_To = event.id.toString();
    self.responseFromUserModel.user_ToID = event.reponse.toString();
    self.responseFromUserModel.user_ExpressedDate = new Date();
    self.responseFromUserModel.status = '1';
    self.responseFromUserModel.theTextBeingSend = event.reponse;

    self.commonService.responseMessageFromUser(self.responseFromUserModel).subscribe(
      () => {
        self.runNowResponseMessage();
      },
      error => { }
    );
  }

  runNowResponseMessage() {
    const self = this;
    // self.snackBar.open('Response recorded');
    self.bindGrid();
  }

  // sortData(sort: Sort) {
  //   this.sortedData = _.orderBy(this.messageData, [sort.active], [sort.direction]);
  // }

  deleteOutBoxItem(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.toString();
    self.commonService.deleteFromOutBox(deleteId).subscribe(
      data => {
        self.bindGrid();
        if (data.DeleteWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
        }
      }
    );

  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      this.loaderService.showLoader();
      self.commonService.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.showLargeImage = true;
          this.loaderService.hideLoader();
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => { }
      );
    }
    else {

    }
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.sortedData ? this.first === (this.sortedData.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.sortedData ? this.first === 0 : true;
  }

  acceptInterest(event: ViewMessageModel) {
    const self = this;
    self.urm = {} as ResponseFromViewMessageModel;
    self.urm.userbeingaccepted = event.FromUser;
    self.urm.userloggedin = event.ToUser;
    self.urm.acceptance = true;

    switch (event.TableId) {
      case 1:
        self.commonService.sendAcceptMyMesssage(self.urm).subscribe(resp => {
          if (resp.UpdateWorked) {
            this.messageService.add({ severity: 'success', summary: 'Service Message', detail: resp.Message });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: resp.Error });
          }
        });
        break;
      case 6:
        self.commonService.sendAcceptExpressInterest(self.urm).subscribe(resp => {
          // self.snackBar.open(resp.Message, "Ok", {
          //   duration: 5000,
          // }).onAction().subscribe(() => {
          //   self.mc.show = false;
          //   this.CallBack.next(self.mc.show);
          // });
        });
        break;

    }
  }

  navigateToDetails(tc: MessageRecorded) {
    let link = tc.pathToProfile + "/" + tc.userTo;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

  globalSearch(event) {
    const self = this;
    let columnsToFilter = self.visibleColumns;
    self.sortedData = self.commonFunc.searchText(self.messageData, event, columnsToFilter);
  }

  hasNonNullValues(columnName: string): boolean {
    return this.sortedData?.some(item => item[columnName] != null);
  }

  getData(row: any, column: string): any {
    return row[column];
  }

  isDate(value: any): boolean {
    return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));
  }

  triggerParentRefresh(refresh: boolean) {
    if (refresh) {
      this.bindGrid();
    }
  }

  setShowIcon(item: MessageRecorded) {
    item.showIconEngine = !item.showIconEngine;
  }




}
