<page>
  <ng-template>
    <div class="text-block">
      <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center">PROFILE</div>         
        </div>
      </div>

      <p-divider />
      <div>
        <p-tabView [(activeIndex)]="activeIndex">
          <p-tabPanel header="Basic" leftIcon="pi pi-id-card">
            <view-profile-basic></view-profile-basic>
          </p-tabPanel>
          <p-tabPanel header="Job Information" leftIcon="pi pi-briefcase">
            <view-profile-job-information></view-profile-job-information>
          </p-tabPanel>
          <p-tabPanel header="Family Details" leftIcon="pi pi-users">
            <view-profile-family-details></view-profile-family-details>
          </p-tabPanel>
          <p-tabPanel header="Post" leftIcon="pi pi-map-marker">
            <view-profile-post></view-profile-post>
          </p-tabPanel>
          <p-tabPanel header="Home Address" leftIcon="pi pi-map-marker">
            <view-profile-home-address></view-profile-home-address>
          </p-tabPanel>
          <p-tabPanel header="Work Address" leftIcon="pi pi-map-marker">
            <view-profile-work-address></view-profile-work-address>
          </p-tabPanel>
          <p-tabPanel header="Other" leftIcon="pi pi-info-circle">
            <view-profile-other></view-profile-other>
          </p-tabPanel>
          <p-tabPanel header="Partner Preferences" leftIcon="pi pi-users">
            <view-profile-partner-preference></view-profile-partner-preference>
          </p-tabPanel>
          <p-tabPanel header="Reference In Kerala" leftIcon="pi pi-home">
            <view-profile-reference-in-kerala></view-profile-reference-in-kerala>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </ng-template>
</page>