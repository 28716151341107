import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { DynamicComponentService } from './DynamicComponentService';
import { SendMessageRequest } from 'src/app/dto/AddNewChatMessageRequest';
import { LambdaService } from './LambdaService';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection: signalR.HubConnection;
    private connectionState = new BehaviorSubject<string>('Disconnected');
    connect = "";

    constructor(
        private localStore: LocalStore,
        private dynamicComponentService: DynamicComponentService,
        private lambdaService: LambdaService
    ) {
        this.connect = this.lambdaService.getLambdaUrl('AuthChatApiCall') + "/chatHub/";
        this.createConnection();
        this.handleConnectionChange();
    }

    private createConnection(): void {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(this.connect, { 
                withCredentials: false,
                transport: signalR.HttpTransportType.WebSockets,
                skipNegotiation: true
            })
            .withAutomaticReconnect([0, 2000, 10000, 30000])
            .build();
        
        this.hubConnection.serverTimeoutInMilliseconds = 600000;
    
        // Add connection monitoring
        this.hubConnection.onclose((error) => {
            console.log('Connection closed:', error);
        });
    
        this.hubConnection.onreconnecting((error) => {
            console.log('Reconnecting:', error);
        });
    }

    public startConnection(): void {
        if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started');
                    this.connectionState.next('Connected');
                    this.joinChat();
                })
                .catch((err) => {
                    console.error(`Error while starting connection: ${err}`);
                    this.connectionState.next('Disconnected');
                    this.reconnect();
                });
        }
    }

    public addMessageListener(callback: (user: string, message: IndividualChatmodel) => void): void {
        this.hubConnection.on('ReceiveMessage', (user, message) => {
            this.handleMessage(callback, user, message);
        });
    }

    public addMessageConfirmationListener(callback: (user: string, message: string) => void): void {
        this.hubConnection.on('MessageSent', (user, message) => this.getBackConfirmation(callback, user, message));
    }

    public sendMessage(sendMessageRequest: SendMessageRequest): void {
        this.hubConnection.invoke("SendMessage", sendMessageRequest)
            .then(() => console.log('Message sent successfully'))
            .catch(err => console.error('Error sending message: ', err));
    }

    private handleMessage(callback: (user: string, message: IndividualChatmodel) => void, user: string, message: IndividualChatmodel): void {

        const recipientWindowIsPresent = this.dynamicComponentService.receiverChatComponents.some(comp => comp.instance.userData.RecipientId === message.RecipientId);
        const senderWindowIsPresent = this.dynamicComponentService.senderChatComponents.some(comp => comp.instance.userData.SenderId === message.RecipientId);

        // if there is no recipient window
        if (!recipientWindowIsPresent && !senderWindowIsPresent) {
            this.dynamicComponentService.createReceiverChatWindow(message);
        }
        else if (recipientWindowIsPresent) {
            let recipientComponent = this.dynamicComponentService.receiverChatComponents.find(comp => comp.instance.userData.RecipientId === message.RecipientId);
            recipientComponent.instance.loadChatMessages();
        }
        else if (senderWindowIsPresent) {
            let senderComponent = this.dynamicComponentService.senderChatComponents.find(comp => comp.instance.userData.SenderId === message.RecipientId);
            senderComponent.instance.loadChatMessages();
        }

        // if there ia already a recipient window
        callback(user, message);
    }

    private getBackConfirmation(callback: (user: string, message: string) => void, user: string, message: string): void {
        callback(user, message);
    }

    private joinChat() {
        let userLoggedIn = this.localStore.getCurrentLoggedInUserSync().UserName;
        if (userLoggedIn) {
            this.hubConnection.invoke('JoinChat', userLoggedIn)
                .catch(err => console.error('Error joining chat: ', err));
        }
    }

    private reconnect(): void {
        setTimeout(() => {
            console.log('Attempting to reconnect...');
            this.startConnection();
        }, 5000);
    }

    private handleConnectionChange(): void {
        this.hubConnection.onreconnecting((error) => {
            console.log('Reconnecting...', error);
            this.connectionState.next('Reconnecting');
        });

        this.hubConnection.onreconnected((connectionId) => {
            console.log('Reconnected. ConnectionId: ', connectionId);
            this.connectionState.next('Connected');
            this.joinChat();
        });

        this.hubConnection.onclose((error) => {
            console.log('Connection closed:', error);
            this.connectionState.next('Disconnected');
            this.reconnect();
        });
    }

    public getConnectionState(): Observable<string> {
        return this.connectionState.asObservable();
    }
}