<div class="image-block">
    <img pTooltip="EXPRESS INTEREST" tooltipPosition="left" src="assets/image/newicons/love-message-4669.png"
        class="brc-ito-3" alt="Express Interest" (click)="expressInterest()" />
</div>
<div class="image-block">
    <img pTooltip="SEND MESSAGE" tooltipPosition="left" src="assets/image/newicons/envelope.png" class="brc-ito-2"
        alt="Send Message" (click)="showSendMessage()" />
</div>
<div class="image-block">
    <img pTooltip="VIEW PROFILE" tooltipPosition="left" src="assets/image/newicons/id-card.png" class="brc-ito-1"
        alt="View Profile" (click)="viewProfile()" />
</div>
<div class="image-block">
    <img pTooltip="DELETE" tooltipPosition="left" src="assets/image/newicons/delete-10399.png" class="brc-ito-4"
        alt="Delete" (click)="deleteRecord()" />
</div>

<p-dialog header="Send Message" [(visible)]="userAction.ShowSendMessage">
    <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
        (OnSubmit)="eventPostSubmit($event)"></send-message>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
<p-toast position="bottom-right"></p-toast>
