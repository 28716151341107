import { DatePipe } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { ResponseModelComponent } from 'src/app/common/reusable/model/confirmWithResponse/model.component';
import { confirmResponseModel } from 'src/app/common/reusable/model/confirmWithResponse/model.component.domain';
import { ConfirmModelYesOrNoComponent } from 'src/app/common/reusable/model/confirmYesOrNo/model.component';
import { DeleterecordComponent } from 'src/app/common/reusable/model/deleterecord/deleterecord.component';
import { DisplayModelComponent } from 'src/app/common/reusable/model/DisplayComponent/model.component';
import { HostmodelComponent } from 'src/app/common/reusable/model/hostmodel/hostmodel.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { RestoredeleterecordComponent } from 'src/app/common/reusable/model/restoredeleterecord/restoredeleterecord.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { DeleteModel } from "src/app/dto/DeleteModel";
import { MessageRecorded } from "src/app/dto/MessageRecorded";
import { ConfirmDeleteCarrier } from "src/app/dto/ConfirmDeleteCarrier";
import { ConfirmRestoreCarrier } from "src/app/dto/ConfirmRestoreCarrier";
import { MessagesToDelete } from "../inbox/MessagesToDelete";
import { ResponseFromUserModel } from "../inbox/ResponseFromUserModel";
import * as _ from 'lodash';
import { CommonFunc } from 'src/app/common/reusable/servicecenter/CommonFunc';
import { trigger, transition, style, animate } from '@angular/animations';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';

@Component({
  selector: 'app-my-message-inbox-trash',
  templateUrl: './my-message-inbox-trash.component.html',
  styleUrls: ['./my-message-inbox-trash.component.scss'],
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ]),

    trigger('fadeInOutFast', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ])

  ]
})
export class MyMessageInboxTrashComponent {
  messageDataSend: MessageRecorded[];
  sortedDataSend: MessageRecorded[];
  showLoader = false;
  messageDataReceived: MessageRecorded[];
  sortedDataReceived: MessageRecorded[];
  selectedMessageItems: MessageRecorded[] = [];
  userloggedIn: string;
  questiontoask: string;
  idToDelete: number[];
  deleteModel: DeleteModel;
  imageToDisplay: HostWindowModel;
  responseFromUserModel: ResponseFromUserModel;
  selectAll: boolean;
  setting: {};
  selectionMode;
  userId: string;
  // gridLines: GridLine;
  displayedColumns = ['UserImage', 'UserFromName', 'userExpressedDate', 'messageStatus', 'ActionColumn'];
  dataSourceReceived: any;
  dataSourceSend: any;
  initialPage: Object;
  cdcmSend: ConfirmDeleteCarrier;
  crcSend: ConfirmRestoreCarrier;
  cdcmReceived: ConfirmDeleteCarrier;
  crcReceived: ConfirmRestoreCarrier;
  ngdc: ConfirmDeleteCarrier;
  restoreReceived: ConfirmDeleteCarrier;
  showReceivedDelete: boolean = false;
  showReceivedRestore: boolean = false;
  @ViewChild(DisplayModelComponent, { static: false }) displayWindow: DisplayModelComponent;
  @ViewChild(ResponseModelComponent, { static: false }) responsewindow: ResponseModelComponent;
  @ViewChild(ConfirmModelYesOrNoComponent, { static: false }) confirmyestornowindow: ConfirmModelYesOrNoComponent;
  @ViewChild(HostmodelComponent, { static: false }) imageModelWindow: HostmodelComponent;
  @ViewChild(DeleterecordComponent, { static: false }) cmcSend: DeleterecordComponent;
  @ViewChild(RestoredeleterecordComponent, { static: false }) rdcSend: RestoredeleterecordComponent;
  showLargeImage = false;
  receivedFirst = 0;
  receivedRows = 10;
  Sendfirst = 0;
  SendRows = 10;
  visibleColumns = [];
  searchText: string = "";
  showSearchIcon = true;

  first = 0;
  rows = 10;
  totalRecords: number = 0;
  rowsPerPage: number = 10;
  pagedRecords: MessageRecorded[] = [];
  
  currentDeviceState: string = "";


  constructor(private commonService: Commonservice, private commonFunc: CommonFunc, private localStore: LocalStore, private datePipe: DatePipe, private messageService: MessageService, private confirmationService: ConfirmationService, private signalStateService: SignalStateService, private loaderService: GlobalLoaderService) { }

  // Load data ones componet is ready
  ngOnInit() {
    const self = this;
    self.initiateInbox();
  }
  initiateInbox() {
    const self = this;

    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();  
    this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.messageDataSend = [];
    self.sortedDataSend = [];
    self.messageDataReceived = [];
    self.sortedDataReceived = [];

    self.questiontoask = 'Are you sure you want to delete?';
    self.idToDelete = [];
    self.deleteModel = {} as DeleteModel;
    self.selectAll = false;
    self.responseFromUserModel = {} as ResponseFromUserModel;
    self.imageToDisplay = {} as HostWindowModel;
    self.selectionMode = { selectionMode: ['row'] };

    self.cdcmSend = {} as ConfirmDeleteCarrier;
    self.cdcmSend.id = 0;
    self.cdcmSend.show = false;
    self.cdcmSend.title = 'Are you sure you want to delete send record?';

    self.ngdc = {} as ConfirmDeleteCarrier;
    self.ngdc.id = 0;
    self.ngdc.show = false;
    self.ngdc.title = 'Are you sure you want to delete received record?';


    self.restoreReceived = {} as ConfirmRestoreCarrier;
    self.restoreReceived.id = 0;
    self.restoreReceived.show = false;
    self.restoreReceived.title = 'Are you sure, you want to restore received record?';

    self.crcSend = {} as ConfirmRestoreCarrier;
    self.crcSend.id = 0;
    self.crcSend.show = false;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    let columns = ["userToID", "UserFromName", "age", "height", "christaintype", "userExpressedDate", "messageStatus", "messageStatusChangeDate"];
    self.visibleColumns.push(...columns);
    self.bindGrid();
  }



  bindGrid() {
    const self = this;
    this.loaderService.showLoader();
    self.commonService.getMessageReceivedTrash(self.userloggedIn).subscribe(
      data => {
        this.loaderService.hideLoader();
        self.runReceived(data);
      },
      error => { }
    );
  }


  runReceived(data: any) {
    const self = this;
    self.messageDataReceived = [];
    self.sortedDataReceived = [];
    if (data !== undefined) {
      if (data.length > 0) {
        _.forEach(data, function (value, key) {
          let myData: MessageRecorded;
          myData = {} as MessageRecorded;
          if (value.messageStatusChangeDate !== null) {
            value.messageStatusChangeDate = self.datePipe.transform(Date.now(), "yyyy-MM-dd");
          }
          else {
            value.messageStatusChangeDate = "Not yet";
          }

          if (value.messageStatus === null) {
            value.messageStatus = 'Not seen';
          }

          myData = value;
          myData.showIconEngine = true;
          myData.pathToProfile = "./../viewotherprofile"
          self.messageDataReceived.push(myData);
          self.sortedDataReceived.push(myData);
          self.totalRecords = self.sortedDataReceived.length;
          self.loadPageData(0, self.rowsPerPage);
        });
      }
      else {
        this.pagedRecords = [];
        self.totalRecords = 0;
      }

    }
  }

  loadPageData(page: number, rows: number) {
    const start = page * rows;
    const end = start + rows;
    this.pagedRecords = this.sortedDataReceived.slice(start, end);
  }

  onPageChange(event: PageEvent) {
    this.first = event.first; 
    this.rows = event.rows; 
    const page = Math.floor(this.first / this.rows);
    this.loadPageData(page, this.rows);
  }

  selectedThemAll() {
  }

  viewMessage() {
    const self = this;
    self.displayWindow.show();
  }



  closeReceived() {
    const self = this;
    self.showReceivedDelete = false;
  }


  closeRestoreReceived() {
    const self = this;
    self.showReceivedRestore = false;
  }

  openSendRestoreModel(item) {
    const self = this;
    self.rdcSend.cdc.show = true;
    self.rdcSend.cdc.id = item.Id;
  }

  runNow() {
    const self = this;
    self.selectAll = false;
    self.bindGrid();
  }



  deleteReceivedItem(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.id.toString();
    this.loaderService.showLoader();
    self.commonService.deleteTrashMessageReceived(deleteId).subscribe(
      data => {
        self.bindGrid();
        self.showReceivedDelete = false;
        this.loaderService.hideLoader();
      }
    );
  }


  RestoreSentItem(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.id.toString();
    if (event.confirmYesOrNo) {
      self.commonService.restoreTrashMessageSend(deleteId).subscribe(
        data => {
          self.bindGrid();
        }
      );
    }
  }

  replyMessage(item) {
    const self = this;
    self.responsewindow.show(item.interestFromUserId);
  }

  responseToMessage(event: confirmResponseModel) {
    const self = this;
    self.responseFromUserModel.userOnlineFromWhomMessageIsBeingSend = self.userloggedIn;
    self.responseFromUserModel.user_To = event.id.toString();
    self.responseFromUserModel.user_ToID = event.reponse.toString();
    self.responseFromUserModel.user_ExpressedDate = new Date();
    self.responseFromUserModel.status = '1';
    self.responseFromUserModel.theTextBeingSend = event.reponse;
    self.commonService.responseMessageFromUser(self.responseFromUserModel).subscribe(
      () => {
        self.runNowResponseMessage();
      },
      error => { }
    );
  }

  runNowResponseMessage() {
    const self = this;
    self.bindGrid();
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      this.loaderService.showLoader();
      self.commonService.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.showLargeImage = true;
          this.loaderService.hideLoader();
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => { }
      );
    }
    else {

    }
  }

  RestoreReceievedItem(event) {
  }

  openRestoreReceivedModel(event: Event, item) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let deleteId = {} as DeleteModel;
        deleteId.sqlWhere = item.Id.toString();
        self.commonService.restoreTrashMessageReceived(deleteId).subscribe(
          data => {
            self.bindGrid();
            self.showReceivedRestore = false;
          }
        );
      },
      reject: () => {
        //reject action
      }
    });
  }

  openSingleReceivedDeleteModal(event: Event, item) {
    const self = this;
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let deleteId = {} as DeleteModel;
        deleteId.sqlWhere = item.Id.toString();
        self.commonService.deleteTrashMessageReceived(deleteId).subscribe(
          data => {
            self.bindGrid();
            self.showReceivedDelete = false;
          }
        );
      },
      reject: () => {
        //reject action
      }
    });
  }

  openSingleSendDeleteModal(event: Event, item) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = item.Id.toString();
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.commonService.deleteTrashMessageSend(deleteId).subscribe(
          data => {
            self.bindGrid();
          }
        );
      },
      reject: () => {
        //reject action
      }
    });
  }

  openMultiDeleteModal() {
    const self = this;
    let idsToDelete: MessagesToDelete = {} as MessagesToDelete;
    idsToDelete.Ids = [];

    let member = self.selectedMessageItems.length > 1 ? "members" : "member";

    if (self.selectedMessageItems.length > 0) {
      self.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure that you want to delete selected ' + member + ' proposals?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          self.selectedMessageItems.forEach(element => {
            idsToDelete.Ids.push(element.Id);
          });

          self.commonService.deleteTrashMessageReceivedMultiple(idsToDelete).subscribe(
            data => {
              if (data.DeleteWorked) {
                this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
              }
              else {
                this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
              }
              self.bindGrid();
            }
          );
        },
        reject: () => {
          //reject action
        }
      });
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "please select at least one item to delete" });
    }
  }

  deleteSentItem(event) {

  }

  globalSearch(event) {
    const self = this;
    let columnsToFilter = self.visibleColumns;
    self.sortedDataReceived = self.commonFunc.searchText(self.messageDataReceived, event, columnsToFilter);
  }

  setShowIcon(item: MessageRecorded) {
    item.showIconEngine = !item.showIconEngine;
  }
}
