<publicpage>

    <div class="slide-show-zone">
        <slideshow></slideshow>
    </div>
    <div class="content-section">
        <div class="col-4 kill-right-spacing p-r-5 login-home">
            <publicpasswordresetbox></publicpasswordresetbox>
        </div>
        <div class="col-8 full-col offer-section">
            <loginhomeoffers></loginhomeoffers>
        </div>
    </div>

</publicpage>