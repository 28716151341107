import { Component, OnInit } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GetViewProfileFamilyDetailsDto } from "src/app/dto/GetViewProfileFamilyDetailsDto";

@Component({
  selector: 'view-profile-family-details',
  templateUrl: './view-profile-family-details.component.html',
  styleUrls: ['./view-profile-family-details.component.css']
})
export class ViewProfileFamilyDetailsComponent implements OnInit {
  userloggedIn: string;
  ui: GetViewProfileFamilyDetailsDto;
  showLoader = true;

  constructor(private commonService: Commonservice, private localStore: LocalStore) {
  }


  ngOnInit() {
    const self = this;
    self.showLoader = true;
    self.ui = {} as GetViewProfileFamilyDetailsDto;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getViewProfileFamilyDetails(self.userloggedIn).subscribe(
        data => {
          self.ui = data;
          self.showLoader = false;
        },
        error => { }
      );
    });

  }
}
