import { Routes } from '@angular/router';
import { AdvancedSearchComponent } from './advanced.component';
import { GaurdService } from '../../../common/reusable/servicecenter/gaurdService.component';



// Route Configuration
export const AdvancedSearchRoute: Routes = [
  { path: 'advancedsearch', canActivate: [GaurdService], component: AdvancedSearchComponent }
];
