<div class="barelist">
    <div>
      <div class="row">
        <div class="col-md-3 left-sub-title">Education with Subject</div>
        <div class="col-md-9">
          {{ mc.CandidateEducationSubject }}
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Education Category</div>
        <div class="col-md-9">
          {{ mc.CandidateEducationCategory }}
        </div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Occupation Category</div>
        <div class="col-md-9">{{ mc.CandidateOccupation }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Occupation In Detail</div>
        <div class="col-md-9">{{ mc.CandidateOccupationInDetail }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Working Firm/Place</div>
        <div class="col-md-9">{{ mc.CandidateNameFirm }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Working Town/State/Country</div>
        <div class="col-md-9">{{ mc.CandidateWorkplace }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Annual Income (INR)</div>
        <div class="col-md-9">{{ mc.CandidateAnnualIncome }}</div>
      </div>
    </div>
    <div>
      <hr />
      <div class="row">
        <div class="col-md-3 left-sub-title">Employed Category</div>
        <div class="col-md-9">{{ mc.CandidateEmploymentCategory }}</div>
      </div>
    </div>
  </div>