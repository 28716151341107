import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LambdaService {
  private urls = environment.lambdaUrls;

  constructor() {}

  getLambdaUrl(functionName: string): string {
    return this.urls[functionName];
  }
}