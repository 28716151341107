import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { HostWindowModel } from './hostmodel.domain';
import { Commonservice } from '../../servicecenter/commonservice.component';
import { formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ImageModelWindow',
  templateUrl: './hostmodel.component.html',
  styleUrls: ['./hostmodel.component.sass']
})
export class HostmodelComponent implements OnInit, DoCheck {
  @Input() tc: HostWindowModel;
  MyDate: string;
  showLocal: boolean = false;
  constructor(private commonService: Commonservice, private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.tc = {} as HostWindowModel;
    this.tc.show = false;
    this.tc.UserId = "";
    this.tc.RouterPath = "";
    this.showLocal = this.tc.show;
  }

  myClock() {
    if (this.tc.show) {
      setInterval(() => {         //replaced function() by ()=>
        this.MyDate = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
      }, 1000);
    }
  }
  ngDoCheck() {
    // if (this.showLocal != this.tc.show) {
    //   this.myClock();
    // }
  }
  ngOnInit() {
    this.tc = {} as HostWindowModel;
    this.tc.show = false;
  }
  closewindow() {
    this.tc = {} as HostWindowModel;
    this.tc.show = false;
  }

  callLargeImage(thumbnailId) {
    const self = this;
    let largerImageId = thumbnailId.replace("Tmb", "");
    self.commonService.getLargeImage(largerImageId).subscribe(
      data => {
        self.tc.MainImage = data.MainImage;
      },
      error => {
        
      }
    );
  }

  viewProfile(userId) {
    this.router.navigate(['ViewFullProfile'], { queryParams: { id: userId } });
  }
}
