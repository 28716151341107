
export class ExpressInterestModel {
    header: string;
    modelContent: string;
    modelFooter: string;
    show: boolean;
    showHeader: boolean;
    showFooter: boolean;
    FromUser: string;
    ToUser: string;
    Message: string;
    Accept: boolean;
    sendUserActualName: string;
}
