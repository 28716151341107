import { Component, OnInit, ViewChild } from '@angular/core';
import { SendMessageObject, BookmarkView } from './bookmark.component.domain';
import { Commonservice } from '../../../common/reusable/servicecenter/commonservice.component';
import { LocalStore } from '../../../common/reusable/globalservice/internalflyweight.service';
import { ExpressInterestModel } from "src/app/dto/ExpressInterestModel";
import { ExpressInterestSendModel } from "src/app/dto/ExpressInterestSendModel";
import { SendMessageModel } from "src/app/dto/SendMessageModel";
import { SendMessageGeneric } from "src/app/dto/SendMessageGeneric";
import { ExpressInterestCarrier } from "src/app/dto/ExpressInterestCarrier";
import { RemoveBookmarkModel } from "src/app/dto/RemoveBookmarkModel";
import { DatePipe } from '@angular/common';
import { W3modelwithresponseComponent } from 'src/app/common/reusable/model/w3modelwithresponse/w3modelwithresponse.component';
import { ResponseFromUserModel } from "../../mymessages/inbox/ResponseFromUserModel";
import * as _ from 'lodash';
import { HostmodelComponent } from 'src/app/common/reusable/model/hostmodel/hostmodel.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { Router } from '@angular/router';
import { CommonFunc } from 'src/app/common/reusable/servicecenter/CommonFunc';
import { UserAction } from 'src/app/dto/UserAction';
import { trigger, transition, style, animate } from '@angular/animations';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss'],
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ]),

    trigger('fadeInOutFast', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ])

  ]
})
export class BookmarkComponent implements OnInit {
  userloggedIn: string;
  confirmationTitle: string = "Confirmation";
  sendMessageObject: SendMessageObject;
  listOfbookmark: Array<BookmarkView>;
  listOfbookmarkSorted: Array<BookmarkView>;
  messageToSend: SendMessageModel;
  imageToDisplay: HostWindowModel;
  mtd: ExpressInterestModel;
  removeBookmarkModel: RemoveBookmarkModel;
  idSendTo: string;
  responseFromUserModel: ResponseFromUserModel;
  expressInterestModel: ExpressInterestSendModel;
  userId: string;
  showLargeImage = false;
  @ViewChild(W3modelwithresponseComponent, { static: false }) responsewindow: W3modelwithresponseComponent;
  @ViewChild(HostmodelComponent, { static: false }) imageModelWindow: HostmodelComponent;
  showSendMessageWindow: boolean;
  sendMessageText: string;
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;
  smg: SendMessageGeneric;
  p: number = 1;
  showLoader = true;
  showFloatingLoader = false;
  searchText: string = "";
  showSearchIcon = true;
  userAction: UserAction;

  constructor(private commonservice: Commonservice, private localStore: LocalStore, private commonFunc: CommonFunc, private datePipe: DatePipe, private messageService: MessageService, private confirmationService: ConfirmationService, private primengConfig: PrimeNGConfig, private router: Router, private loaderService: GlobalLoaderService) { }

  async ngOnInit() {
    const self = this;
    this.primengConfig.ripple = true;
    self.initiateInbox();
  }

  initiateInbox() {
    const self = this;
    self.imageToDisplay = {} as HostWindowModel;
    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.userloggedIn = self.userloggedIn;
    self.getBookMark();
    self.userAction = {} as UserAction;
    self.sendMessageObject = {} as SendMessageObject;
    self.listOfbookmark = [] as Array<BookmarkView>;
    self.listOfbookmarkSorted = [] as Array<BookmarkView>;
    self.messageToSend = {} as SendMessageModel;
    self.responseFromUserModel = {} as ResponseFromUserModel;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.mtd = {} as ExpressInterestModel;
    this.loaderService.loader$.subscribe(x => {
      this.showLoader = x;
    })
    self.idSendTo = "";
  }

  getBookMark() {
    const self = this;
    self.commonservice.getBookmarks(self.userloggedIn).subscribe(
      data => {
        self.loadData(data);
        self.loaderService.hideLoader();
      },
      error => { }
    );
  }

  async loadData(data) {
    const self = this;
    self.listOfbookmark = [];
    self.listOfbookmarkSorted = [];
    _.forEach(data, function (value, key) {
      let myData: BookmarkView;
      myData = {} as BookmarkView;
      myData = value;
      myData.FullProfilePath = "./../viewotherprofile";
      //  myData.showIconEngine = true;
      self.listOfbookmark.push(myData);
      self.listOfbookmarkSorted.push(myData);
    });
  }

  removeBookmark(event: Event, bookmark) {
    const self = this;
    self.confirmationTitle = "Confirmation";
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete the bookmark?',
      accept: () => {
        self.removeBookmarkModel = {} as RemoveBookmarkModel;
        self.removeBookmarkModel.bookmarkId = bookmark.bookMarkId;
        self.commonservice.removeBookMark(self.removeBookmarkModel).subscribe(data => {
          if (data) {
            self.getBookMark();
            this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Message });
          }
        });
      }
    });
  }

  expressInterestPop(event: Event, row) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = row.userWhoGotBookMarked;
    eic.userToName = row.candidate_name.toUpperCase();
    self.mtd = self.commonservice.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure express interest?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  sendExpressInterest(event) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonservice.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    self.loaderService.showLoader();
    if (imageThumbnailId != null) {
      self.commonservice.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.showLargeImage = true;
          self.loaderService.hideLoader();
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => { }
      );
    }
    else {
      self.loaderService.hideLoader();
      this.messageService.add({ severity: 'info', summary: 'Service Message', detail: "no image" });
    }
  }

  showSendDialogue(mc: BookmarkView) {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = mc.userWhoGotBookMarked;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonservice.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }



  navigateToDetails(tc: BookmarkView) {
    let link = tc.FullProfilePath + "/" + tc.userWhoGotBookMarked;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

  globalSearch(event) {
    const self = this;
    const columnsToFilter = ['candidate_name', 'candidate_Username', 'candidate_Age', 'candidate_Denomination', 'Education', 'candidate_Occupation', 'candidate_Workplace', 'Partner_Expectations_brief'];
    self.listOfbookmark = self.commonFunc.searchText(self.listOfbookmarkSorted, event, columnsToFilter);
  }

  triggerParentRefresh(refresh: boolean) {
    if (refresh) {
      this.getBookMark();
    }
  }

  setShowIcon(item: BookmarkView) {
    item.showIconEngine = !item.showIconEngine;
  }
}
