<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<div class="flex" *ngIf="!showLoader">
  <div class="col-12">
   
    <div class="flex table-container">
      <div class="col-2 text-left">Expected Education</div>
      <div class="col-10">
        {{ ui?.Education || "--" }}
      </div>
    </div>
    <div class="flex table-container">
      <div class="col-2 text-left">Expected Employment</div>
      <div class="col-10">
        {{ ui?.Employment || "--" }}
      </div>
    </div>
    <div class="flex table-container">
      <div class="col-2 text-left">
        Expected Denominations
      </div>
      <div class="col-10">
        {{ ui?.Denominations || "--" }}
      </div>
    </div>
    <div class="flex table-container">
      <div class="col-2 text-left">Expect Second Marriage</div>
      <div class="col-10">
        {{ ui?.OpinionOnSecondMarriage || "--" }}
      </div>
    </div>
    <div class="flex table-container">
      <div class="col-2 text-left">Expected Physical Status</div>
      <div class="col-10">{{ ui?.ExpectedPhysicalStatus || "--" }}</div>
    </div>
    <div class="flex table-container">
      <div class="col-2 text-left">Expected Marital Status</div>
      <div class="col-10">{{ ui?.ExpectedMaritalStatus || "--" }}</div>
    </div>
    <div class="flex table-container">
      <div class="col-2 text-left">
        Brief Narration About Your Expectations
      </div>
      <div class="col-10">
        {{ ui?.BriefNaration || "--" }}
      </div>
    </div>
  </div>
</div>