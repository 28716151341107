// Imports
import {
  ExpressInterest
} from './menu.component.domain';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  NgZone
} from '@angular/core';
import {
  MenuService
} from './menu.component.services';
import { AppSettings } from '../contants/appsettings';
import { SignalStateService } from '../servicecenter/StateService';


@Component({
  selector: 'menu-left',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Output() notify: EventEmitter < any > = new EventEmitter();
  @Input() loading: boolean;
  public fullData: ExpressInterest[];
  public expressInterests: ExpressInterest[];
  public myMessageInbox: ExpressInterest[];
  public homeMenu: ExpressInterest[];
  public profileMenu: ExpressInterest[];
  public storageVault: ExpressInterest[];
  public searchMenu: ExpressInterest[];
  public stopCondition = true;

  constructor(private menuService: MenuService, private signalStateService: SignalStateService, private zone: NgZone) {}


  getMyMessages(myType: any) {
    const self = this;
    const myMessageInbox: any = myType.filter((ei: any) => ei.Type === 'MyMessages');
    myMessageInbox.forEach((element: any) => {
      self.myMessageInbox.push(element);
    });
  }

  getExpressedInteredMenu(myType: any) {
    const self = this;
    const myexpressedInterest: any = myType.filter((ei: any) => ei.Type === 'ExpressInterest');
    myexpressedInterest.forEach((element: any) => {
      self.expressInterests.push(element);
    });
  }
  getHomeMenu(myType: any) {
    const self = this;
    const expressInterest: ExpressInterest[] = myType.filter((ei: ExpressInterest) => ei.Type === 'HomeMenu');
    expressInterest.forEach((element: any) => {
      self.homeMenu.push(element);
    });
  }

  getProfileMenu(myType: any) {
    const self = this;
    const expressInterest: ExpressInterest[] = myType.filter((ei: ExpressInterest) => ei.Type === 'myprofile');
    expressInterest.forEach((element: any) => {
      self.profileMenu.push(element);
    });
  }
  getSearchMenu(myType: any) {
    const self = this;
    const expressInterest: ExpressInterest[] = myType.filter((ei: ExpressInterest) => ei.Type === 'searchmenu');
    expressInterest.forEach((element: any) => {
      self.searchMenu.push(element);
    });
  }

  getStorageVaultMenu(myType: any) {
    const self = this;
    const expressInterest: ExpressInterest[] = myType.filter((ei: ExpressInterest) => ei.Type === 'vault');
    expressInterest.forEach((element: any) => {
      self.storageVault.push(element);
    });
  }



  ngOnInit() {
    const self = this;
    self.loading = false;
    self.expressInterests = [];
    self.homeMenu = [];
    self.profileMenu = [];
    self.searchMenu = [];
    self.myMessageInbox = [];
    self.storageVault = [];
    self.loading = AppSettings.loaderVisibleStatus;
    
    if (self.signalStateService.getValue('menu')() == null) {
      this.menuService.getMenuFromServer().subscribe(
        data => {
          self.run(data);
        },
        error =>{
          
        }
      );
    } else {
      const obj = JSON.parse(self.signalStateService.getValue('menu')());
      self.fillMenu(obj);
    }
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async fillMenu(myType: any) {
    // this again
    const self = this;
    self.loading = false;
    self.notify.emit(true);
    this.stopCondition = true;
    self.signalStateService.setValue('menu', JSON.stringify(myType));
    self.getExpressedInteredMenu(myType);
    self.getMyMessages(myType);
    self.getHomeMenu(myType);
    self.getProfileMenu(myType);
    self.getSearchMenu(myType);
    self.getStorageVaultMenu(myType);
  }

  public async run(myType) {
    this.zone.run(() => {
      this.fillMenu(myType);
      this.stopCondition = false;
    });
  }

  disableRightClick(event: MouseEvent): void {
    event.preventDefault();  // Prevent the default context menu
  }

}
