<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>


<ng-container [ngSwitch]="currentDeviceState">

  <ng-container *ngSwitchCase="'desktop-landscape'">
    <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'mobile-portrait'">
    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'tablet-portrait'">
    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
  </ng-container>

</ng-container>



<ng-template #mobilePortrait>
  <div class="flex flex-column table-container" *ngIf="!showLoader">
    <div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Name</div>
        <div class="col-6">{{ ui?.candidate_Name || "--" }}</div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Height</div>
        <div class="col-6">
          {{ ui?.candidate_Height || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Gender</div>
        <div class="col-6">{{ ui?.gender || "--" }}</div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left"> Denomination</div>
        <div class="col-6">
          {{ ui?.candidate_Denomination || "--" }}
        </div>
      </div>

      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Education Category</div>
        <div class="col-6">
          {{ ui?.educationCategory || "--" }}
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Occupation</div>
        <div class="col-6">
          {{ ui?.candidate_Occupation || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Weight</div>
        <div class="col-6">
          {{ ui?.candidate_Weight || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Complexion</div>
        <div class="col-6">
          {{ ui?.candidateComplexion || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Martial Status</div>
        <div class="col-6">{{ ui?.maritalstatus || "--" }}</div>
      </div>
    </div>
    <div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Date of birth</div>
        <div class="col-6">
          {{ ui?.candidates_dob | date : "dd/MM/yyyy" || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left"> Diocese</div>
        <div class="col-6">
          {{ ui?.candidate_Diocese || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Blood Group</div>
        <div class="col-6">{{ ui?.bloodGroup || "--" }}</div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Physical Status</div>
        <div class="col-6">
          {{ ui?.candidatePhysicalStatus || "--" }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #desktopLandscape>
  <div class="flex flex-wrap py-1 table-container" *ngIf="!showLoader">
    <div class="col-4">
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Name</div>
        <div class="col-6">{{ ui?.candidate_Name || "--" }}</div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Height</div>
        <div class="col-6">
          {{ ui?.candidate_Height || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Gender</div>
        <div class="col-6">{{ ui?.gender || "--" }}</div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left"> Denomination</div>
        <div class="col-6">
          {{ ui?.candidate_Denomination || "--" }}
        </div>
      </div>

      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Education Category</div>
        <div class="col-6">
          {{ ui?.educationCategory || "--" }}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Occupation</div>
        <div class="col-6">
          {{ ui?.candidate_Occupation || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Weight</div>
        <div class="col-6">
          {{ ui?.candidate_Weight || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Complexion</div>
        <div class="col-6">
          {{ ui?.candidateComplexion || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Martial Status</div>
        <div class="col-6">{{ ui?.maritalstatus || "--" }}</div>
      </div>
    </div>
    <div class="col-4">
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Date of birth</div>
        <div class="col-6">
          {{ ui?.candidates_dob | date : "dd/MM/yyyy" || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left"> Diocese</div>
        <div class="col-6">
          {{ ui?.candidate_Diocese || "--" }}
        </div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Blood Group</div>
        <div class="col-6">{{ ui?.bloodGroup || "--" }}</div>
      </div>
      <div class="flex flex-wrap py-1">
        <div class="col-6 text-left">Physical Status</div>
        <div class="col-6">
          {{ ui?.candidatePhysicalStatus || "--" }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobilePortraitFooter>
</ng-template>

<ng-template #desktopLandscapeFooter>
</ng-template>


<!-- </p-card> -->