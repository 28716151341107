import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LambdaService } from 'src/app/common/reusable/servicecenter/LambdaService';

@Injectable()
export class ViewExternalService {
  connect = "";
  constructor(_router: Router, private httpClient: HttpClient, private lambdaService: LambdaService) { 
    this.connect = this.lambdaService.getLambdaUrl('internalApiCalls');
  }
  getOtherUserProfile(nameOfTheOneBeingVisited, userLoggedIn) {
    const theresult = this.httpClient.get(this.connect + '/HomePage/GetOtherUserProfile/' + nameOfTheOneBeingVisited + '/' + userLoggedIn);
    return theresult;
  }
}


