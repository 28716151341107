<div class="second-top-panel">
  <div class="flex flex-column flex-wrap text-white gap-2">
    <div class="flex">
      <div class="col-sec-title">sacredheartsmatrimony.com </div>
    </div>
    <div class="flex flex-row flex-wrap gap-2">
      <div class="flex">contact us</div>
      <div class="flex">faq</div>
      <div class="flex">site map</div>
      <div class="flex">about us</div>
      <div class="flex">report misuse</div>
    </div>
  </div>
</div>