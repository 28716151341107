<p-card>
  <div class='flex py-1'>
    <div class='col-3'>
      <!-- <div *ngIf="mc.CandidateProfileImageReal"> -->
        <img [src]=mc.CandidateProfileImageReal (click)="makeitbig(mc.candidateProfileImageTmb,mc.CandidateUserId)"
          class="profile-image"
          id="ctl00_ContentPlaceHolder1_pic1View">
      <!-- </div> -->
    </div>
    <div class='col-9'>
      <div class="barelist">
        <div>
          <div class="barelist">
            I'am {{mc.CandidateName}}
            <p-divider></p-divider>
            Profile Created By: {{mc.ProfileSubmittersRelation}}
            <p-divider></p-divider>
            Last Login Date: {{mc.CandidateLastLogin | date:"MM/dd/yy" }}
            <p-divider></p-divider>
          </div>

        </div>
        <div>


          <div class="barelist">
            <div>
              <div class='flex py-1'>
                <div class='col-3'>
                  Age
                </div>
                <div class='col-9'>
                  {{mc.Age}}yrs
                </div>
              </div>
            </div>
            <div>
              <div class='flex py-1'>
                <div class='col-3'>
                  Height
                </div>
                <div class='col-9'>
                  {{mc.CandidateHeight}} 
                </div>
              </div>
            </div>

            <div>
              <div class='flex py-1'>
                <div class='col-3'>
                  Complexion
                </div>
                <div class='col-9'>
                  {{mc.CandidateComplexion}}
                </div>
              </div>
            </div>
            <div>
              <div class='flex py-1'>
                <div class='col-3'>
                  Christian Denomination
                </div>
                <div class='col-9'>
                  {{mc.CandidateDenomination}}
                </div>
              </div>
            </div>
            <div>
              <div class='flex py-1'>
                <div class='col-3'>
                  Education
                </div>
                <div class='col-9'>
                  {{mc.CandidateEducationSubject}}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
  <ImageModelWindow [tc]=imageToDisplay></ImageModelWindow>
</p-card>