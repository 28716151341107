import { from, throwError, Observable, BehaviorSubject } from 'rxjs';
import { finalize, catchError, switchMap, take, filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Commonservice } from './commonservice.component';
import { Router } from '@angular/router';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { APP_CONSTANTS } from '../contants/app.constants';
import { SignalStateService } from './StateService';
import { GlobalLoaderService } from './global-loader-service';
import Swal from 'sweetalert2';
import { UpdatedRefreshToken } from 'src/app/UpdatedRefreshToken';

@Injectable()
@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private commonservice: Commonservice,
    private router: Router,
    private localStore: LocalStore,
    private signalStateService: SignalStateService,
    private loaderService: GlobalLoaderService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = this.addTokenToRequest(request);
    this.loaderService.showLoader();

    return next.handle(authReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          const isTokenExpired =
            error.headers.get('X-Token-Expired') === 'true' ||
            error.headers.get('X-Auth-Error-Type') === 'TokenExpired';

          if (isTokenExpired) {
            return this.handle401Error(request, next);
          }
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.hideLoader();
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.loaderService.hideLoader();

      return from(Swal.fire({
        title: 'Your session is about to expire',
        text: 'Would you like to extend it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, extend it!',
        cancelButtonText: 'No, log out',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })).pipe(
        switchMap(result => {
          if (result.isConfirmed) {
            const userName = this.localStore.getCurrentLoggedInUserSync().UserName;
            const refreshToken = JSON.parse(this.signalStateService.getValue(APP_CONSTANTS.refresherToken)());

            return this.commonservice.getRefresherToken(userName, refreshToken).pipe(
              map((response: UpdatedRefreshToken) => {
                // Store new tokens
                this.signalStateService.setValue(APP_CONSTANTS.refresherToken, JSON.stringify(response.RefreshToken));
                this.signalStateService.setValue(APP_CONSTANTS.token, JSON.stringify(response.Token));
                this.refreshTokenSubject.next(response.Token);
                return response;
              }),
              catchError(err => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(null);
                this.router.navigate(['/']);
                return throwError(() => err);
              }),
              finalize(() => {
                this.isRefreshing = false;
              })
            );
          } else {
            this.isRefreshing = false;
            this.router.navigate(['/']);
            return throwError(() => new Error('User cancelled token refresh'));
          }
        }),
        switchMap(response => {
          // After successful token refresh, retry the original request
          const newRequest = this.addTokenToRequest(request);
          return next.handle(newRequest);
        }),
        catchError(error => {
          this.isRefreshing = false;
          return throwError(() => error);
        })
      );
    }

    // If refresh is in progress, wait for new token and retry request
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(() => {
        const newRequest = this.addTokenToRequest(request);
        return next.handle(newRequest);
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    let token = JSON.parse(this.signalStateService.getValue(APP_CONSTANTS.token)());
    if (token?.replace(/"/g, '').length === 0) {
      token = JSON.parse(window.name);
      this.signalStateService.setValue(APP_CONSTANTS.token, JSON.stringify(token));
    }
    if (token?.replace(/"/g, '').length > 0) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return request;
  }
}