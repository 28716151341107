import { ChangeDetectorRef, Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { TokenValidationResultDto } from 'src/app/dto/TokenValidationResultDto';
import { userModel } from 'src/app/private/profile/modifypassword/modifypassword.component.domain';

@Component({
  selector: 'publicpasswordresetbox',
  templateUrl: './publicpasswordresetbox.component.html',
  styleUrl: './publicpasswordresetbox.component.css',
  providers: [MessageService, ConfirmationService]
})
export class PublicpasswordresetboxComponent {
  password2: string;
  password1: string;
  username: string;
  public usermodel: userModel;
  disableSubmit: boolean = true;
  disableReset: boolean = true;
  passwordMismatchError: string = '';
  token: string | null = null;
  isValidToken: boolean = false;
  router: Router;
  constructor(private commonservice: Commonservice, _router: Router, private localStore: LocalStore, private messageService: MessageService, private route: ActivatedRoute) { 
    this.router = _router;
  }


  ngOnInit() {
    const self = this;
    self.username = "";
    self.password1 = "";
    self.usermodel = {} as userModel;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.username = result.UserName;
    });

    // Read the 'token' query parameter from the URL
    this.route.queryParams.subscribe(params => {
      this.token = params['token'] || null;
      self.commonservice.validateToken(this.token).subscribe(
        (data: TokenValidationResultDto) => {
          this.isValidToken = data.IsValid;
          this.username = data.Username;
          console.log("this.isValidToken", this.isValidToken);
        },
        error => { }
      );
    });
  }

  // Function to handle password mismatch validation
  validatePasswordMatch() {
    if (this.password1 !== this.password2) {
      this.passwordMismatchError = 'Passwords do not match. Please fix.';
    } else {
      this.passwordMismatchError = '';
    }
  }

  onSubmit(form: NgForm) {
    const self = this;
    if (self.password1 !== self.password2) {
      self.disableSubmit = false;
    } else {
      if (self.password1.trim().length > 0 && self.password2.trim().length > 0) {
        self.disableSubmit = true;
        self.usermodel.Username = self.username;
        self.usermodel.Password = self.password1;
        self.commonservice.changePassword(self.usermodel).subscribe(
          data => {
            self.run(data);
          },
          error => { }
        );
      }
    }
  }

  run(data: any) {
    const self = this;
    if (data.ActivityWorked) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
      self.usermodel = {} as userModel;
      self.router.navigateByUrl('/welcome');
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: data.Error });
    }
  }

  cancel() {
    const self = this;
    self.usermodel = {} as userModel;
  }

  resetForm() {
    this.password1 = '';
    this.password2 = '';
  }

}
