<div [ngClass]="[cdc.show ? 'overlay-v' : 'overlay-h']">
    <div [ngClass]="[cdc.show ? 'show-pop-v' : 'show-pop-h']">
      <div class="model-box ">
        <div class="mb-1">
          <div class="mb-1-1">
  
          </div>
          <div class="mb-1-2" (click)="close()">
            X
          </div>
        </div>
        <div class="mb-2">
          <div class="mb-2-1">
            <button type='button' (click)='actionFromUser()' color='primary' pButton>YES</button>
          </div>
          <div class="mb-2-2">
            <button type='button' (click)='close()' color='primary' pButton>NO</button>
          </div>
        </div>
      </div>
    </div>
  </div>