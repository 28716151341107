import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { SaveResponse } from "src/app/dto/SaveResponse";
import { GetEditProfileReferenceDto } from "src/app/dto/GetEditProfileReferenceDto";
import { SetEditProfileReferenceDto } from "src/app/dto/SetEditProfileReferenceDto";
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';

@Component({
  selector: 'app-edit-reference-profile',
  templateUrl: './edit-reference-profile.component.html',
  styleUrls: ['./edit-reference-profile.component.css'],
  providers: [MessageService]
})
export class EditReferenceProfileComponent implements OnInit {
  ui: GetEditProfileReferenceDto;
  uic: EditPageMegaBucket;
  userloggedIn: string;
  srm: SaveResponse;
  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService,public loadingService: LoaderService) { }

  ngOnInit(): void {
    const self = this;
    self.ui = {} as GetEditProfileReferenceDto;
    self.uic = {} as EditPageMegaBucket;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      this.loadingService.startLoading();
      self.getDataFromDb();
    });
    
  } 
  getDataFromDb() {
    const self = this;
    self.commonService.getEditProfileReferences(self.userloggedIn).subscribe(
      data => {
        self.ui = data;
      },
      error => {},
      () => {
        this.loadingService.stopLoading();
      }
    );
  }

  saveInformation() {
    const self = this;
    let setEditProfileReferenceDto: SetEditProfileReferenceDto = {} as SetEditProfileReferenceDto;
    setEditProfileReferenceDto.Reference_Ker_Name = self.ui.Reference_Ker_Name;
    setEditProfileReferenceDto.Reference_Ker_Address = self.ui.Reference_Ker_Address;
    setEditProfileReferenceDto.Reference_Ker_Phone = self.ui.Reference_Ker_Phone;
    setEditProfileReferenceDto.Candidate_Username = self.userloggedIn;
    
    this.commonService.setEditProfileReferences(setEditProfileReferenceDto).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        self.getDataFromDb();
      },
      error => {}
    );
  }

}
