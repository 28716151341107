<div class="main-page-layout">
  <div class="public-page-header">
    <div class="horizontal-row sub-page-header   second-left-col">
      <div class="block-1">
        <div class="vertical-list first-left-col">
          <div class="header-one">Sacred Hearts Matrimony</div>
          <div class="header-two">a Premium Christian Matrimony</div>
        </div>
      </div> 
      <div class="block-2">
        <!-- <img src="../../assets/image/topright.png" alt="" class="top-panel-right-logo" /> -->
        <div class="vertical-list ">
          <!-- <div class="header-3">SHM</div>
          <div class="header-4">It is better to be together than to be apart</div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="public-page-content my-background-rest-of-page">
    <div class="top-menu">
      <publictopmenu></publictopmenu>
    </div>
    <div class="home-page-content">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="public-page-footer">
    <publicpagefooter></publicpagefooter>
  </div>
</div>