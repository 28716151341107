import { Component } from '@angular/core';
import { TabCommunicationService } from './common/reusable/servicecenter/TabCommunicationService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'All In the Game';
  subscription: any;
  browserRefresh: boolean;

  constructor(private localForageService: TabCommunicationService) {
  }
}
