import { Component, OnInit, ViewChild } from '@angular/core';
import {
  inboxDateSingle
} from './inbox.component.domain';
import { MessagesToDelete } from "./MessagesToDelete";
import { ResponseFromUserModel } from "./ResponseFromUserModel";
import { DeleteModel } from "../../../dto/DeleteModel";
import { W3ModelContent } from "../../../dto/W3ModelContent";
import { ExpressInterestModel } from "../../../dto/ExpressInterestModel";
import { ExpressInterestSendModel } from "../../../dto/ExpressInterestSendModel";
import { ViewMessageModel } from "../../../dto/ViewMessageModel";
import { ExpressInterestCarrier } from "../../../dto/ExpressInterestCarrier";
import { AddBookmarkModel } from "../../../dto/AddBookmarkModel";
import { ConfirmDeleteCarrier } from "../../../dto/ConfirmDeleteCarrier";
import { MakeMessageReadModel } from "../../../dto/MakeMessageReadModel";
import { ResponseFromViewMessageModel } from "../../../dto/ResponseFromViewMessageModel";
import { ConfirmModelComponent } from '../../../common/reusable/model/confirmModel/model.component';
import { DisplayModelComponent } from '../../../common/reusable/model/DisplayComponent/model.component';
import { ResponseModelComponent } from '../../../common/reusable/model/confirmWithResponse/model.component';
import { ConfirmModelYesOrNoComponent } from '../../../common/reusable/model/confirmYesOrNo/model.component';
import { Commonservice } from '../../../common/reusable/servicecenter/commonservice.component';
import { LocalStore } from '../../../common/reusable/globalservice/internalflyweight.service';
import { confirmResponseModel } from '../../../common/reusable/model/confirmWithResponse/model.component.domain';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { HostmodelComponent } from 'src/app/common/reusable/model/hostmodel/hostmodel.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { ConfirmdeletemodelComponent } from 'src/app/common/reusable/model/confirmdeletemodel/confirmdeletemodel.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { MessageInboxModel } from 'src/app/dto/MessageInboxModel';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { CommonFunc } from 'src/app/common/reusable/servicecenter/CommonFunc';
import { trigger, transition, style, animate } from '@angular/animations';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';


@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ]),

    trigger('fadeInOutFast', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ])

  ]
})
export class InboxComponent implements OnInit {
  inboxData: inboxDateSingle[];
  selectedMessageItems: inboxDateSingle[] = [];
  userloggedIn: string;
  questiontoask: string;
  idToDelete: number;
  idsToDelete: MessagesToDelete[];
  deleteModel: DeleteModel;
  cdcm: ConfirmDeleteCarrier;
  responseFromUserModel: ResponseFromUserModel;
  selectAll: boolean;
  messageToDisplay: W3ModelContent;
  setting: {};
  selectionMode;
  idSendTo: string;
  mtd: ExpressInterestModel;
  vmsg: ViewMessageModel;
  showMessage = false;
  userId: string;
  showsupermmodal: boolean = false;
  sortedData: inboxDateSingle[] = [];
  pagedRecords: inboxDateSingle[] = [];
  imageToDisplay: HostWindowModel;
  expressInterestModel: ExpressInterestSendModel;
  dataSource: any;
  first = 0;
  rows = 10;
  searchText: string = "";
  visibleColumns = [];
  smg: SendMessageGeneric;
  displayedColumns = ['UserImage', 'UserFromName', 'userExpressedDate', 'messageStatus', 'ActionColumn'];
  showLargeImage = false;
  initialPage: Object;
  @ViewChild(ConfirmModelComponent, { static: false }) modelWindow: ConfirmModelComponent;
  @ViewChild(DisplayModelComponent, { static: false }) dw: DisplayModelComponent;
  @ViewChild(ResponseModelComponent, { static: false }) responsewindow: ResponseModelComponent;
  @ViewChild(ConfirmModelYesOrNoComponent, { static: false }) confirmyestornowindow: ConfirmModelYesOrNoComponent;
  @ViewChild(HostmodelComponent, { static: false }) imageModelWindow: HostmodelComponent;
  @ViewChild(ConfirmdeletemodelComponent, { static: false }) cmc: ConfirmdeletemodelComponent;
  urm: ResponseFromViewMessageModel;
  showLoader = false;
  confirmationTitle: string = "Confirmation";
  sendMessageText: string;
  showLiteLoader = false;
  showDeleteModel = false;
  showSearchIcon = true;
  totalRecords: number = 0;
  rowsPerPage: number = 10;
  currentDeviceState: string = "";

  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;


  constructor(private commonService: Commonservice, private commonFunc: CommonFunc, private localStore: LocalStore, private datePipe: DatePipe, private messageService: MessageService, private confirmationService: ConfirmationService, private router: Router, private signalStateService: SignalStateService, private loaderService: GlobalLoaderService) {

  }

  // Load data ones componet is ready
  ngOnInit() {
    const self = this;
    self.initiateInbox();

  }
  initiateInbox() {
    const self = this;
    this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.inboxData = [];
    self.cdcm = {} as ConfirmDeleteCarrier;
    self.cdcm.id = 0;
    self.cdcm.show = false;
    this.sortedData = this.inboxData.slice();
    self.questiontoask = 'Are you sure you want to delete?';
    self.idToDelete = 0;
    self.deleteModel = {} as DeleteModel;
    self.selectAll = false;
    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.imageModelWindow = {} as HostmodelComponent;
    self.responseFromUserModel = {} as ResponseFromUserModel;
    self.selectionMode = { selectionMode: ['row'] };
    self.messageToDisplay = {} as W3ModelContent;
    self.imageToDisplay = {} as HostWindowModel;
    self.initialPage = { pageSizes: true, pageCount: 4 };
    let columns = ["userFromID", "UserFromName", "age", "height", "christaintype", "userExpressedDate", "messageStatus", "messageStatusChangeDate"];
    self.visibleColumns.push(...columns);
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;  
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();  
    // console.log("self.currentDeviceState ", self.currentDeviceState);
    self.bindGrid();
    self.vmsg = {} as ViewMessageModel;
  }

  bindGrid() {
    const self = this;
    this.loaderService.showLoader();
    this.commonService.getMessageFromInbox(self.userloggedIn).subscribe(
      data => {
        self.run(data);
        this.loaderService.showLoader();
      },
      error => { }
    );
  }

  run(data: any) {
    const self = this;

    if (data !== undefined) {
      let currentData: Array<inboxDateSingle> = [];
      if (data?.length > 0) {
        _.forEach(data, function (value, key) {
          let myData: inboxDateSingle;
          myData = {} as inboxDateSingle;
          myData = value;
          if (value.messageStatusChangeDate !== null) {
            value.messageStatusChangeDate = self.datePipe.transform(Date.now(), "yyyy-MM-dd");
          }
          else {
            value.messageStatusChangeDate = "Not yet";
          }

          if (value.messageStatus === null) {
            value.messageStatus = 'Pending';
          }
          myData.showIconEngine = true;
          myData.pathToProfile = "./../viewotherprofile";
          currentData.push(myData);
        });
        this.sortedData = this.commonFunc.mergeArraysByKey(this.sortedData, currentData, 'inboxID');
        this.totalRecords = this.sortedData.length;
        this.loadPageData(0, this.rowsPerPage);
      }
      else {
        this.sortedData = [];
        this.totalRecords = 0;
        this.pagedRecords = [];
      }
    }
  }

  loadPageData(page: number, rows: number) {
    const start = page * rows;
    const end = start + rows;
    this.pagedRecords = this.sortedData.slice(start, end);
  }

  onPageChange(event: PageEvent) {
    this.first = event.first;
    this.rows = event.rows;
    const page = Math.floor(this.first / this.rows);
    this.loadPageData(page, this.rows);
  }

  selectedThemAll() {
    const self = this;
    self.inboxData.forEach(element => {
      element.selected = !element.selected;
    });
    self.initialPage = { pageSizes: true, pageCount: 4 };
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  }

  viewMessage(row) {
    const self = this;
    let mmrm = new MakeMessageReadModel();
    mmrm.IdOfRecordToBeMadeRead = row.inboxID;
    mmrm.TableId = 1;
    self.commonService.setToRead(mmrm).subscribe(
      data => {
        self.vmsg = {} as ViewMessageModel;
        self.vmsg.show = true;
        self.showMessage = true;
        self.vmsg.header = "Interest received from " + row.UserFromName.toUpperCase();
        self.vmsg.modelContent = row.textMessageFromUser;
        self.vmsg.showHeader = true;
        self.vmsg.showFooter = true;
        self.vmsg.ToUser = row.userToID;
        self.vmsg.TableId = 1;
        self.vmsg.FromUser = row.userFromID;
        self.vmsg.modelFooter = "DATE EXPRESSED: " + this.datePipe.transform(row.userExpressedDate, "yyyy-MM-dd");
      },
      error => { }
    );
  }

  rebindGrid(event) {
    const self = this;
    self.bindGrid();
  }


  openMultiDeleteModal() {
    const self = this;
    let idsToDelete: MessagesToDelete = {} as MessagesToDelete;
    idsToDelete.Ids = [];

    let member = self.selectedMessageItems.length > 1 ? "members" : "member";

    if (self.selectedMessageItems.length > 0) {
      self.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure that you want to delete selected ' + member + ' proposals?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          self.selectedMessageItems.forEach(element => {
            idsToDelete.Ids.push(element.inboxID);
          });

          self.commonService.deleteMultipleSelectedMessages(idsToDelete).subscribe(
            data => {
              if (data.DeleteWorked) {
                this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
              }
              else {
                this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
              }
              self.bindGrid();
            }
          );
        },
        reject: () => {
          //reject action
        }
      });
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "please select at least one item to delete" });
    }
  }
  runNow() {
    const self = this;
    self.selectAll = false;
    self.bindGrid();
  }

  replyMessage(item) {
    const self = this;
    self.responsewindow.show(item.interestFromUserId);
  }

  responseToMessage(event: confirmResponseModel) {
    const self = this;
    self.responseFromUserModel.userOnlineFromWhomMessageIsBeingSend = self.userloggedIn;
    self.responseFromUserModel.user_To = event.id.toString();
    self.responseFromUserModel.user_ToID = event.reponse.toString();
    self.responseFromUserModel.user_ExpressedDate = new Date();
    self.responseFromUserModel.status = '1';
    self.responseFromUserModel.theTextBeingSend = event.reponse;
    self.commonService.responseMessageFromUser(self.responseFromUserModel).subscribe(
      () => {
        self.runNowResponseMessage();
      },
      error => { }
    );
  }

  runNowResponseMessage() {
    const self = this;
    self.bindGrid();
  }

  expressInterestPop(event: Event, row: inboxDateSingle) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let eic = new ExpressInterestCarrier();
        eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
        eic.fromId = self.userloggedIn;
        eic.userToId = row.userFromID;
        eic.userToName = row.UserFromName.toUpperCase();
        self.mtd = self.commonService.expressInterest(eic);
        self.confirmationTitle = self.mtd.header;
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  openSingleDeleteModal(event: Event, item) {
    const self = this;
    self.idToDelete = item.inboxID;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.deleteInboxItem(self.idToDelete);
      },
      reject: () => {
        //reject action
      }
    });
  }


  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  showSendDialogue(mc: MessageInboxModel) {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = mc.userFromID;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonService.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }

  bookmarkprofile(row) {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: row.userToID,
      UserWhoIsGettingBookmarked: row.userFromID
    }
    let error: any;
    this.loaderService.showLoader();
    self.commonService.AddBookMark(bookmark).subscribe(
      data => {
        if (data != undefined) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
          this.loaderService.hideLoader();
        }
        error = data?.Message;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Service Message', detail: error });
      }
    );
  }

  deleteInboxItem(idToDelete: number) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = idToDelete.toString();
    this.loaderService.hideLoader();
    self.commonService.deleteSelectedMessages(deleteId).subscribe(
      data => {
        this.loaderService.hideLoader();
        if (data.DeleteWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
        }
        self.bindGrid();
      }
    );
  }

  acceptproposalfromuser() {

  }


  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      this.loaderService.hideLoader();
      self.commonService.getLargeImage(imageThumbnailId).subscribe(
        data => {
          this.loaderService.hideLoader();
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;

        },
        error => { }
      );
    }
    else {
    }
  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.sortedData ? this.first === (this.sortedData.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.sortedData ? this.first === 0 : true;
  }

  acceptInterest(event: ViewMessageModel) {
    const self = this;
    self.urm = {} as ResponseFromViewMessageModel;
    self.urm.userbeingaccepted = event.FromUser;
    self.urm.userloggedin = event.ToUser;
    self.urm.acceptance = true;
    this.loaderService.showLoader();
    switch (event.TableId) {
      case 1:
        self.commonService.sendAcceptMyMesssage(self.urm).subscribe(resp => {
          self.vmsg.show = false;
          this.loaderService.showLoader();
          if (resp.UpdateWorked) {
            self.bindGrid();
            this.messageService.add({ severity: 'success', summary: 'Service Message', detail: resp.Message });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: resp.Error });
          }
        });
        break;
      case 6:
        self.commonService.sendAcceptExpressInterest(self.urm).subscribe(resp => {
          self.vmsg.show = false;
          this.loaderService.showLoader();
          if (resp.UpdateWorked) {
            self.bindGrid();
            this.messageService.add({ severity: 'success', summary: 'Service Message', detail: resp.Message });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: resp.Error });
          }
        });
        break;
    }
    self.showMessage = false;
  }

  navigateToDetails(tc: inboxDateSingle) {
    let link = tc.pathToProfile + "/" + tc.userFromID;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

  globalSearch(event) {
    const self = this;
    let columnsToFilter = self.visibleColumns;
    self.sortedData = self.commonFunc.searchText(self.inboxData, event, columnsToFilter);
  }

  triggerParentRefresh(refresh: boolean) {
    if (refresh) {
      this.bindGrid();
    }
  }

  setShowIcon(item: inboxDateSingle) {
    item.showIconEngine = !item.showIconEngine;
  }


}