<form #sendMessageForm="ngForm" (ngSubmit)="onSubmit(sendMessageForm)">
  <div class="flex flex-column">
    <div class="col">
      <textarea
        [rows]="5"
        [cols]="70"
        pInputTextarea
        id="usermessage"
        name="usermessage"
        #usermessage="ngModel"
        required
        minlength="1"
        maxlength="250"
        [(ngModel)]="message"
        [readonly]="isReadOnly"
        [class.field-error]="sendMessageForm.submitted && usermessage.invalid"
      ></textarea>
    </div>
    <div class="col" [hidden]="usermessage.valid || !sendMessageForm.submitted">
      <p-messages severity="error">
        <ng-template pTemplate>
          Please enter the message to be send
        </ng-template>
      </p-messages>
    </div>
    <div class="col row">
      <ng-container *ngIf="!showMessage">
        <p-button icon="pi pi-send" label="Send" type="submit"></p-button>
        <spacer></spacer>
        <p-button
          icon="pi pi-times-circle"
          (click)="hideSendMessageWindow()"
          label="Cancel"
          class="p-button-secondary"
        ></p-button>
      </ng-container>
      <div *ngIf="showMessage">
        <p-button
          icon="pi pi-times-circle"
          (click)="hideSendMessageWindow()"
          label="Ok"
        ></p-button>
      </div>

      <!-- <p-messages severity="info" *ngIf="showMessage">
        <ng-template pTemplate>
          {{ message }}
        </ng-template>
      </p-messages> -->
    </div>
  </div>
</form>
<!-- <p-toast position="bottom-right"></p-toast> -->
