import { trigger, transition, style, animate } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { ExpressInterestCarrier } from 'src/app/dto/ExpressInterestCarrier';
import { ExpressInterestModel } from 'src/app/dto/ExpressInterestModel';
import { ExpressInterestSendModel } from 'src/app/dto/ExpressInterestSendModel';
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';
import { YourRecentVisitsModelOVC } from "src/app/dto/YourRecentVisitsModelOVC";

@Component({
  selector: 'home-my-recent-visits',
  templateUrl: './home-my-recent-visits.component.html',
  styleUrls: ['./home-my-recent-visits.component.css'],
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomeMyRecentVisitsComponent implements OnInit {
  yourRecentVisitsOVC: Array<YourRecentVisitsModelOVC>;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  showLoader = false;
  imageToDisplay: HostWindowModel;
  showLocalLoader = false;
  p: number = 1;
  mtd: ExpressInterestModel;
  confirmationTitle: string = "Confirmation";
  expressInterestModel: ExpressInterestSendModel;
  smg: SendMessageGeneric;
  sendMessageText: string;
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;


  constructor(private commonService: Commonservice, private localStore: LocalStore, private confirmationService: ConfirmationService, private messageService: MessageService, private datePipe: DatePipe, private router: Router, private loaderService: GlobalLoaderService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = false;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.userloggedIn = self.userloggedIn;
    self.yourRecentVisitsOVC = [];
    self.imageToDisplay = {} as HostWindowModel;
    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.mtd = {} as ExpressInterestModel;
    // this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.bindGrid();
  }


  bindGrid() {
    const self = this;
    // this.loaderService.showLoader();
    this.showLocalLoader = true;
    self.commonService.getLoggedInUsersRecentVisits(self.userloggedIn).subscribe(
      data => {
        self.yourRecentVisitsOVC = data;
        self.yourRecentVisitsOVC.map(x => x.showIconEngine = true);
        this.showLocalLoader = false;
      },
      error => {

      }
    );
  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      self.showLargeImage = false;
      // this.loaderService.showLoader();
      this.showLoader = true;
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          // this.loaderService.hideLoader();
          this.showLoader = false;
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {

    }
  }




  expressInterestPop(event: Event, row: YourRecentVisitsModelOVC) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = row.IdOfPersonIVisitedOVC;
    eic.userToName = row.NameOfPersonWhoVisitedMeOVC;
    self.mtd = self.commonService.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure express interest?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  sendExpressInterest(event) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data.InsertWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Message });
        }
      });
    }
  }

  showSendDialogue(mc: YourRecentVisitsModelOVC) {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = mc.IdOfPersonIVisitedOVC;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonService.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  navigateToDetails(tc: YourRecentVisitsModelOVC) {
    let link = "./../viewotherprofile" + "/" + tc.IdOfPersonIVisitedOVC;
    this.router.navigate([]).then(() => { window.open(link, '_blank'); });
  }

  setShowIcon(item: YourRecentVisitsModelOVC) {
    item.showIconEngine = !item.showIconEngine;
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }

  deleteItem(cdvm: YourRecentVisitsModelOVC) {
    const self = this;
    self.confirmationTitle = "Confirmation";
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to delete ' + cdvm.NameOfPersonWhoVisitedMeOVC + ' ?',
      accept: () => {
        self.commonService.deleteOwnersVisits(cdvm.IDOVC).subscribe(dataMain => {        
          if (dataMain.DeleteWorked) {
            self.bindGrid();
            this.messageService.add({ severity: 'success', summary: 'Service Message', detail: dataMain.Message });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: dataMain.Message });
          }
        });
      }
    });
  }
}
