import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { ChatUserListResponse } from 'src/app/dto/usersReadyForChat';
import { LambdaService } from './LambdaService';

@Injectable({
    providedIn: 'root'
})
export class Chatsignalservice {
    connect = "";
    chatList = signal<ChatUserListResponse[]>([]);
    constructor(private httpClient: HttpClient, private lambdaService: LambdaService) {
        this.connect = this.lambdaService.getLambdaUrl('AuthChatApiCall')
    }

    fetchChatList(username: string): Observable<ChatUserListResponse[]> {
        if (this.chatList().length > 0) {
            return of(this.chatList());
        }

        const storedList = sessionStorage.getItem('chatList');
        if (storedList && JSON.parse(storedList).length > 0) {
            const parsedList = JSON.parse(storedList) as ChatUserListResponse[];
            this.chatList.set(parsedList);
            return of(parsedList);
        }        
        return this.httpClient.get<ChatUserListResponse[]>(this.connect + '/api/Chat/GetInitialChatList/' + username).pipe(
            tap(response => {
                this.chatList.set(response);
                sessionStorage.setItem('chatList', JSON.stringify(response));
            })
        );
    }
}