import {
  trigger, style, animate, transition, query, stagger
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { PhotoService } from './PhotoService';
import { SignalStateService } from '../../reusable/servicecenter/StateService';
import { APP_CONSTANTS } from '../../reusable/contants/app.constants';



@Component({
  selector: 'slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.css'],
  animations: [trigger('fadeSlide', [
    transition(':enter', [
      query('.icons-inleft-ki', [
        style({ opacity: 0, transform: 'translateX(-50px)' }), // Initial state
        stagger(100, [
          animate('5000ms ease-in', style({ opacity: 1, transform: 'translateX(0)' })) // Final state, 5000ms (5 seconds) duration
        ])
      ])
    ]),
    transition(':leave', [
      query('.icons-inleft-ki', [
        style({ opacity: 1, transform: 'translateX(0)' }), // Initial state for leaving
        stagger(100, [
          animate('5000ms ease-out', style({ opacity: 0, transform: 'translateX(50px)' })) // Final state for leaving, 5000ms (5 seconds) duration
        ])
      ])
    ])
  ])]
})
export class SlideshowComponent implements OnInit {
  imageSource = [];
  show1: boolean = true;
  show2: boolean = true;
  show3: boolean = true;
  show4: boolean = true;
  show5: boolean = true;
  theCurrentImage1: string = "../../assets/image/slide/1.jpg";
  theCurrentImage2: string = "";
  theCurrentImage3: string = "";
  theCurrentImage4: string = "";
  theCurrentImage5: string = "";
  currentImageIndex = 0;
  currentIndex = 0;
  translateX = 0;
  currentDeviceState: string = "";
  currentClass: string;

  images: any[] | undefined;

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];


  constructor(private photoService: PhotoService, private signalStateService: SignalStateService) { }

  ngOnInit() {
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();
    this.photoService.getImages().then((images) => {
      this.images = images;
    });
    this.setScreenClass(this.currentDeviceState);
  }

  setScreenClass(screenType: string) {
    switch (screenType) {
      case "desktop-landscape":
        this.currentClass = 'icons-inleft-ki';
        break;
      case "mobile-portrait":
        this.currentClass = 'icons-inleft-ki-mobile';
        break;
      case "tablet-portrait":
        this.currentClass = 'icons-inleft-ki-mobile';
        break;
      default:
        this.currentClass = 'icons-inleft-ki';
        break;
    }
  }

  setTheSource() {
  }

  changeImageSource() {
    const currentIndex = this.imageSource.indexOf(this.theCurrentImage1);
    const nextIndex = (currentIndex + 1) % this.imageSource.length;
    this["theCurrentImage" + nextIndex] = this.imageSource[nextIndex];
  }
  startImageSlider() {
    setInterval(() => {
      this.images[this.currentIndex].show = false;
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.images[this.currentIndex].show = true;
      this.translateX = -this.currentIndex * 100; // Adjust the 100 value for the image width
    }, 5000); // Change image every 5 seconds    
  }
}
