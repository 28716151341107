import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmDeleteCarrier } from "src/app/dto/ConfirmDeleteCarrier";

@Component({
  selector: 'deleterecord',
  templateUrl: './deleterecord.component.html',
  styleUrls: ['./deleterecord.component.scss']
})
export class DeleterecordComponent implements OnInit {
  @Input() cdc: ConfirmDeleteCarrier;
  @Output() confirmaction = new EventEmitter<ConfirmDeleteCarrier>();

  constructor() { }

  ngOnInit() {
    this.cdc = {} as ConfirmDeleteCarrier;
    this.cdc.show = false;
    this.cdc.confirmYesOrNo = false;
    this.cdc.id = 0;
    this.cdc.title="Are you sure, you want to delete?";
  }

  close() {
    this.cdc.show = false;
  }

  actionFromUser() {
    this.cdc.show = false;
    this.cdc.confirmYesOrNo = true;
    this.confirmaction.next(this.cdc);
  }
}
