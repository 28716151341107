import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { Dropdown2 } from "src/app/dto/Dropdown2";
import { Dropdown3 } from "src/app/dto/Dropdown3";
import { QuickSearchHomePageParam } from "src/app/dto/QuickSearchHomePageParam";
import * as _ from 'lodash-es';
import { CommunityEnum } from 'src/app/common/model/enum/Community';
import { GenderEnum } from 'src/app/common/model/enum/Gender';
import { AutoComplete } from 'primeng/autocomplete';
import { QuickSearchResult, SearchResult } from 'src/app/private/search/quicks/quicks.component.domain';

@Component({
  selector: 'app-publicsearch',
  templateUrl: './publicsearch.component.html',
  styleUrls: ['./publicsearch.component.css']
})
export class PublicsearchComponent implements OnInit, AfterViewInit {
  @ViewChild(AutoComplete) private autoComplete: AutoComplete;
  // parent
  startAge: Dropdown2[] = [];
  endAge: Dropdown2[] = [];
  masterStartAge: Dropdown2[] = [];
  masterEndAge: Dropdown2[] = [];
  genders: Dropdown3[] = [];
  startHeight: Dropdown2[] = [];
  endHeight: Dropdown2[] = [];
  MasterStartHeight: Dropdown2[] = [];
  MasterEndHeight: Dropdown2[] = [];
  community: Dropdown3[] = [];
  // parent


  // seletion region
  selectedStartAge: Dropdown2;
  selectedEndAge: Dropdown2;
  selectedCommunity: string = "Both";
  selectedGender: string = "Male";
  selectedStartHeight: Dropdown2;
  selectedEndHeight: Dropdown2;
  // seletion region

  withPhoto: boolean = false;
  withPhotoText: string = "With Photo";
  resultCount = 0;
  searchResult: Array<SearchResult> = [];
  showLoader = false;

  page: number = 1;
  size: number = 10;
  paginationCount: number = 0;
  loadFromPresavedSearch: boolean = false;
  showPreSavedLoad: boolean = false;
  first = 0;
  rows = 10;
  totalRecords = 0;

  constructor(private commonService: Commonservice, private renderer: Renderer2) {
  }


  ngAfterViewInit() {
  }

  ngOnInit() {
    const self = this;
    this.genders = [{ name: GenderEnum.Male, code: GenderEnum.Male }, { name: GenderEnum.Female, code: GenderEnum.Female }];
    this.community = [{ name: CommunityEnum.Both, code: CommunityEnum.Both }, { name: CommunityEnum.Catholic, code: CommunityEnum.Catholic }, { name: CommunityEnum.NonCatholic, code: CommunityEnum.NonCatholic }];

    const ccn = self.commonService;

    ccn.getFreeEntries().subscribe(data => {
      // debugger;
    });

    const joint = forkJoin({ age: ccn.getAge(), height: ccn.getHeight() });
    joint.subscribe(({ age, height }) => {
      {

        // age
        self.startAge = age;
        self.endAge.push(...age);
        self.masterEndAge.push(...age);
        self.masterStartAge.push(...age);
        self.selectedStartAge = self.startAge[0];
        self.selectedEndAge = self.endAge[0];
        // age

        // height
        self.startHeight.push(...height);
        self.endHeight.push(...height);
        self.MasterStartHeight.push(...height);
        self.MasterEndHeight.push(...height);
        self.selectedStartHeight = self.startHeight[0];
        self.selectedEndHeight = self.endHeight[0];
        // height
      }
    })
  }

  searchStartAge(event) {
    const self = this;
    self.startAge = [];
    self.startAge = self.masterStartAge.filter(x => x.Name.toLowerCase().includes(event.query.toLowerCase()));
  }

  searchEndAge(event) {
    const self = this;
    self.endAge = [];
    self.endAge = self.masterEndAge.filter(x => x.Name.toLowerCase().includes(event.query.toLowerCase()));
  }

  searchStartHeight(event) {
    const self = this;
    self.startHeight = [];
    self.startHeight = self.MasterStartHeight.filter(x => x.Name.toLowerCase().includes(event.query.toLowerCase()));
  }

  searchEndHeight(event) {
    const self = this;
    self.endHeight = [];
    self.endHeight = self.MasterEndHeight.filter(x => x.Name.toLowerCase().includes(event.query.toLowerCase()));
  }

  search(reset: boolean = false) {
    const self = this;

    self.showLoader = true;
    let quickSearchHomePageParam = {} as QuickSearchHomePageParam;
    quickSearchHomePageParam.Gender = (self.genders.indexOf(self.genders.filter(x => x.code === self.selectedGender)[0])) + 1;

    quickSearchHomePageParam.ShowWithProfile = self.withPhoto ? 2 : 0;

    quickSearchHomePageParam.StartAge = self.selectedStartAge["value"] != 0 ? parseInt(self.selectedStartAge["value"]) : null;

    quickSearchHomePageParam.EndAge = self.selectedEndAge["value"] != 0 ? parseInt(self.selectedEndAge["value"]) : null;

    quickSearchHomePageParam.StartHeight = self.selectedStartHeight["value"] != 0 ? parseInt(self.selectedStartHeight["value"]) : null;

    quickSearchHomePageParam.EndHeight = self.selectedEndHeight["value"] != 0 ? parseInt(self.selectedEndHeight["value"]) : null;

    quickSearchHomePageParam.Community = self.community.indexOf(self.community.filter(x => x.code === self.selectedCommunity)[0]) + 1;

    if (reset) {
      quickSearchHomePageParam.PaginationParams = {
        Page: 1,
        Size: 10
      }
    }
    else {
      quickSearchHomePageParam.PaginationParams = {
        Page: this.page,
        Size: this.size
      }
    }

    self.commonService.QuickSearchHomePage(quickSearchHomePageParam).subscribe((data: QuickSearchResult) => {
      if (data.SearchResult && data.TotalRecords) {
        self.searchResult = [];
        self.searchResult.push(...data.SearchResult);
        self.totalRecords = data.TotalRecords;
        if (reset) {
          this.first = 0
        }
      }
      self.showLoader = false;    
    });
  }

  onPageChange(event: any) {
    this.page = event.first / event.rows + 1;  // Calculate the current page number
    this.size = event.rows;
    this.first = event.first;
    this.search();
  }

}
