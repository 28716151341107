import { Component } from '@angular/core';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { VisibilityStatusModel } from "src/app/dto/VisibilityStatusModel";
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-deleteprofile',
  templateUrl: './deleteprofile.component.html',
  styleUrls: ['./deleteprofile.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class DeleteprofileComponent {
  showLoader = true;
  userprofileVisible = false;
  userloggedIn: string;
  constructor(private messageService: MessageService, private commonService: Commonservice, private localStore: LocalStore) { }
  ngOnInit() {
    const self = this;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.bindData();

  }

  bindData() {
    const self = this;
    self.commonService.getVisisbilityStatus(self.userloggedIn).subscribe(
      data => {
        self.showLoader = false;
        let visibility = data.toString();
        if (visibility === "1") {
          self.userprofileVisible = true;
        }
        else {
          self.userprofileVisible = false;
        }
      },
      error => { }
    );

  }
  makepersonisibleorhide(event) {
    let visible = "";
    switch (event) {
      case true:
        visible = "1";
        break;
      case false:
        visible = "0";
        break;
    }
    const self = this;
    let visiblityStatus: VisibilityStatusModel = new VisibilityStatusModel();
    visiblityStatus.userLoggedin = self.userloggedIn;
    visiblityStatus.visibilityVariable = visible;

    self.commonService.setVisisbilityStatus(visiblityStatus).subscribe(
      data => {
        if (visiblityStatus.visibilityVariable === "0") {
          this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "User profile is hidden now" });
        }
        else {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "User profile is visible now" });
        }
      },
      error => { }
    );
  }
}
