import { Component, OnInit, Input } from '@angular/core';
import { RegisteredUser } from '../../../view/view.components.domain';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { MemberFamilyDetailsDto } from '../../../view/MemberFamilyDetailsDto';

@Component({
  selector: 'candidatefamilydetails',
  templateUrl: './candidatefaimlydetails.component.html',
  styleUrls: ['./candidatefaimlydetails.component.scss']
})
export class CandidatefaimlydetailsComponent implements OnInit {
  mc: MemberFamilyDetailsDto =  {
    FatherName: '',
    FatherHouseName: '',
    FathersNativePlace: '',
    FatherOccupation: '',
    MotherName: '',
    MotherHouseName: '',
    MotherNativeDistrict: '',
    MotherOccupation: '',
    BrotherMarried: '',
    BrothersUnmarried: '',
    BrotherPriest: '',
    SistersMarried: '',
    SistersUnmarried: '',
    SistersNun: '',
    AbroadCandidateavailabilityatNativeTown: '',
    CandidateAssets: '',
    CandidateBackGround: '',
    CandidateAboutFamily: '',
    ProfileSubmittedBy: ''
  };
  showLoader = true;
  userloggedIn: string;
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    
    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getPrimaryUserInformation();
    });
  }
  getPrimaryUserInformation(){
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.commonService.getMemberFamilyDetailsTab(self.userWhosInformationIsRequired,self.userloggedIn).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }


}
