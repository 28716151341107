<page>
  <ng-template>
    <ng-containter *ngIf="this.loadingService.isLoading() else content">
      <super-floating-loader [isLoading]="this.loadingService.isLoading()"></super-floating-loader>
    </ng-containter>
    <ng-template #content>
      <div class="text-block">

        <div class="super-title">
          <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center">EDIT PROFILE</div>
          </div>
        </div>

        <p-divider />


        <p-accordion>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Basic</span></div>
                <div> <i class="pl-2 pi pi-id-card" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>
            <ng-container *ngIf="loadChild">
              <app-edit-basic-profile [epd]="uic"></app-edit-basic-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Job</span></div>
                <div> <i class="pl-2 pi pi-briefcase" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>
            <ng-container *ngIf="loadChild">
              <app-edit-job-profile [epd]="uic"></app-edit-job-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Family</span></div>
                <div> <i class="pl-2 pi pi-users" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>
            <ng-container *ngIf="loadChild">
              <ng-template pTemplate="header">
                <div class="title-header-accordian">
                  <div><span class="ml-2">Family</span></div>
                  <div> <i class="pl-2 pi pi-users" style="font-size: 1.2rem !important"></i></div>
                </div>
              </ng-template>
              <app-edit-family-profile [epd]="uic"></app-edit-family-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Partner</span></div>
                <div> <i class="pl-2 pi pi-users" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>
            <ng-container *ngIf="loadChild">
              <app-edit-partner-profile [epd]="uic"></app-edit-partner-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">References</span></div>
                <div> <i class="pl-2 pi pi-users" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>
            <ng-container *ngIf="loadChild">
              <app-edit-reference-profile></app-edit-reference-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Other</span></div>
                <div> <i class="pl-2 pi pi-info-circle" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>
            <ng-container *ngIf="loadChild">
              <edit-other-profile [epd]="uic"></edit-other-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Home Address</span></div>
                <div> <i class="pl-2 pi pi-map-marker" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>
            <ng-container *ngIf="loadChild">
              <edit-home-address></edit-home-address>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>

            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Work Address</span></div>
                <div> <i class="pl-2 pi pi-map-marker" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>

            <ng-container *ngIf="loadChild">
              <edit-work-address [epd]="uic"></edit-work-address>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab>  
            
            <ng-template pTemplate="header">
              <div class="title-header-accordian">
                <div><span class="ml-2">Postal Address</span></div>
                <div> <i class="pl-2 pi pi-map-marker" style="font-size: 1.2rem !important"></i></div>
              </div>
            </ng-template>


            <ng-container *ngIf="loadChild">
              <edit-postal-address [epd]="uic"></edit-postal-address>
            </ng-container>
          </p-accordionTab>
        </p-accordion>

      </div>
    </ng-template>

  </ng-template>
</page>