<!-- The Modal -->
<div id="myModalConfirmWithResponse" class="modal">
  <div class="modal-content">
    <div class="row">
      <div class="col-md-12 text-left">
        <textarea rows="4" cols="45" [(ngModel)]="response"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-left">
        <button (click)="CallParentMethod(replyToId, response)" pButton color="primary"> Respond</button>
      </div>
      <div class="col-md-6 text-center">
        <button (click)=closewindow() pButton>Cancel</button>
      </div>
    </div>
  </div>
</div>