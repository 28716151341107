import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SignalStateService } from './StateService';

@Injectable()
export class GaurdService {
    userLoggedIn: string;
    tokeyKey = 'token';
    token: string;
    router: Router;
    constructor(_router: Router, private signalStateService: SignalStateService) {
        this.router = _router;
    }

    canActivate(): boolean {
        const self = this;
        let notthief = false;
        const token = self.getLocalToken();
        if (token === null || this.isObjectEmpty(token)) {
            notthief = false;
            self.router.navigateByUrl('/welcome');
        } else {
            notthief = true;
        }
        return notthief;
    }

    getLocalToken(): string {
        if (!this.token) {``
            this.token = JSON.parse(this.signalStateService.getValue(this.tokeyKey)());
        }
        return this.token;
    }

    isObjectEmpty(obj) {
        return JSON.stringify(obj) === "{}";
    }
}
