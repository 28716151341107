<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Address for Correspondence</div>
  </div>
  <div class="col-4">
    <textarea pInputText placeholder="Address for Correspondence" [(ngModel)]="ui.Address"
      style="width: 100%; height: 100px;" maxlength="1999"></textarea>
  </div>
  <div class="col-6"></div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Pincode</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Pincode" [(ngModel)]="ui.PinCode" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Postal Region</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="postalRegion" [(ngModel)]="postalRegionSelected" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Contact No. (Land Line)</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Contact No. (Land Line)" [(ngModel)]="ui.ContactNo" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Mobile No</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Mobile No" [(ngModel)]="ui.MobileNumber" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>
<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Country</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="countries" [(ngModel)]="countrySelectedValue" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" (onChange)="onCountryChange($event)" [filter]="true"
      filterBy="Name" [showClear]="true" placeholder="Select a Country"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1" *ngIf="showState">
  <div class="col-2">
    <div class="position-label">State</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="states" [(ngModel)]="stateSelectedValue" optionLabel="State" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" (onChange)="onStateChange($event)" [filter]="true"
      filterBy="Name" [showClear]="true" placeholder="Select a state"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>


<div class="flex py-1" *ngIf="showCity">
  <div class="col-2">
    <div class="position-label">City</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="cities" [(ngModel)]="citySelectedValue" optionLabel="City" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }" [filter]="true" filterBy="Name" [showClear]="true"
      placeholder="Select a city"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>
<div class="flex py-1">
  <div class="col-10"></div>
  <div class="col-2 text-right">
    <button pButton (click)="saveInformation()">SAVE</button>
  </div>
</div>

<p-toast position="bottom-right"></p-toast>