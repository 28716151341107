import { ConfirmDeleteCarrier } from "../../../../dto/ConfirmDeleteCarrier";
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { confirmResponseModel } from '../confirmWithResponse/model.component.domain';

@Component({
  selector: 'confirmdeletemodel',
  templateUrl: './confirmdeletemodel.component.html',
  styleUrls: ['./confirmdeletemodel.component.scss']
})
export class ConfirmdeletemodelComponent implements OnInit {
  @Input() cdc: ConfirmDeleteCarrier;
  @Output() confirmaction = new EventEmitter<ConfirmDeleteCarrier>();

  constructor() { }

  ngOnInit() {
    this.cdc = {} as ConfirmDeleteCarrier;
    this.cdc.show = false;
    this.cdc.confirmYesOrNo = false;
    this.cdc.id = 0;
  }

  close() {
    this.cdc.show = false;
  }
  
  actionFromUser() {
    this.cdc.show = false;
    this.cdc.confirmYesOrNo = true;
    this.confirmaction.next(this.cdc);
  }
}
