<p-card>
  <p-tabView (onChange)="handleChange($event)" [(activeIndex)]="activeIndex">
    <p-tabPanel header="Primary Information">
      <candidate-personal-primary-information></candidate-personal-primary-information>
    </p-tabPanel>
    <p-tabPanel header="Photos">
      <candidate-personal-image-collection></candidate-personal-image-collection>
    </p-tabPanel>
    <p-tabPanel header="Religious and Social Background">
      <candidate-personal-religion-information></candidate-personal-religion-information>
    </p-tabPanel>
    <p-tabPanel header="Partner Requirements">
      <candidate-personal-partner-information></candidate-personal-partner-information>
    </p-tabPanel>
    <p-tabPanel header="Education and Career">
      <candidate-personal-education-information></candidate-personal-education-information>
    </p-tabPanel>
    <p-tabPanel header="Family Details">
      <ng-template mat-tab-label>
        <img src="../../../../../../assets/image/Bookmark-Add.png" class="tab-icon" />
        <span>Family Details</span>
      </ng-template>
      <candidatefamilydetails></candidatefamilydetails>
    </p-tabPanel>
    <p-tabPanel header="Contact Details">
      <ng-template mat-tab-label>
        <img src="../../../../../../assets/image/Bookmark-Add.png" class="tab-icon" />
        <span>Contact Details</span>
      </ng-template>
      <candidatecontactdetails></candidatecontactdetails>
    </p-tabPanel>
  </p-tabView>
</p-card>