import { Dropdown2 } from "../../dto/Dropdown2";
import { superUserMine } from "../../dto/superUserMine";
import { RegisterNewMemberModel } from "../../dto/RegisterNewMemberModel";
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, } from '@angular/forms';
import { EmailAsyncValidator, EmailValidator, PhoneAsyncValidator, selectOneValidatorRegister } from 'src/app/common/util/validators/commonvalidator';
import * as _ from 'lodash-es';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Dropdown } from "src/app/dto/Dropdown";
import { UserInformation } from "src/app/db.service";

@Component({
  selector: 'publicregister',
  templateUrl: './publicregister.component.html',
  styleUrls: ['./publicregister.component.css'],
  providers: [MessageService]
})
export class PublicregisterComponent implements OnInit {
  primaryInformationFormGroup: FormGroup;
  inpMaritalStatus: Dropdown;
  inpReligion: Dropdown;
  religion: Dropdown[];
  denomination: Dropdown[];
  emailValidationErrorText: string = "";

  states: Dropdown[] = [];
  cities: Dropdown[] = [];
  nationality: Dropdown[] = [];
  height: Dropdown[] = [];
  genders: Dropdown[] = [];
  occupations: Dropdown[] = [];
  inpHeight: Dropdown;
  inpGender: Dropdown;
  inpDenomination: Dropdown;
  inpState: Dropdown;
  inpCity: Dropdown;
  inpNationality: Dropdown;
  inpOccupation: Dropdown;
  newUser: RegisterNewMemberModel = new RegisterNewMemberModel();
  userMartialStatus: Array<Dropdown>;
  maritalToSelected: Dropdown;
  userReligionDenomination: Array<Dropdown>;
  userDiocese: Array<Dropdown>;
  private _validEmail: boolean = true;

  public showStates: boolean = false;
  public showCity: boolean = false;
  router: Router;
  username: string;

  countrySelected: Dropdown;
  stateSelected: Dropdown;
  showFloatingLoader = true;




  get validEmail(): boolean {
    if (this._validEmail === false) {
      this.emailValidationErrorText = "Required miniimum length is 3 & Please provide valid email address";
    }
    else {
      this.emailValidationErrorText = "";
    }
    return this._validEmail;
  }
  set validEmail(value: boolean) {
    this._validEmail = value;
  }
  /**
   *
   */
  constructor(private fb: FormBuilder, private commonService: Commonservice, private messageService: MessageService, _router: Router) {
    this.router = _router;
  }

  ngOnInit() {
    this.fillSelectedItems();
    this.fillControls();
  }

  fillSelectedItems(): void {
    this.primaryInformationFormGroup = this.fb.group({
      userEmail: new FormControl(this.newUser.CandidateEmail, [Validators.required, Validators.minLength(3), EmailValidator], [EmailAsyncValidator(this.commonService)]),
      inpPhoneNumber: new FormControl(this.newUser.CandidatePhoneNumber, [Validators.required, Validators.minLength(3)], [PhoneAsyncValidator(this.commonService)]),
      inpPassword: new FormControl(this.newUser.CandidatePassword, [Validators.required, Validators.minLength(5)]),
      inpFirstName: new FormControl(this.newUser.CandidateFirstName, [Validators.required, Validators.minLength(3)]),
      inpMiddleName: new FormControl(this.newUser.CandidateMiddleName),
      inpLastName: new FormControl(this.newUser.CandidateLastName),
      inpMaritalStatus: new FormControl(this.newUser.CandidateMaritalStatus, [Validators.required, selectOneValidatorRegister]),
      inpGender: new FormControl(this.newUser.CandidateGender, [Validators.required, selectOneValidatorRegister]),
      inpHeight: new FormControl(this.newUser.CandidateHeight, [Validators.required, selectOneValidatorRegister]),
      inpReligion: new FormControl(this.newUser.CandidateReligionDenomination, [Validators.required, selectOneValidatorRegister]),
      inpState: new FormControl(this.newUser.CandidateState, [Validators.required, selectOneValidatorRegister]),
      inpCity: new FormControl(this.newUser.CandidateCity, [Validators.required, selectOneValidatorRegister]),
      inpNationality: new FormControl(this.newUser.CandidateCountry, [Validators.required, selectOneValidatorRegister]),
      inpOccupation: new FormControl(this.newUser.CandidateOccupation, [Validators.required, selectOneValidatorRegister]),
      inpDateOfBirth: new FormControl(this.newUser.CandidateDateOfBirth, [Validators.required, Validators.minLength(3)]),
      inpCouponCode: new FormControl(this.newUser.CouponCode),
    });
  }

  validateEmail(event) {
  }

  saveNewUser(): void {
    const self = this;
    self.newUser = {} as RegisterNewMemberModel;
    self.newUser.CandidateEmail = self.primaryInformationFormGroup.controls.userEmail.value;
    self.newUser.CandidatePhoneNumber = self.primaryInformationFormGroup.controls.inpPhoneNumber.value;
    self.newUser.CandidatePassword = self.primaryInformationFormGroup.controls.inpPassword.value;
    self.newUser.CandidateFirstName = self.primaryInformationFormGroup.controls.inpFirstName.value;
    self.newUser.CandidateMiddleName = self.primaryInformationFormGroup.controls.inpMiddleName.value;
    self.newUser.CandidateLastName = self.primaryInformationFormGroup.controls.inpLastName.value;
    self.newUser.CandidateMaritalStatus = parseFloat(self.primaryInformationFormGroup.controls.inpMaritalStatus.value);
    self.newUser.CandidateGender = parseFloat(self.primaryInformationFormGroup.controls.inpGender.value);
    self.newUser.CandidateReligionDenomination = parseFloat(self.primaryInformationFormGroup.controls.inpReligion.value);
    self.newUser.CandidateState = parseFloat(self.primaryInformationFormGroup.controls.inpState.value);
    self.newUser.CandidateCity = parseFloat(self.primaryInformationFormGroup.controls.inpCity.value);
    self.newUser.CandidateCountry = parseFloat(self.primaryInformationFormGroup.controls.inpNationality.value);
    self.newUser.CandidateOccupation = parseFloat(self.primaryInformationFormGroup.controls.inpOccupation.value);
    self.newUser.CandidateDateOfBirth = new Date(self.primaryInformationFormGroup.controls.inpDateOfBirth.value);
    self.newUser.CouponCode = self.primaryInformationFormGroup.controls.inpCouponCode.value;

    self.commonService.registerNewMember(self.newUser).subscribe(response => {
      if (response.InsertWorked) {
        let userToLogin = {} as superUserMine;
        userToLogin.Password = self.newUser.CandidatePassword;
        userToLogin.Username = response.Message;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.newUser.CandidateEmail + ' is registered' });
        self.loginHome(userToLogin);
      } else {
        self.messageService.add({ severity: 'error', summary: 'Service Message', detail: response.Message });
      }
    });
  }

  loginHome(user: superUserMine) {
    const self = this;
    self.commonService.login(user).subscribe(
      data => {
        self.runNow(data);
      },
      error => {
        self.catchError(error);
      }
    );
  }
  catchError(response: any) {
  }

  runNow(response: any) {
    const self = this;
    if (response.State === 1) {

      sessionStorage.clear();
      sessionStorage.setItem('token', response.AccessToken);
      sessionStorage.setItem('userLoggedIn', response.UserName);
      let ui = {} as UserInformation;
      ui.Name = response.Name;
      ui.UserName = response.UserName;
      ui.AccessToken = response.AccessToken;
      sessionStorage.setItem("loginResponse", JSON.stringify(response));
      sessionStorage.setItem("userLoggedIn", JSON.stringify(ui));
      self.router.navigateByUrl('/home');
    } else {
      alert(response.Msg);
    }
  }

  genericInputValidator(controlName: string): boolean {
    let inValid: boolean = false;
    let controlBeingValidated = this.primaryInformationFormGroup.get(controlName);

    if (controlBeingValidated.hasError('minlength') && controlBeingValidated.touched) {
      inValid = true;
    }

    if (controlBeingValidated.hasError('required') && controlBeingValidated.touched) {
      inValid = true;
    }

    return inValid;
  }

  genericDropDownListValidator(controlName: string): boolean {
    let inValid: boolean = false;

    let controlBeingValidated = this.primaryInformationFormGroup.get(controlName);

    if (controlBeingValidated.hasError('MinimumOneSelection') && controlBeingValidated.touched) {
      inValid = true;
    }

    if (controlBeingValidated.hasError('required') && controlBeingValidated.touched) {
      inValid = true;
    }

    return inValid;
  }

  fillControls() {
    const self = this;
    const ccn = self.commonService;
    const observables = [ccn.getMaritalStatus(), ccn.getPartnerReligion(), ccn.getCountry(), ccn.getHeight(), ccn.getGender(), ccn.getOccupations()];
    const joint = forkJoin(observables);
    joint.subscribe(
      s => {
        self.userMartialStatus = _.nth(s, 0);
        self.inpMaritalStatus = self.userMartialStatus[0];
        self.userReligionDenomination = _.nth(s, 1);
        self.inpReligion = self.userReligionDenomination.find(x => x["value"] === "0");;
        self.height = _.nth(s, 3);
        self.inpHeight = self.height.find(x => x["value"] === "0");
        self.genders = _.nth(s, 4);

        self.inpGender = _.first(self.genders);
        self.occupations = _.nth(s, 5);
        self.inpOccupation = self.occupations.find(x => x["value"] === "0");

        let countrySelected = _.nth(s, 2)[0].Value;
        ccn.getStatesBasedOnCountry(countrySelected).subscribe(x => {
          let stateSelected = x[0].Value;

          ccn.getCitiesBasedOnState(stateSelected.toString(), countrySelected).subscribe(y => {
            self.nationality = _.nth(s, 2);
            self.states.push(...x);
            self.showStates = true;
            self.showCity = true;
            self.inpState = self.states[0];
            self.cities.push(...y);
            self.inpNationality = self.nationality[0];
            self.inpState = self.states[0];
            self.inpCity = self.cities[0];
            self.showFloatingLoader = false;
          });
        });
      }
    )
  }

  onNationalityChange(event) {
    const self = this;
    const ccn = self.commonService;
    self.countrySelected = event.value;
    ccn.getStatesBasedOnCountry(event.value).subscribe(x => {
      if (x?.length > 0) {
        self.showStates = true;
        self.states = [];
        self.states.push(...x);
        self.inpState = self.states[0];
        self.stateSelected = x[0];
        ccn.getCitiesBasedOnState(self.stateSelected.Value.toString(), self.countrySelected.Value.toFixed()).subscribe(y => {
          self.showStates = true;
          self.showCity = true;
          self.cities = [];
          self.cities.push(...y);
          self.inpCity = self.cities[0];
        });
      }
      else {
        self.showStates = false;
      }
    });
  }

  appendSelect(incoming: Array<Dropdown>): Array<Dropdown> {
    let returnObject: Array<Dropdown> = [];
    let select: Dropdown = {
      Name: "Select",
      Value: 0
    }
    incoming.unshift(select);
    returnObject.push(...incoming);
    return returnObject;
  }

  appendSelectDropdown2(incoming: Array<Dropdown2>): Array<Dropdown2> {
    let returnObject: Array<Dropdown2> = [];
    let select: Dropdown2 = {
      Name: "Select",
      Value: "0"
    }
    incoming.unshift(select);
    returnObject.push(...incoming);
    return returnObject;
  }

  onStateChange(event) {
    const self = this;
    const ccn = self.commonService;
    self.stateSelected = event.value;
    ccn.getCitiesBasedOnState(self.stateSelected.toString(), self.countrySelected.toString()).subscribe(x => {
      if (x?.length > 0) {
        self.cities = [];
        self.cities.push(...x);
        self.inpCity = self.cities[0];
        self.showCity = true;
      }
      else {
        self.showCity = false;
      }
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    let hasError = this.primaryInformationFormGroup.controls[controlName].hasError(errorName);
    return hasError;
  }
  validationErrorExists() {
    return ((this.primaryInformationFormGroup || this.primaryInformationFormGroup.dirty) && !this.primaryInformationFormGroup.valid);
  }

  get userEmail() {
    const self = this;
    let userEmail = self.primaryInformationFormGroup.get('userEmail')
    return userEmail;
  };

  resetRegister() {

  }
}
