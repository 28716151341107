<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>

<div class="flex" *ngIf="!showLoader">
  <div class="col-12">
    <div class="flex table-container">
      <div class="col-6 text-left">Candidates Annual Income</div>
      <div class="col-6">
        {{ ui?.Candidate_Annual_Income || "--" }}
      </div>
    </div>
    <div class="flex table-container">
      <div class="col-6 text-left">Candidate's Share/Assets</div>
      <div class="col-6">{{ ui?.candidate_Assets || "--" }}</div>
    </div>
    <div class="flex table-container">
      <div class="col-6 text-left">
        About Candidate (School/College/institution
        Studied,Acheivements, Hobbies, Family Background, etc)
      </div>
      <div class="col-6">
        {{ ui?.candidate_Background || "--" }}
      </div>
    </div>
  </div>
</div>