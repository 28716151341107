// data.service.ts
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignalStateService } from './StateService';
import { APP_CONSTANTS } from '../contants/app.constants';

@Injectable({
    providedIn: 'root',
})
export class PrefetchBackGroundService {
    userloggedIn: string;
    constructor(private signalStateService: SignalStateService) { }

    updateBasicProfileInStore(data) {
        this.signalStateService.setValue(APP_CONSTANTS.BasicProfileDataSet, JSON.stringify(data));
    }

    storeResolutionRelatedData(data) {
        this.signalStateService.setValue(APP_CONSTANTS.ResolutionData, data);
    }
}
