import {
  Component,
  OnInit
} from '@angular/core';
import {
  User,
  menuLinks
} from '../home/home.component.domain';
import { HomePageUserInfoModel } from "src/app/dto/HomePageUserInfoModel";
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  hpuim: HomePageUserInfoModel;
  public user: User;
  public menuLinkArray: menuLinks[];
  userloggedIn: string;
  largerImage: string;
  userId: string;
  imageToDisplay: HostWindowModel;
  p: number = 1;
  yr: number = 1;
  savedSearchPageNumber: number = 1;
  bookmarkPage: number = 1;
  membersWhoViewedContactDetailsNumber = 1;
  ContactDetailViewedByYouNumber = 1;
  YourRecentVisitsOVCNumber = 1;
  ContactDetailViewedByYou: any[] = [];
  BookMarkedUsers: any[] = [];
  showLargeImage = false;
  constructor(private commonService: Commonservice, private localStore: LocalStore) { }


  ngOnInit() {
    const self = this;
    self.hpuim = {} as HomePageUserInfoModel;
    self.imageToDisplay = {} as HostWindowModel;
    self.ContactDetailViewedByYou = [];
    self.inititateUser();
    self.inititateMenu();
  }

  inititateUser() {
    const self = this;
    self.user = {} as User;
    // self.localStore.getCurrentLoggedInUser().then((result) => {
    //   self.userloggedIn = result.UserLoggedIn;;
    // });

    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;

    self.menuLinkArray = [];
    self.user.name = '';
    self.user.address = {};
    self.user.address.street = '';
    self.user.address.postcode = '';
    self.user.profileid = '';
    self.user.registereddate = new Date;
    self.user.membertype = '';
    self.user.renewaldate = new Date;
    self.user.userrating = 5;
    self.user.usersWhoGotBookmarked = [];
    self.user.savedSearches = [];
    self.user.membersWhoViewedYourContactDetail = [];
    self.user.contactDetailsViewedByYou = [];
    self.user.recentVisitorsToYourProfile = [];
    self.user.yourRecentVisits = [];
  }

  inititateMenu() {
    const self = this;
    const bookmarkMenu = {} as menuLinks;
    bookmarkMenu.name = 'My BookMark';
    bookmarkMenu.value = 1;
    const myProfile = {} as menuLinks;
    myProfile.name = 'My Profile';
    myProfile.value = 2;
    const modifyPassword = {} as menuLinks;
    modifyPassword.name = 'Modify Password';
    modifyPassword.value = 3;
    const reRegister = {} as menuLinks;
    reRegister.name = 'Re-Register';
    reRegister.value = 4;
    const deleteProfile = {} as menuLinks;
    deleteProfile.name = 'Delete Profile';
    deleteProfile.value = 5;
    const messagesInbox = {} as menuLinks;
    messagesInbox.name = 'Messages Inbox';
    messagesInbox.value = 6;
    const messagesOutbox = {} as menuLinks;
    messagesOutbox.name = 'Messages Outbox';
    messagesOutbox.value = 7;
    const expressinterestRecieved = {} as menuLinks;
    expressinterestRecieved.name = 'Express interest Recieved';
    expressinterestRecieved.value = 8;
    const expressinterestSent = {} as menuLinks;
    expressinterestSent.name = 'Express interest Sent';
    expressinterestSent.value = 9;
    self.menuLinkArray.push(bookmarkMenu);
    self.menuLinkArray.push(myProfile);
    self.menuLinkArray.push(modifyPassword);
    self.menuLinkArray.push(reRegister);
    self.menuLinkArray.push(deleteProfile);
    self.menuLinkArray.push(messagesInbox);
    self.menuLinkArray.push(messagesOutbox);
    self.menuLinkArray.push(expressinterestRecieved);
    self.menuLinkArray.push(expressinterestSent);
  }

  selectEasyReachOption(itemId: any) {

  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {

    }
  }

  callLargeImage(thumbnailId) {
    const self = this;
    let largerImageId = thumbnailId.replace("Tmb", "");
    self.commonService.getLargeMainImage(largerImageId).subscribe(
      data => {
        self.imageToDisplay.MainImage = data.MainImage;
      },
      error => {

      }
    );
  }
}
