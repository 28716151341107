import { Component, computed } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { EditPageMegaBucket } from 'src/app/dto/EditPageMegaBucket';
import * as _ from 'lodash';
import { Dropdown } from 'src/app/dto/Dropdown';
import { SetEditHomeAddressDto } from 'src/app/dto/SetEditHomeAddressDto';
import { SaveResponse } from 'src/app/dto/SaveResponse';
import { GetViewProfileHomeAddressDto } from 'src/app/dto/GetViewProfileHomeAddressDto';
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';
import { SignalBasedService } from 'src/app/common/reusable/servicecenter/SignalBasedService';

@Component({
  selector: 'edit-home-address',
  templateUrl: './edit-home-address.component.html',
  styleUrl: './edit-home-address.component.css',
  providers: [MessageService]
})
export class EditHomeAddressComponent {
  ui: GetViewProfileHomeAddressDto;
  // uic: EditPageMegaBucket;
  userloggedIn: string;
  cities: Dropdown[] = [];
  states: Dropdown[] = [];
  countries: Dropdown[] = [];
  postalRegion: Dropdown[] = [];
  countrySelectedValue: Dropdown;
  citySelectedValue: Dropdown;
  stateSelectedValue: Dropdown;
  showCity = false;
  showState = false;
  srm: SaveResponse;
  postalRegionSelected: Dropdown;
  cityOrDistrictSelected: Dropdown;
  uic = computed(() => this.signalBasedService.getApiData()());
  isMainPageLoading = this.signalBasedService.getIsLoading();

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, public loadingService: LoaderService, private signalBasedService: SignalBasedService) { }
  ngOnInit(): void {
    const self = this;

    self.ui = {} as GetViewProfileHomeAddressDto;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.getDataFromDb(result.UserName);
    });
  }

  getDataFromDb(userLoggedIn: string) {
    const self = this;
    const ccn = self.commonService;
    this.loadingService.startLoading();

    ccn.setViewProfileHomeAddress(userLoggedIn).subscribe((address: GetViewProfileHomeAddressDto) => {
      self.countries = self.uic().Countries;
      self.states = self.uic().HomeStates;
      self.cities = self.uic().HomeCities;
      self.postalRegion= self.uic().PostalRegion;
      self.ui = address;
      this.loadingService.stopLoading();
      self.processCountries(address);
      self.processStates(self.uic().HomeStates);
      self.processCities(self.uic().HomeCities);
    });
  }

  processCountries(s: GetViewProfileHomeAddressDto) {
    const self = this;
    self.ui = s;
    if (self.ui.Country != undefined) {
      let countrySelectedValueFromDb = self.uic().Countries.filter(x => x.Value.toString() === self.ui.Country)[0];
      self.countrySelectedValue = self.ui.Country === undefined ? self.uic().Countries[0] : countrySelectedValueFromDb;
    }
    else {
      self.countrySelectedValue = self.uic().Countries.filter(x => x.Value === 0)[0];
    }
    this.bindData();
  }

  processStates(x: Dropdown[]) {
    const self = this;
    if (x?.length > 0) {
      self.showState = true;
      if (self.ui.State != undefined) {
        let stateSelectedValueFromDb = self.uic().HomeStates.filter(x => x.Value.toString() === self.ui.State.toString())[0];
        self.stateSelectedValue = self.ui.State === undefined ? self.uic().HomeStates[0] : stateSelectedValueFromDb;
      }
      else {
        self.stateSelectedValue = self.uic().HomeStates.filter(x => x.Value.toString() === '0')[0];
      }
    }
    else {
      self.showState = false;
    }
  }

  processCities(x: Dropdown[]) {
    const self = this;
    if (x.length > 0) {
      if (self.ui.City != undefined) {
        let citySelectedValueFromDb = self.uic().HomeCities.filter(x => x.Value.toString() === self.ui.City)[0];
        self.citySelectedValue = self.ui.City === undefined ? self.uic().HomeCities[0] : citySelectedValueFromDb;
      }
      else {
        self.citySelectedValue = self.uic().HomeCities.filter(x => x.Value === 0)[0];
      }
      self.showCity = true;
    }
    else {
      self.showCity = false;
    }
  }

  onCountryChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.countrySelectedValue = event.value;
      ccn.getStatesBasedOnCountry(self.countrySelectedValue.Value.toString()).subscribe(x => {
        if (x?.length > 0) {
          self.showState = true;
          self.states = [];
          self.states.push(...x);          
        }
        else {
          self.showState = false;
        }
      });
    }
    else {
      self.stateSelectedValue = { Name: "Select", Value: 0 };
      self.citySelectedValue = { Name: "Select", Value: 0 };
      self.showState = false;
      self.showCity = false;
    }
  }

  onStateChange(event) {
    const self = this;
    const ccn = self.commonService;
    if (event.value != null) {
      self.stateSelectedValue = event.value;
      if (self.stateSelectedValue != undefined) {
        ccn.getCitiesBasedOnState(self.stateSelectedValue.Value.toString(), self.countrySelectedValue.Value.toString()).subscribe(x => {
          self.cities = [];
          if (x.length > 0) {
            self.cities = [];
            self.cities.push(...x);
            self.showCity = true;
          }
          else {
            self.showCity = false;
          }
        });
      }
    }
    else {
      self.citySelectedValue = { Name: "Select", Value: 0 };
      self.showCity = false;
    }
  }

  bindData() {
    const self = this;
    if (self.uic().PostalRegion.find(x => x.Value == self.ui.PostalRegion) != undefined) {
      self.postalRegionSelected = self.uic().PostalRegion.find(x => x.Value == self.ui.PostalRegion);
    }
    else {
      self.postalRegionSelected = _.first(self.uic().PostalRegion).value;
    }
  }

  saveInformation() {
    const self = this;
    let setEditHomeAddressDto: SetEditHomeAddressDto = {} as SetEditHomeAddressDto;
    setEditHomeAddressDto.Address = self.ui.Address;
    setEditHomeAddressDto.PinCode = self.ui.PinCode;
    setEditHomeAddressDto.PostalRegion = self.postalRegionSelected.Value;
    setEditHomeAddressDto.City = self.citySelectedValue.Value;
    setEditHomeAddressDto.State = self.stateSelectedValue.Value;
    setEditHomeAddressDto.Country = self.countrySelectedValue.Value;
    setEditHomeAddressDto.ContactNo = self.ui.ContactNo;
    setEditHomeAddressDto.MobileNumber = self.ui.MobileNumber;
    setEditHomeAddressDto.Username = self.userloggedIn;
    this.commonService.setEditHomeAddress(setEditHomeAddressDto).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        this.signalBasedService.fetchData(self.userloggedIn).subscribe(
          () => console.log('Data fetched successfully'),
          error => console.error('Error fetching data:', error)
        );
      },
      error => { }
    );
  }
}
