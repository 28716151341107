<page>
  <ng-template>
    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>
    <div>
      <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center">TRASH SENT</div>

          <div *ngIf="showSearchIcon">
            <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
          </div>
          <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">

            <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
              (ngModelChange)="globalSearch($event)" />

          </div>
        </div>
      </div>
      <p-divider />
      <div class="panel panel-default b-r-1">
        <div class="panel-header-container">
        </div>

        <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">

          <div class="control-section">
            <div class="barelist ">
              <div class="blue-t1 standard-content">
                <div *ngIf="sortedDataSend?.length === 0" class="no-value-text-ic">
                  No messages in sent trash
                </div>


                <ng-container [ngSwitch]="currentDeviceState">
                  <ng-container *ngSwitchCase="'desktop-landscape'">
                    <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                  </ng-container>


                  <ng-container *ngSwitchCase="'mobile-portrait'">
                    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'tablet-portrait'">
                    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                  </ng-container>

                </ng-container>

              </div>
              <div class="grid-footer" *ngIf="sortedDataSend?.length > 0">
                <p-divider />
                <ng-container [ngSwitch]="currentDeviceState">

                  <ng-container *ngSwitchCase="'desktop-landscape'">
                    <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'mobile-portrait'">
                    <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'tablet-portrait'">
                    <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
                  </ng-container>

                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ImageModelWindow [tc]="imageToDisplay"></ImageModelWindow>
      <deleterecord [cdc]="cdcmSend.show" (confirmaction)="deleteSentItem($event)">
      </deleterecord>
      <restoredeleterecord [cdc]="crcSend.show" (confirmaction)="RestoreSentItem($event)">
      </restoredeleterecord>

      <!-- ---------This section is for delete received ------ -->
      <ng-template [ngIf]="showReceivedDelete">
        <div [ngClass]="[ngdc.show ? 'overlay-v' : 'overlay-h']">
          <div [ngClass]="[ngdc.show ? 'show-pop-v' : 'show-pop-h']">
            <div class="model-box">
              <div class="mb-1">
                <div class="mb-1-1"></div>
                <div class="mb-1-2" (click)="closeReceived()">X</div>
              </div>
              <div class="mb-1">
                <div>
                  {{ ngdc.title }}
                </div>
              </div>
              <div class="mb-2">
                <div class="mb-2-1">
                  <button type="button" (click)="deleteReceivedItem(ngdc)" color="primary" pButton>
                    YES
                  </button>
                </div>
                <div class="mb-2-2">
                  <button type="button" (click)="closeReceived()" color="primary" pButton>
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- ---------This section is for delete received ------ -->

      <!-- ---------This section is for restore received received ------ -->
      <ng-template [ngIf]="showReceivedRestore">
        <div [ngClass]="[restoreReceived.show ? 'overlay-v' : 'overlay-h']">
          <div [ngClass]="[restoreReceived.show ? 'show-pop-v' : 'show-pop-h']">
            <div class="model-box">
              <div class="mb-1">
                <div class="mb-1-1"></div>
                <div class="mb-1-2" (click)="closeRestoreReceived()">X</div>
              </div>
              <div class="mb-1">
                <div>
                  {{ restoreReceived.title }}
                </div>
              </div>
              <div class="mb-2">
                <div class="mb-2-1">
                  <button type="button" (click)="RestoreReceievedItem(restoreReceived)" color="primary" pButton>
                    YES
                  </button>
                </div>
                <div class="mb-2-2">
                  <button type="button" (click)="closeRestoreReceived()" color="primary" pButton>
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- ---------This section is for restore received received ------ -->

    </div>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>
    <p-toast position="bottom-right"></p-toast>
    <p-confirmPopup></p-confirmPopup>



    <ng-template #mobilePortrait  *ngIf="pagedRecords?.length>0">
      <div *ngFor="let grid of pagedRecords"  class="flex flex-column">
        <div class="flex flex-row">
          <div class="col-10">
            <img [src]="grid.thumbNailImage" class="thumbnail-image" (click)="
            makeitbig(
              grid.candidateProfileImageTmb,
              grid.userTo
            )
          " />
          </div>
          <div class="col-2 action-box flex justify-content-center">

            <div *ngIf="grid?.showIconEngine">
              <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS" (click)="setShowIcon(grid)"></i>
            </div>
            <div *ngIf="!grid.showIconEngine" class="icon-set" @fadeInOutFast>
              <div class="ibo-1">
                <img pTooltip="DELETE PERMANENTLY" tooltipPosition="top"
                  src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                  (click)="openSingleSendDeleteModal($event, grid)" />
              </div>
              <div class="ibo-2">
                <img pTooltip="RESTORE" tooltipPosition="top" src="../../../../assets/image/restore2.png"
                  class="rc-ito-5" alt="" (click)="openSendRestoreModel($event, grid)" />
              </div>
            </div>

          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Name</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.UserToName }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ID</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.userTo }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Age</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.age }} Years</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Religion</div>
          <div class="col-8">
            <strong> : {{ grid.christaintype }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Height</div>
          <div class="col-8">
            <strong> : {{ grid.height }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">DATE</div>
          <div class="col-8">
            <strong> : {{ grid.userExpressedDate | date : "medium" }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">STATUS</div>
          <div class="col-8">
            <strong> : {{ grid.messageStatus }}</strong>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-table [value]="pagedRecords" *ngIf="pagedRecords?.length>0" [(selection)]="selectedMessageItems">
        <ng-template pTemplate="header">
          <tr class="text-block">
            <th style="width: 4rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>PHOTO</th>
            <th pSortableColumn="userToID"><p-sortIcon field="userToID"></p-sortIcon> TO</th>
            <th pSortableColumn="userExpressedDate"><p-sortIcon field="userExpressedDate"></p-sortIcon> DATE
            </th>
            <th pSortableColumn="messageStatus"><p-sortIcon field="messageStatus"></p-sortIcon> STATUS</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td>
              <p-tableCheckbox [value]="row"></p-tableCheckbox>
            </td>
            <td>
              <img [src]="row.thumbNailImage" class="thumbnail-image" (click)="
                makeitbig(
                  row.candidateProfileImageTmb,
                  row.userTo
                )
              " />
            </td>
            <td class="text-block">
              <div class="user-info">
                <div class="ui-1">
                  {{ row.userToID }}
                </div>
                <div class="ui-2">{{ row.userTo }}</div>
                <div class="ui-3">
                  {{ row.age }} Years/{{ row.height }}/{{
                  row.christaintype
                  }}
                </div>
              </div>
            </td>
            <td class="text-block">
              <div class="date-info">
                <div class="di-1">
                  {{ row.userExpressedDate | date : "medium" }}
                </div>
                <div class="di-2"></div>
              </div>
            </td>
            <td class="text-block">
              <div class="date-info">
                <div class="di-1">{{ row.messageStatus }}</div>
                <div class="di-2">
                  (Changed On: {{ row.messageStatusChangeDate }})
                </div>
              </div>
            </td>
            <td>
              <div class="action-box-2">
                <div class="action-box-2 text-right">
                  <div *ngIf="row?.showIconEngine">
                    <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                      (click)="setShowIcon(row)"></i>
                  </div>
                  <div *ngIf="!row.showIconEngine" class="icon-set" @fadeInOutFast>
                    <div class="ibo-1">
                      <img pTooltip="DELETE PERMANENTLY" tooltipPosition="top"
                        src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                        (click)="openSingleSendDeleteModal($event, row)" />
                    </div>
                    <div class="ibo-2">
                      <img pTooltip="RESTORE" tooltipPosition="top" src="../../../../assets/image/restore2.png"
                        class="rc-ito-5" alt="" (click)="openSendRestoreModel($event, row)" />
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="caption">
          <div style="font-family: 'ComfortaaRegular' ;">
            Total Records: {{totalRecords}}
          </div>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template #mobilePortraitFooter>
      <div class="col-12 justify-content-center">
        <ng-container *ngIf="sortedDataSend?.length > 0">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </ng-container>
      </div>
    </ng-template>

    <ng-template #desktopLandscapeFooter>
      <div class="flex py-1">
        <div class="col-1 align-footer">
          <span class="label label-primary">Total Count {{ totalRecords }}</span>
        </div>

        <div class="col-9 justify-content-center">
          <ng-container *ngIf="sortedDataSend?.length > 0">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true"
              [pageLinks]="5" [showPageLinks]="true" [showJumpToPageDropdown]="false"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
          </ng-container>
        </div>

        <div class="col-2 text-right">
          <button pButton *ngIf="sortedDataSend?.length > 0" (click)="openMultiDeleteModal()">
            Delete
          </button>
        </div>
      </div>
    </ng-template>

  </ng-template>
</page>