<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Name</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Name" [(ngModel)]="ui.candidate_name" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Date Of Birth</div>
  </div>
  <div class="col-4">
    <p-calendar [(ngModel)]="ui.candidates_dob" placeholder="Date Of Birth" [style]="{'width':'100%'}"
      [inputStyle]="{'width':'100%'}"></p-calendar>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Height</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Height" [(ngModel)]="ui.candidate_Height" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Weight</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Weight" [(ngModel)]="ui.candidate_Weight" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Diocese</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.Diocese" [(ngModel)]="dioceseSelected" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Denomination</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.Denomination" [(ngModel)]="denominationSelected" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1" style="padding-top: 10px !important;">
  <div class="col-2">
    <div class="position-label">Gender</div>
  </div>
  <div class="col-4 horizontal-divs">
    <div *ngFor="let gender of uic.Gender" class="field-checkbox" style="margin-right: 10px !important; ">
      <p-radioButton [inputId]="gender" name="gender" [value]="gender" [(ngModel)]="genderSelected"></p-radioButton>
      <label [for]="gender">{{ gender.Name }}</label>
    </div>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Complexion</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.Complexion" [(ngModel)]="complexionSelected" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Marital Status</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.MaritalStatus" [(ngModel)]="maritalStatusSelected" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Physical Status</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.PhysicalStatus" [(ngModel)]="physicalStatusSelected" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Education</div>
  </div>
  <div class="col-4">
    <p-dropdown [options]="uic.EducationalCategory" [(ngModel)]="educationCategorySelected" optionLabel="Name"
      [style]="{ width: '100%', overflow: 'visible' }"></p-dropdown>
  </div>
  <div class="col-6"></div>
</div>


<div class="flex py-1">
  <div class="col-2">
    <div class="position-label">Education subject</div>
  </div>
  <div class="col-4">
    <input pInputText placeholder="Occupation" [(ngModel)]="ui.candidate_Education_Subject" style="width: 100%" />
  </div>
  <div class="col-6"></div>
</div>

<div class="flex py-1">
  <div class="col-10"></div>
  <div class="col-2 text-right">
    <button pButton (click)="saveInformation()">SAVE</button>
  </div>
</div>
<p-toast position="bottom-right"></p-toast>