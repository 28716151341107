import { Routes } from '@angular/router';
import { BookmarkComponent } from './bookmark.component';
import { GaurdService } from '../../../common/reusable/servicecenter/gaurdService.component';




// Route Configuration
export const hBTRoute: Routes = [
  { path: 'bookmark', canActivate: [GaurdService], component: BookmarkComponent }
];
