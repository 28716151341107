<!-- <div class='headerLine'>
  <div class='Aligner'>
    <div class='Aligner-item Aligner-item--fixed'>
      <div class='table-responsive'>
        <table class='table my-table'>
          <tr>
            <td class='my-cell'>
              <a class='menu-link' [routerLink]="['/welcome']" routerLinkActive="active">Home</a>
            </td>
            <td class='my-cell'>
              <div class='seperator'></div>
            </td>
            <td class='my-cell'>
              <a class='menu-link' [routerLink]="['/guestsearch']" routerLinkActive="active">Search</a>
            </td>
            <td class='my-cell'>
              <div class='seperator'></div>
            </td>
            <td class='my-cell'>
              <a class='menu-link' [routerLink]="['/register']" routerLinkActive="active">Register</a>
            </td>
            <td class='my-cell'>
              <div class='seperator'></div>
            </td>
            <td class='my-cell'>
              <a class='menu-link' [routerLink]="['/paymentoptions']" routerLinkActive="active">Payment Options</a>
            </td>
            <td class='my-cell'>
              <div class='seperator'></div>
            </td>
            <td class='my-cell'>
              <a class='menu-link' [routerLink]="['/aboutus']" routerLinkActive="active"> About Us</a>
            </td>
            <td class='my-cell'>
              <div class='seperator'></div>
            </td>
            <td class='my-cell'>
              <a class='menu-link' [routerLink]="['/contactus']" routerLinkActive="active"> Contact Us</a>
            </td>
          </tr>
          <tr>
            <td colSpan='11' class='my-cell text-center'>
              COPYRIGHT 2023 - Sacred Hearts Matrimony, ALL RIGHTS RESERVED
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</div> -->


<div class="footer">
  <div class="">
    <a class="menu-link" [routerLink]="['/welcome']" routerLinkActive="active">Home</a>
    <a class="menu-link" [routerLink]="['/guestsearch']" routerLinkActive="active">Search</a>
    <a class="menu-link" [routerLink]="['/register']" routerLinkActive="active">Register</a>
    <a class="menu-link" [routerLink]="['/paymentoptions']" routerLinkActive="active">Payment Options</a>
    <a class="menu-link" [routerLink]="['/aboutus']" routerLinkActive="active">About Us</a>
    <a class="menu-link" [routerLink]="['/contactus']" routerLinkActive="active">Contact Us</a>
  </div>
  <div class="copyright">
    COPYRIGHT 2023 - Sacred Hearts Matrimony, ALL RIGHTS RESERVED
  </div>
</div>