<div id="hostModel" [style.display]="tc.show == true ? 'block' : 'none'" class="w3-modal">
  <div class="w3-modal-content w3-animate-zoom w3-card-4">
    <div class="w3-container">

      <div class="barelist">
        <div class="text-right">
          <div class='row'>
            <div class='col-md-7 text-left'>
              IMAGE CATALOGUE
            </div>
            <div class='col-md-4'>

            </div>
            <div class='col-md-1'>
              <img (click)="closewindow()" src="../../../../../assets/icons/Close.png" class="close-button" alt="">
            </div>
          </div>
        </div>
        <div>

          <div class='row'>
            <div class='r-p-0 col-md-8'>
              <img [src]="tc.MainImage" class="image-display img-thumbnail">
            </div>
            <div class='col-md-4'>
              <table class="full-width">
                <tr>
                  <td>
                    <div class="my-horizontal-list ">
                      <div *ngFor='let item of tc.ThumbNailImages'>
                        <img [src]="item.Image" class="image-display-thumbnail img-thumbnail"
                          (click)="callLargeImage(item.Id)">
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a [routerLink]="[tc.RouterPath,tc.UserId]" target="_blank" class="btn btn-link classify-me" pButton
                      class="my-button space-it" color="primary">Full Profile</a>
                  </td>
                </tr>
              </table>
            </div>
          </div>






        </div>
      </div>
    </div>
  </div>
</div>