

export class ExpressInterestCarrier {
    fromId: string;
    userToId: string;
    userToName: string;
    currentDate: string;
}


