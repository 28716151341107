import { Component, OnInit } from '@angular/core';
import { userInfo, userModel } from './modifypassword.component.domain';
import { Commonservice } from '../../../common/reusable/servicecenter/commonservice.component';
import { LocalStore } from '../../../common/reusable/globalservice/internalflyweight.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-modifypassword',
  templateUrl: './modifypassword.component.html',
  styleUrls: ['./modifypassword.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ModifypasswordComponent implements OnInit {
  password2: string;
  password1: string;
  username: string;
  public usermodel: userModel;
  disableSubmit: boolean = true;
  disableReset: boolean = true;
  passwordMismatchError: string = '';
  constructor(private commonservice: Commonservice, private localStore: LocalStore, private messageService: MessageService, private confirmationService: ConfirmationService) { }
  ngOnInit() {
    const self = this;
    self.username = "";
    self.password1 = "";
    self.usermodel = {} as userModel;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.username = result.UserName;
    });
  }

  // Function to handle password mismatch validation
  validatePasswordMatch() {
    if (this.password1 !== this.password2) {
      this.passwordMismatchError = 'Passwords do not match. Please fix.';
    } else {
      this.passwordMismatchError = '';
    }
  }

  onSubmit(form: NgForm) {
    const self = this;
    if (self.password1 !== self.password2) {
      self.disableSubmit = false;
    } else {
      if (self.password1.trim().length > 0 && self.password2.trim().length > 0) {
        self.disableSubmit = true;
        self.usermodel.Username = self.username;
        self.usermodel.Password = self.password1;
        self.commonservice.changePassword(self.usermodel).subscribe(
          data => {
            self.run(data);
          },
          error => { }
        );
      }
    }
  }

  run(data: any) {
    const self = this;
    if (data.ActivityWorked) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
      self.usermodel = {} as userModel;
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: data.Error });
    }
  }

  cancel() {
    const self = this;
    self.usermodel = {} as userModel;
  }

  resetForm() {
    this.password1 = '';
    this.password2 = '';
  }

}
