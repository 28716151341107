import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { finalize, tap } from "rxjs";
import { LambdaService } from "./LambdaService";

@Injectable({
    providedIn: 'root'
})
export class SignalBasedService {
    connect = "";
    private apiData = signal<any>(null);
    private isLoading = signal<boolean>(false);

    constructor(private http: HttpClient, private lambdaService: LambdaService) { 
        this.connect = this.lambdaService.getLambdaUrl('internalApiCalls');
    }

    getApiData() {
        return this.apiData.asReadonly();
    }

    getIsLoading() {
        return this.isLoading.asReadonly();
    }

    fetchData(username: string) {
        this.isLoading.set(true);
        return this.http.get<EditPageMegaBucket>(this.connect + '/HomePage/GetEditPageObjects/' + username).pipe(
            finalize(() => this.isLoading.set(false)),
            tap(data => this.apiData.set(data))
        );
    }
}