<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<div class="demo-tab-content" *ngIf="!showLoader">
  <div class="flex flex-wrap table-container">

    <div class="col-4">
      <div class="flex py-1">
        <div class="col-6 text-left">Name oF Firm</div>
        <div class="col-6">
          {{ ui?.candidate_Name_Firm || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-6 text-left">Working Country</div>
        <div class="col-6">
          {{ ui?.countryName || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-6 text-left">Working City</div>
        <div class="col-6">
          {{ ui?.cityName || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-6 text-left">When Is The Next Leave</div>
        <div class="col-6">
          {{ ui?.candidate_NextLeave || "--" }}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="flex py-1">
        <div class="col-6 text-left">Visa Status/ Family Visa etc</div>
        <div class="col-6">
          {{ ui?.candidate_visaStatus || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-6 text-left">Education Category</div>
        <div class="col-6">{{ ui?.educationCategory || "--" }}</div>
      </div>
      <div class="flex py-1">
        <div class="col-6 text-left">
          Fathers Native Place (Family Roots At)
        </div>
        <div class="col-6">
          {{ ui?.Father_NativeDitrict || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-6 text-left">Job Catagory</div>
        <div class="col-6">{{ ui?.jobCategory || "--" }}</div>
      </div>
    </div>
    <div class="col-4">
      <div class="flex py-1">
        <div class="col-6 text-left">Parents Settled at</div>
        <div class="col-6">{{ ui?.parentssettledat || "--" }}</div>
      </div>
    </div>





  </div>
</div>