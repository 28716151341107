<page>
  <ng-template>
    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>
    <div>

      <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center">EXPRESS INTEREST RECEIVED</div>
          <div *ngIf="showSearchIcon">
            <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
          </div>
          <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">
            <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
              (ngModelChange)="globalSearch($event)" />
          </div>
        </div>
      </div>



      <p-divider />

      <div class="panel panel-default">
        <div class="panel-header-container">
        </div>
        <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">

          <div class="control-section standard-content">

            <div *ngIf="sortedData?.length === 0" class="no-value-text-ic">
              No express interest recieved yet
            </div>

            <div *ngIf="sortedData.length > 0">
              <ng-container [ngSwitch]="currentDeviceState">

                <ng-container *ngSwitchCase="'desktop-landscape'">
                  <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'mobile-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'tablet-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>

              </ng-container>
            </div>
          </div>
          <div class="grid-footer" *ngIf="messageData?.length > 0">
            <p-divider />
            <ng-container [ngSwitch]="currentDeviceState">

              <ng-container *ngSwitchCase="'desktop-landscape'">
                <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'mobile-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'tablet-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>

            </ng-container>
          </div>
        </div>
      </div>

    </div>

    <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [breakpoints]="{ '960px': '75vw' }"
      [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false">
      <p>
        {{ vmsg.modelContent }}
      </p>
      <ng-template pTemplate="footer">
        <p-button (click)="showMessage = false" label="Keep it pending" styleClass="p-button-text"></p-button>
        <p-button (click)="acceptInterest(vmsg)" label="I accept" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>
    <p-confirmPopup></p-confirmPopup>
    <p-toast position="bottom-right"></p-toast>

    <ng-template #cellTemplate let-value>
      <td>
        @if (value !== null && value !== undefined) {
        @if (isDate(value)) {
        {{ value | date: "short" }}
        } @else {
        {{ value }}
        }
        } @else {
        Not available
        }
      </td>
    </ng-template>



    <ng-template #mobilePortrait>
      <div *ngFor="let grid of pagedRecords" class="flex flex-column">
        <div class="flex flex-row">
          <div class="col-11">
            <img [src]="grid.thumbNailImage" class="thumbnail-image" (click)="
            makeitbig(
              grid.candidateProfileImageTmb,
              grid.userFromID
            )
          " />
          </div>
          <div class="col-1">
            <div class="action-box flex justify-content-between md:justify-content-start">
              <div *ngIf="grid?.showIconEngine">
                <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                  (click)="setShowIcon(grid)"></i>
              </div>
              <div *ngIf="!grid.showIconEngine" class="icon-set" @fadeInOutFast>
                <card-member-actions [userAction]="{
                  fromId: grid.userToID,
                  userToId: grid.userFromID,
                  userToName: grid.UserFromName.toUpperCase(),
                  ShowSendMessage: false,
                  sendMessageText: sendMessageText ?? '',
                  Id: grid.inboxID,
                  tableToDelete:'ExpressInterestRecieved'
                }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Name</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.UserFromName }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ID</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.userFrom }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Age</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.age ? grid.age + ' Years' : '' }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Religion</div>
          <div class="col-8">
            <strong> : {{ grid.christaintype }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Height</div>
          <div class="col-8">
            <strong> : {{ grid.height }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Message</div>
          <div class="col-8 text-left">
            @if(grid.acceptanceStatus){
            {{grid?.textMessageFromUser}}
            }
            @else{
            : <i class="pi pi-eye" (click)="viewMessage(grid)" pTooltip="VIEW MESSAGE" tooltipPosition="top"></i>
            }
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Expressed On</div>
          <div class="col-8">
            <strong> : {{ grid.userExpressedDate | date : "medium" }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ACCEPTED</div>
          <div class="col-8">
            <strong> :
              @if(grid.acceptanceStatus){
              Yes
              }
              @else{
              Not Yet
              }
            </strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ACCEPTED ON</div>
          <div class="col-8">
            <strong> : <ng-container
                *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.AcceptedOn }"></ng-container></strong>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-table [value]="sortedData" [(selection)]="selectedMessageItems">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>PHOTO</th>
            <th pSortableColumn="userFrom"><p-sortIcon field="userFrom"></p-sortIcon> ID</th>
            <th pSortableColumn="UserFromName"><p-sortIcon field="UserFromName"></p-sortIcon> FROM</th>
            <th pSortableColumn="age"><p-sortIcon field="age"></p-sortIcon> AGE</th>
            <th pSortableColumn="height"><p-sortIcon field="height"></p-sortIcon> HEIGHT</th>
            <th pSortableColumn="userExpressedDate"><p-sortIcon field="userExpressedDate"></p-sortIcon> RECEIVED
              ON</th>
            <th style="text-align: center;">MESSAGE</th>
            <th Style="text-align: center;">ACCEPTED </th>
            <th pSortableColumn="AcceptedOn"><p-sortIcon field="AcceptedOn"></p-sortIcon> ACCEPTED ON</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td>
              <p-tableCheckbox [value]="row"></p-tableCheckbox>
            </td>
            <td>
              <div *ngIf="row.thumbNailImage">
                <img [src]="row.thumbNailImage" class="thumbnail-image" (click)="
                    makeitbig(
                      row.candidateProfileImageTmb,
                      row.userFromID
                    )
                  " />
              </div>
            </td>
            <td> {{ row.userFrom }}</td>
            <td> {{ row.UserFromName }}</td>
            <td> {{ row.age ? row.age + ' Years' : '' }}</td>
            <td> {{ row.height }}</td>
            <td>{{ row.userExpressedDate | date : "medium" }}</td>
            <td style="text-align: center;">
              <i class="pi pi-envelope" (click)="viewMessage(row)" pTooltip="VIEW MESSAGE" tooltipPosition="top"
                style="color: #00cdfb; font-size: 20px;"></i>
            </td>
            <td style="text-align: center;">
              @if(row.acceptanceStatus){
              <i class="pi pi-thumbs-up" style="color: #ff9b04; font-size: 20px;" pTooltip="Accepted"></i>
              }
              @else{
              <i class="pi pi-hourglass" style="color: #cf5b86; font-size: 20px;" pTooltip="WAITING.."
                tooltipPosition="top"></i>
              }
            </td>
            <td>
              <ng-container *ngTemplateOutlet="cellTemplate; context: { $implicit: row.AcceptedOn }"></ng-container>
            </td>
            <td>
              <div class="action-box-2 text-right">

                <div *ngIf="row?.showIconEngine">
                  <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                    (click)="setShowIcon(row)"></i>
                </div>
                <div *ngIf="!row.showIconEngine" class="icon-set" @fadeInOutFast>
                  <card-member-actions [userAction]="{
                fromId: row.userToID,
                userToId: row.userFromID,
                userToName: row.UserFromName.toUpperCase(),
                ShowSendMessage: false,
                sendMessageText: sendMessageText ?? '',
                Id: row.Id,
                tableToDelete:'ExpressInterestRecieved'
              }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="caption">
          <div style="font-family: 'ComfortaaRegular' ;">
            Total Records: {{messageData.length}}
          </div>
        </ng-template>
      </p-table>
    </ng-template>

    <ng-template #mobilePortraitFooter>
      <div class="flex py-1">
        <div class="col-12">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </div>

      </div>
    </ng-template>

    <ng-template #desktopLandscapeFooter>
      <div class="flex py-1">
        <div class="col-1 align-footer">
          <span class="label label-primary">Total Count {{ messageData.length }}</span>
        </div>
        <div class="col-9">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </div>
        <div class="col-2">
          <div class="text-right">
            <button pButton *ngIf="messageData.length > 0" (click)="openMultiDeleteModal()">
              Delete
            </button>
          </div>
        </div>
      </div>
    </ng-template>



  </ng-template>
</page>