import { LoginhomeoffersComponent } from './public/publichome/loginhomeoffers/loginhomeoffers.component';
import { LoginaboutComponent } from './public/publichome/loginabout/loginabout/loginabout.component';
import { ProfilesearchhomeComponent } from './public/publichome/profilesearchhome/profilesearchhome.component';
import { LoginchomeComponent } from './public/publichome/loginchome/loginchome.component';
import { LoginComponent } from './public/login/login.component';
import { StoragevaultComponent } from './private/vault/storagevault/storagevault.component';
import { QuickSearchComponent } from './private/search/quicks/quicks.component';
import { AdvancedSearchComponent } from './private/search/advanced/advanced.component';
import { ViewComponent } from './private/profile/view/view.component';
import { ReregisterComponent } from './private/profile/reregister/reregister.component';
import { ModifypasswordComponent } from './private/profile/modifypassword/modifypassword.component';
import { EditprofileComponent } from './private/profile/editprofile/editprofile.component';
import { DeleteprofileComponent } from './private/profile/deleteprofile/deleteprofile.component';
import { InboxComponent } from './private/mymessages/inbox/inbox.component';
import { HomeComponent } from './private/dashboard/home/home.component';
import { BookmarkComponent } from './private/dashboard/bookmark/bookmark.component';
import { PublicsearchComponent } from './public/publicsearch/publicsearch.component';
import { PublichomeComponent } from './public/publichome/publichome.component';
import { PubliccontactusComponent } from './public/publiccontactus/publiccontactus.component';
import { PublicaboutusComponent } from './public/publicaboutus/publicaboutus.component';
import { ExpressInterestSentComponent } from './private/expressinterest/sent/sent.component';
import { ExpressInterestRecievedComponent } from './private/expressinterest/recieved/recieved.component';
import { MyMessagesSentComponent } from './private/mymessages/sent/sent.component';
import { PublicregisterComponent } from './public/publicregister/publicregister.component';
import { PublicpaymentoptionsComponent } from './public/publicpaymentoptions/publicpaymentoptions.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StepsModule } from 'primeng/steps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PublicpageComponent } from './common/public/publicpage/publicpage.component';
import { PublicpagebodyComponent } from './common/public/publicpagebody/publicpagebody.component';
import { PublicpageheaderComponent } from './common/public/publicpageheader/publicpageheader.component';
import { PublicpagefooterComponent } from './common/public/publicpagefooter/publicpagefooter.component';
import { PrivatepageComponent } from './common/private/privatepage/privatepage.component';
import { PrivatepagebodyComponent } from './common/private/privatepagebody/privatepagebody.component';
import { PrivatepageheaderComponent } from './common/private/privatepageheader/privatepageheader.component';
import { PrivatepagefooterComponent } from './common/private/privatepagefooter/privatepagefooter.component';
import { PageComponent } from './common/reusable/page/page.component';
import { AuthgaurdComponent } from './common/reusable/authgaurd/authgaurd.component';
import { FooterComponent } from './common/reusable/footer/footer.component';
import { MenuComponent } from './common/reusable/menu/menu.component';
import { RatingComponent } from './common/reusable/rating/rating.component';
import { SearchinmenuComponent } from './common/reusable/searchinmenu/searchinmenu.component';
import { TopdashboardComponent } from './common/reusable/topdashboard/topdashboard.component';
import { TopdashboardloginComponent } from './common/reusable/topdashboardlogin/topdashboardlogin.component';
import { ConfirmModelComponent } from './common/reusable/model/confirmModel/model.component';
import { DisplayModelComponent } from './common/reusable/model/DisplayComponent/model.component';
import { ResponseModelComponent } from './common/reusable/model/confirmWithResponse/model.component';
import { ConfirmModelYesOrNoComponent } from './common/reusable/model/confirmYesOrNo/model.component';
import { PublictopmenuComponent } from './common/public/publictopmenu/publictopmenu.component';
import { SlideshowComponent } from './common/public/slideshow/slideshow.component';
import { BluebuttonComponent } from './common/reusable/bluebutton/bluebutton.component';
import { TopbarComponent } from './common/reusable/topbar/topbar.component';
import { LoaderComponent } from './common/reusable/loader/loader.component';
import { MenuService } from './common/reusable/menu/menu.component.services';
import { Commonservice } from './common/reusable/servicecenter/commonservice.component';
import { ViewExternalService } from './private/profile/view/view.component.service';
import { ViewInternalService } from "./private/profile/view/ViewInternalService";
import { LocalStore } from './common/reusable/globalservice/internalflyweight.service';
import { GaurdService } from './common/reusable/servicecenter/gaurdService.component';
import { CommonNewservice } from './common/reusable/servicecenter/commonNewService';
import { W3messagedeliverymodelComponent } from './common/reusable/model/w3messagedeliverymodel/w3messagedeliverymodel.component';
import { DatePipe } from '@angular/common';
import { ViewotherprofileComponent } from './private/profile/thirdeye/viewotherprofile/viewotherprofile.component';
import { UserboardComponent } from './private/profile/thirdeye/viewotherprofile/userboard/userboard.component';
import { UsertabboardComponent } from './private/profile/thirdeye/viewotherprofile/usertabboard/usertabboard.component';
import { UsermenuboardComponent } from './private/profile/thirdeye/viewotherprofile/usermenuboard/usermenuboard.component';
import { CandidatefaimlydetailsComponent } from './private/profile/thirdeye/viewotherprofile/candidatefaimlydetails/candidatefaimlydetails.component';
import { CandidatecontactdetailsComponent } from './private/profile/thirdeye/viewotherprofile/candidatecontactdetails/candidatecontactdetails.component';
import { W3modelwithresponseComponent } from './common/reusable/model/w3modelwithresponse/w3modelwithresponse.component';
import { MycardComponent } from './common/reusable/mycard/mycard.component';
import { HostmodelComponent } from './common/reusable/model/hostmodel/hostmodel.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SafeHtml } from './common/util/SafeHtml';
import { ProgressBar } from './common/util/progressbar/probressbar.component';
import { SpacerComponent } from './common/util/spacer.component';
import { PrivatesearchbyprofileComponent } from './private/search/privatesearchbyprofile/privatesearchbyprofile.component';
import { PublicsearchbyprofileComponent } from './public/publicsearchbyprofile/publicsearchbyprofile.component';
import { MysupermodelComponent } from './common/reusable/mysupermodel/mysupermodel.component';
import { ConfirmdeletemodelComponent } from './common/reusable/model/confirmdeletemodel/confirmdeletemodel.component';
import { ExpressinterestComponent } from './common/reusable/model/expressinterest/expressinterest.component';
import { SendmessageComponent } from './common/reusable/model/sendmessage/sendmessage.component';
import { DeleterecordComponent } from './common/reusable/model/deleterecord/deleterecord.component';
import { ViewmessageComponent } from './common/reusable/model/viewmessage/viewmessage.component';
import { ViewmessagereadonlyComponent } from './common/reusable/model/viewmessagereadonly/viewmessagereadonly.component';
import { RestoredeleterecordComponent } from './common/reusable/model/restoredeleterecord/restoredeleterecord.component';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FieldsetModule } from 'primeng/fieldset';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { EditBasicProfileComponent } from './private/profile/editprofile/edit-basic-profile/edit-basic-profile.component';
import { EditJobProfileComponent } from './private/profile/editprofile/edit-job-profile/edit-job-profile.component';
import { EditFamilyProfileComponent } from './private/profile/editprofile/edit-family-profile/edit-family-profile.component';
import { EditContactProfileComponent } from './private/profile/editprofile/edit-postal-address/edit-postal-address.component';
import { EditReferenceProfileComponent } from './private/profile/editprofile/edit-reference-profile/edit-reference-profile.component';
import { EditImagesProfileComponent } from './private/profile/editprofile/edit-images-profile/edit-images-profile.component';
import { EditOtherProfileComponent } from './private/profile/editprofile/edit-other-profile/edit-other-profile.component';
import { EditPartnerProfileComponent } from './private/profile/editprofile/edit-partner-profile/edit-partner-profile.component';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { MyHttpInterceptor } from './common/reusable/servicecenter/MyHttpInterceptor';
import { DividerModule } from 'primeng/divider';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { LargeImageWindowComponent } from './common/reusable/large-image-window/large-image-window.component';
import { ImageModule } from 'primeng/image';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { SendMessageComponent } from './common/reusable/send-message/send-message.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { UserBareMinimalProfileComponent } from './private/dashboard/home/user-bare-minimal-profile/user-bare-minimal-profile.component';
import { HomeBookmarkProfileComponent } from './private/dashboard/home/home-bookmark-profile/home-bookmark-profile.component';
import { HomeSavedSearchesComponent } from './private/dashboard/home/home-saved-searches/home-saved-searches.component';
import { HomeMembersWhoViewedContactDetailsComponent } from './private/dashboard/home/home-members-who-viewed-contact-details/home-members-who-viewed-contact-details.component';
import { HomeContactDetailsViewedByMeComponent } from './private/dashboard/home/home-contact-details-viewed-by-me/home-contact-details-viewed-by-me.component';
import { HomeRecentVisitorToMyProfileComponent } from './private/dashboard/home/home-recent-visitor-to-my-profile/home-recent-visitor-to-my-profile.component';
import { HomeMyRecentVisitsComponent } from './private/dashboard/home/home-my-recent-visits/home-my-recent-visits.component';
import { ViewProfileBasicComponent } from './private/profile/view/view-profile-basic/view-profile-basic.component';
import { ViewProfileJobInformationComponent } from './private/profile/view/view-profile-job-information/view-profile-job-information.component';
import { ViewProfileFamilyDetailsComponent } from './private/profile/view/view-profile-family-details/view-profile-family-details.component';
import { ViewProfilePostComponent } from './private/profile/view/view-profile-post/view-profile-post.component';
import { ViewProfilePictureComponent } from './private/profile/view/view-profile-picture/view-profile-picture.component';
import { ViewProfileOtherComponent } from './private/profile/view/view-profile-other/view-profile-other.component';
import { ViewProfilePartnerPreferenceComponent } from './private/profile/view/view-profile-partner-preference/view-profile-partner-preference.component';
import { ViewProfileReferenceInKeralaComponent } from './private/profile/view/view-profile-reference-in-kerala/view-profile-reference-in-kerala.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenubarModule } from 'primeng/menubar';
import { CandidatePersonalPrimaryInformationComponent } from './private/profile/thirdeye/viewotherprofile/candidate-personal-primary-information/candidate-personal-primary-information.component';
import { CandidatePersonalReligionInformationComponent } from './private/profile/thirdeye/viewotherprofile/candidate-personal-religion-information/candidate-personal-religion-information.component';
import { CandidatePersonalEducationInformationComponent } from './private/profile/thirdeye/viewotherprofile/candidate-personal-education-information/candidate-personal-education-information.component';
import { CandidatePersonalPartnerInformationComponent } from './private/profile/thirdeye/viewotherprofile/candidate-personal-partner-information/candidate-personal-partner-information.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MyMessageInboxTrashComponent } from './private/mymessages/my-message-inbox-trash/my-message-inbox-trash.component';
import { MyMessageSentTrashComponent } from './private/mymessages/my-message-sent-trash/my-message-sent-trash.component';
import { ExpressInterestRecievedTrashComponent } from './private/expressinterest/express-interest-recieved-trash/express-interest-recieved-trash.component';
import { ExpressInterestSentTrashComponent } from './private/expressinterest/express-interest-sent-trash/express-interest-sent-trash.component';
import { RippleModule } from 'primeng/ripple';
import { SpeedDialModule } from 'primeng/speeddial';
import { InplaceModule } from 'primeng/inplace';
import { SearchByIdComponent } from './private/search/search-by-id/search-by-id.component';
import { ContainterLoaderComponent } from './common/reusable/containter-loader/containter-loader.component';
import { SavedSearchesComponent } from './private/search/saved-searches/saved-searches.component';
import { CandidatePersonalImageCollectionComponent } from './private/profile/thirdeye/viewotherprofile/candidate-personal-image-collection/candidate-personal-image-collection.component';
import { PanelModule } from 'primeng/panel';
import { AnimateModule } from 'primeng/animate';
import { GalleriaModule } from 'primeng/galleria';
import { EditorModule } from 'primeng/editor';
import { PublicPageRegisterComponent } from './common/public-register/publicpage/publicpage.component';
import { ChatWindowComponentComponent } from './common/reusable/chat-window-component/chat-window-component.component';
import { ChatButtonComponentComponent } from './common/reusable/chat-button-component/chat-button-component.component';
import { PhotoLibraryComponent } from './common/reusable/photo-library/photo-library.component';
import { IndividualChatWindowComponent } from './common/reusable/individual-chat-window/individual-chat-window.component';
import { CellTemplateDirective } from './common/directive/CellTemplateDirective';
import { SharedCellTemplateComponent } from './common/sharedcomponent/app-shared-cell-template';
import { ViewProfileHomeAddressComponent } from './private/profile/view/view-profile-home-address/view-profile-home-address.component';
import { ViewProfileWorkAddressComponent } from './private/profile/view/view-profile-work-address/view-profile-work-address.component';
import { EditWorkAddressComponent } from './private/profile/editprofile/edit-work-address/edit-work-address.component';
import { EditHomeAddressComponent } from './private/profile/editprofile/edit-home-address/edit-home-address.component';
import { StepperModule } from 'primeng/stepper';
import { initializeSignalR } from './common/reusable/servicecenter/initializeSignalR';
import { SignalRService } from './common/reusable/servicecenter/SignalRService';
import { PublicresetComponent } from './public/publicreset/publicreset.component';
import { PublicpasswordresetboxComponent } from './public/publicpasswordresetbox/publicpasswordresetbox.component';
import { MembershipComponent } from './private/membership/membership.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { CardMemberActionsComponent } from './common/reusable/card-member-actions/card-member-actions.component';
import { FloatingLoaderComponent } from './common/reusable/loader/FloatingLoaderComponent';
import { PaginatorModule } from 'primeng/paginator';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ChipModule } from 'primeng/chip';
import { ReceiverChatWindowComponentComponent } from './common/reusable/receiver-chat-window-component/receiver-chat-window-component.component';
import { SenderChatWindowComponentComponent } from './common/reusable/sender-chat-window-component/sender-chat-window-component.component';
import { LoaderComponentLocal } from './common/reusable/loader/LoaderComponent';

@NgModule({
    declarations: [
        AppComponent,
        PublichomeComponent,
        PublicsearchComponent,
        PublicregisterComponent,
        PublicpaymentoptionsComponent,
        PublicaboutusComponent,
        PubliccontactusComponent,
        PublicpageComponent,
        PublicpagebodyComponent,
        PublicpageheaderComponent,
        PublicpagefooterComponent,
        PrivatepageComponent,
        PrivatepagebodyComponent,
        PrivatepageheaderComponent,
        PrivatepagefooterComponent,
        PageComponent,
        AuthgaurdComponent,
        FooterComponent,
        MenuComponent,
        RatingComponent,
        SearchinmenuComponent,
        TopdashboardComponent,
        TopdashboardloginComponent,
        MyMessagesSentComponent,
        ExpressInterestRecievedComponent,
        ExpressInterestSentComponent,
        BookmarkComponent,
        HomeComponent,
        InboxComponent,
        DeleteprofileComponent,
        EditprofileComponent,
        ModifypasswordComponent,
        ReregisterComponent,
        ViewComponent,
        AdvancedSearchComponent,
        QuickSearchComponent,
        StoragevaultComponent,
        ConfirmModelComponent,
        DisplayModelComponent,
        ResponseModelComponent,
        ConfirmModelYesOrNoComponent,
        PublichomeComponent,
        LoginComponent,
        PublictopmenuComponent,
        SlideshowComponent,
        LoginchomeComponent,
        ProfilesearchhomeComponent,
        LoginaboutComponent,
        LoginhomeoffersComponent,
        BluebuttonComponent,
        TopbarComponent,
        LoaderComponent,
        W3messagedeliverymodelComponent,
        ViewotherprofileComponent,
        UserboardComponent,
        UsertabboardComponent,
        UsermenuboardComponent,
        CandidatefaimlydetailsComponent,
        CandidatecontactdetailsComponent,
        W3modelwithresponseComponent,
        MycardComponent,
        HostmodelComponent,
        SafeHtml,
        ProgressBar,
        SpacerComponent,
        PrivatesearchbyprofileComponent,
        PublicsearchbyprofileComponent,
        MysupermodelComponent,
        ConfirmdeletemodelComponent,
        ExpressinterestComponent,
        SendmessageComponent,
        DeleterecordComponent,
        ViewmessageComponent,
        ViewmessagereadonlyComponent,
        RestoredeleterecordComponent,
        EditBasicProfileComponent,
        EditJobProfileComponent,
        EditFamilyProfileComponent,
        EditContactProfileComponent,
        EditReferenceProfileComponent,
        EditImagesProfileComponent,
        EditOtherProfileComponent,
        EditPartnerProfileComponent,
        LargeImageWindowComponent,
        SendMessageComponent,
        UserBareMinimalProfileComponent,
        HomeBookmarkProfileComponent,
        HomeSavedSearchesComponent,
        HomeMembersWhoViewedContactDetailsComponent,
        HomeContactDetailsViewedByMeComponent,
        HomeRecentVisitorToMyProfileComponent,
        HomeMyRecentVisitsComponent,
        ViewProfileBasicComponent,
        ViewProfileJobInformationComponent,
        ViewProfileFamilyDetailsComponent,
        ViewProfilePostComponent,
        ViewProfilePictureComponent,
        ViewProfileOtherComponent,
        ViewProfilePartnerPreferenceComponent,
        ViewProfileReferenceInKeralaComponent,
        CandidatePersonalPrimaryInformationComponent,
        CandidatePersonalReligionInformationComponent,
        CandidatePersonalEducationInformationComponent,
        CandidatePersonalPartnerInformationComponent,
        MyMessageInboxTrashComponent,
        MyMessageSentTrashComponent,
        ExpressInterestRecievedTrashComponent,
        ExpressInterestSentTrashComponent,
        SearchByIdComponent,
        ContainterLoaderComponent,
        SavedSearchesComponent,
        CandidatePersonalImageCollectionComponent,
        PublicPageRegisterComponent,
        ChatWindowComponentComponent,
        ChatButtonComponentComponent,
        PhotoLibraryComponent,
        IndividualChatWindowComponent,
        CellTemplateDirective,
        SharedCellTemplateComponent,
        ViewProfileHomeAddressComponent,
        ViewProfileWorkAddressComponent,
        EditWorkAddressComponent,
        EditHomeAddressComponent,
        PublicresetComponent,
        PublicpasswordresetboxComponent,
        MembershipComponent,
        CardMemberActionsComponent,
        FloatingLoaderComponent,
        ReceiverChatWindowComponentComponent,
        SenderChatWindowComponentComponent,
        LoaderComponentLocal
    ],
    bootstrap: [AppComponent],
    imports: [BrowserModule, FormsModule, BrowserAnimationsModule,
        AppRoutingModule,
        NgxPaginationModule, ReactiveFormsModule,
        InputTextModule, CardModule, ButtonModule, DropdownModule, CalendarModule, ToastModule, RadioButtonModule, AutoCompleteModule, SelectButtonModule, FieldsetModule, CheckboxModule, TableModule, AccordionModule,
        TabViewModule, InputSwitchModule, TooltipModule, DividerModule, ConfirmDialogModule, DialogModule, ImageModule, InputTextareaModule,
        MessagesModule, FontAwesomeModule,ChipModule,
        MessageModule, ConfirmPopupModule, InputNumberModule, MultiSelectModule, MenubarModule, ProgressSpinnerModule, RippleModule, SpeedDialModule, InplaceModule, PanelModule, AnimateModule, BrowserAnimationsModule,
        GalleriaModule, EditorModule, StepperModule, PaginatorModule, SweetAlert2Module.forRoot()],
    providers: [MenuService, Commonservice, ViewExternalService, ViewInternalService, LocalStore, GaurdService, CommonNewservice, DatePipe, ConfirmationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyHttpInterceptor,
            multi: true
        }, provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
        SignalRService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeSignalR,
            deps: [SignalRService],
            multi: true
        }
    ]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faBars);  // Add the bars icon to the library
    }
}
