<page>
  <ng-template>

    <div>
      <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center">BOOKMARKS
            <i class="pi pi-bookmark"></i>
          </div>

          <div *ngIf="showSearchIcon">
            <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
          </div>
          <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">

            <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
              (ngModelChange)="globalSearch($event)" />

          </div>
        </div>
      </div>
      <!-- <p-divider type="solid"></p-divider> -->
      <p-card legend="" *ngIf="listOfbookmark?.length > 0" style="margin-left: 0px;">

        <mycard>
          <div class="barelist">
            <div *ngFor="
              let bookmark of listOfbookmark | paginate : { itemsPerPage: 10, currentPage: p }
            ">

              <div class="flex flex-wrap align-items-center justify-content-between custom-subset-card">
                <div class="col-12 md:col-2 mb-2 md:mb-0">
                  <div class="flex justify-content-center md:justify-content-start">
                    <div class="col-6 text-left">
                      <img [src]="bookmark.candidateProfileImageReal"
                        (click)="makeitbig(bookmark.candidateProfileImageTmb, bookmark.userWhoGotBookMarked)"
                        class="profile-image thumbnail-image" [ngClass]="
                          bookmark.candidateProfileImageReal
                            ? 'profile-image thumbnail-image'
                            : 'profile-image thumbnail-image-no-cursor'
                        " alt="Candidate Image" />
                    </div>
                  </div>
                </div>

                <div class="col-12 md:col-9">
                  <div class="barelist text-block">
                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">Name</div>
                      <div class="col-12 md:col-9">
                        <strong> : {{ bookmark.candidate_name }}</strong>
                      </div>
                    </div>

                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">ID</div>
                      <div class="col-12 md:col-9">
                        <strong> : {{ bookmark.candidate_Username }}</strong>
                      </div>
                    </div>

                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">Age</div>
                      <div class="col-12 md:col-9">
                        <strong> : {{ bookmark.candidate_Age }}</strong>
                      </div>
                    </div>

                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">Religion</div>
                      <div class="col-12 md:col-9">
                        : {{ bookmark.candidate_Denomination }}
                      </div>
                    </div>

                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">Edu Qualification</div>
                      <div class="col-12 md:col-9">
                        : {{ bookmark.Education }}
                      </div>
                    </div>

                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">Occupation</div>
                      <div class="col-12 md:col-9">
                        : {{ bookmark.candidate_Occupation }}
                      </div>
                    </div>

                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">Work Place</div>
                      <div class="col-12 md:col-9">
                        : {{ bookmark.candidate_Workplace }}
                      </div>
                    </div>

                    <div class="flex flex-column md:flex-row">
                      <div class="col-12 md:col-3">Looking For</div>
                      <div class="col-12 md:col-9">
                        : {{ bookmark.Partner_Expectations_brief }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 md:col-1 text-right">
                  <div class="action-box flex justify-content-between md:justify-content-start">

                    <div *ngIf="bookmark?.showIconEngine">
                      <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                        (click)="setShowIcon(bookmark)"></i>
                    </div>
                    {{bookmark?.showIconEngine}}
                    <div *ngIf="!bookmark.showIconEngine" class="icon-set" @fadeInOutFast>
                      <card-member-actions [userAction]="{
                      fromId: bookmark.UserWhoBookMarked,
                      userToId: bookmark.userWhoGotBookMarked,
                      userToName: bookmark?.candidate_name?.toUpperCase(),
                      ShowSendMessage: false,
                      sendMessageText: sendMessageText ?? '',
                      Id: bookmark.bookMarkId,
                      tableToDelete:'BookMark'
                    }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <!-- <p-divider></p-divider> -->
            </div>

          </div>

          <ImageModelWindow [tc]="imageToDisplay"></ImageModelWindow>
        </mycard>


        <pagination-controls (pageChange)="p = $event"></pagination-controls>


      </p-card>

      <p-toast position="bottom-right"></p-toast>

      <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
        <large-image-window [tc]="imageToDisplay"></large-image-window>
      </p-dialog>


      <div *ngIf="listOfbookmark?.length === 0 && !showLoader" class="no-value-text-ic">
        No Bookmarks yet
      </div>

    </div>

    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>

    <p-confirmDialog></p-confirmDialog>
  </ng-template>
</page>