import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'confirmyesornowindow',
    templateUrl: './confirmModel.component.html',
    styleUrls: ['./model.component.css']
})
export class ConfirmModelYesOrNoComponent implements OnInit {
    @Input() dataToDisplay;
    @Output() confirmaction = new EventEmitter<number>();
    questionToAsk: string;
    OkayId: number;
    ngOnInit(): void {
        const self = this;
        self.questionToAsk = 'Are you interested?';
    }


    show(idToDelete: any) {
        const self = this;
        const modal = document.getElementById('myModalConfirmModelYesOrNo');
        self.OkayId = idToDelete;
        modal.style.display = 'block';
    }

    hide() {
        const modal = document.getElementById('myModalConfirmModelYesOrNo');
        modal.style.display = 'none';
    }
    CallParentMethod(passedValue: number) {
        const self = this;
        self.confirmaction.next(passedValue);
        self.hide();
    }

    closewindow() {
        const modal = document.getElementById('myModalConfirmModelYesOrNo');
        modal.style.display = 'none';
    }
}
