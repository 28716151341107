import { Component, OnInit } from '@angular/core';
import { SaveResponse } from "src/app/dto/SaveResponse";
import { UserVaultModel } from "src/app/dto/UserVaultModel";
import { RegisteredUser } from '../../profile/view/view.components.domain';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { HostWindowModel, ImageToDisplay } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { MessageService } from 'primeng/api';
import { LargerImage } from '../../profile/view/LargerImage';
import { PrefetchBackGroundService } from 'src/app/common/reusable/servicecenter/PrefetchBackGroundService';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { ImageLockRequest } from 'src/app/dto/ImageLockRequest';
import { tap, catchError, throwError, finalize } from 'rxjs';

@Component({
  selector: 'app-storagevault',
  templateUrl: './storagevault.component.html',
  styleUrls: ['./storagevault.component.scss'],
  providers: [MessageService]
})
export class StoragevaultComponent implements OnInit {
  userloggedIn: string;
  storagePage: number = 1;
  public registeredUser: RegisteredUser;
  public ui: UserVaultModel;
  srm: SaveResponse;
  showGrid: boolean = false;
  showLoader = true;
  userId: string;
  showLargeImage = false;
  imageToDisplay: Array<LargerImage>;
  showFloatingLoader = false;
  mainImage: string = "";
  images: Array<string> = [];
  imagesNow: Array<LargerImage> = [];
  imageUrls: Array<string> = [];
  activeIndex = 0; // Default to the first image
  position: string = 'bottom';
  currentDeviceState: string = "";
  image404: string = ";"

  positionOptions = [
    {
      label: 'Bottom',
      value: 'bottom'
    },
    {
      label: 'Top',
      value: 'top'
    },
    {
      label: 'Left',
      value: 'left'
    },
    {
      label: 'Right',
      value: 'right'
    }
  ];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  constructor(private cs: Commonservice, private localStore: LocalStore, private messageService: MessageService, private signalStateService: SignalStateService, private pbgs: PrefetchBackGroundService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();
    self.image404 = this.signalStateService.getValue("404Image")();
    self.registeredUser = {} as RegisteredUser;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserName;
      self.getUserInfo();
    });
    self.imageToDisplay = [];
  }

  async getUserInfo() {
    const self = this;
    self.cs.getUserVault(this.userloggedIn).subscribe(
      data => {
        self.showGrid = true;
        self.run(data);
      },
      error => { }
    );
  }

  refreshBasicProfile() {
    const self = this;
    self.cs.getHomeUserProfile(self.userloggedIn).subscribe(
      data => {
        self.pbgs.updateBasicProfileInStore(data);
      },
      error => {

      }
    );
  }

  onChangeProfileImage(event) {
    const self = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file: any = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.ui.CandidateProfileImageTmbCa = reader.result;
        if (self.ui.CandidateProfileImageTmbCa != undefined && self.ui.CandidateProfileImageTmbCa.toString().length > 0) {
          self.ui.ShowProfileImageTmb = true;
        }
      };
    }
  }

  onChangeAlbumImage1(event) {
    const self = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file: any = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.ui.CandidateAlbumImage1TmbCa = reader.result;
        if (self.ui.CandidateAlbumImage1TmbCa != undefined && self.ui.CandidateAlbumImage1TmbCa.toString().length > 0) {
          self.ui.ShowAlbumImage1Tmb = true;
        }
      };
    }
  }

  onChangeAlbumImage2(event) {
    const self = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file: any = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.ui.CandidateAlbumImage2TmbCa = reader.result;
        if (self.ui.CandidateAlbumImage2TmbCa != undefined && self.ui.CandidateAlbumImage2TmbCa.toString().length > 0) {
          self.ui.ShowAlbumImage2Tmb = true;
        }
      };
    }
  }

  onChangeAlbumImage3(event) {
    const self = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file: any = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.ui.CandidateAlbumImage3TmbCa = reader.result;
        if (self.ui.CandidateAlbumImage3TmbCa != undefined && self.ui.CandidateAlbumImage3TmbCa.toString().length > 0) {
          self.ui.ShowAlbumImage3Tmb = true;
        }
      };
    }
  }

  onChangeAlbumImage4(event) {
    const self = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file: any = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.ui.CandidateAlbumImage4TmbCa = reader.result;
        if (self.ui.CandidateAlbumImage4TmbCa != undefined && self.ui.CandidateAlbumImage4TmbCa.toString().length > 0) {
          self.ui.ShowAlbumImage4Tmb = true;
        }
      };
    }
  }

  onChangeFamilyImage1(event) {
    const self = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file: any = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.ui.CandidateFamilyImage1TmbCa = reader.result;
        if (self.ui.CandidateFamilyImage1TmbCa != undefined && self.ui.CandidateFamilyImage1TmbCa.toString().length > 0) {
          self.ui.ShowFamilyImage1Tmb = true;
        }
      };
    }
  }

  onChangeFamilyImage2(event) {
    const self = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file: any = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        self.ui.CandidateFamilyImage2TmbCa = reader.result;
        if (self.ui.CandidateFamilyImage2TmbCa != undefined && self.ui.CandidateFamilyImage2TmbCa.toString().length > 0) {
          self.ui.ShowFamilyImage2Tmb = true;
        }
      };
    }
  }

  deleteProfileImage() {
    const self = this;
    self.ui.CandidateProfileImageTmb = "";
    self.ui.ShowProfileImageTmb = false;
  }

  deleteAlbum1Image() {
    const self = this;
    self.ui.CandidateAlbumImage1Tmb = "";
    self.ui.ShowAlbumImage1Tmb = false;
  }
  deleteAlbum2Image() {
    const self = this;
    self.ui.CandidateAlbumImage2Tmb = "";
    self.ui.ShowAlbumImage2Tmb = false;
  }
  deleteAlbum3Image() {
    const self = this;
    self.ui.CandidateAlbumImage3Tmb = "";
    self.ui.ShowAlbumImage3Tmb = false;
  }
  deleteAlbum4Image() {
    const self = this;
    self.ui.CandidateAlbumImage4Tmb = "";
    self.ui.ShowAlbumImage4Tmb = false;
  }
  deleteFamily1Image() {
    const self = this;
    self.ui.CandidateFamilyImage1Tmb = "";
    self.ui.ShowFamilyImage1Tmb = false;
  }
  deleteFamily2Image() {
    const self = this;
    self.ui.CandidateFamilyImage2Tmb = "";
    self.ui.ShowFamilyImage2Tmb = false;
  }


  public async run(data: any) {
    const self = this;
    self.ui = data;

    if (self.ui.CandidateProfileImageTmbCa === null) {
      self.ui.ShowProfileImageTmb = false;
    }
    else {
      self.ui.ShowProfileImageTmb = true;
    }

    if (self.ui.CandidateAlbumImage1TmbCa === null) {
      self.ui.ShowAlbumImage1Tmb = false;
    }
    else {
      self.ui.ShowAlbumImage1Tmb = true;
    }
    if (self.ui.CandidateAlbumImage2TmbCa === null) {
      self.ui.ShowAlbumImage2Tmb = false;
    }
    else {
      self.ui.ShowAlbumImage2Tmb = true;
    }

    if (self.ui.CandidateAlbumImage3TmbCa === null) {
      self.ui.ShowAlbumImage3Tmb = false;
    }
    else {
      self.ui.ShowAlbumImage3Tmb = true;
    }

    if (self.ui.CandidateAlbumImage4TmbCa === null) {
      self.ui.ShowAlbumImage4Tmb = false;
    }
    else {
      self.ui.ShowAlbumImage4Tmb = true;
    }

    if (self.ui.CandidateFamilyImage1TmbCa === null) {
      self.ui.ShowFamilyImage1Tmb = false;
    }
    else {
      self.ui.ShowFamilyImage1Tmb = true;
    }

    if (self.ui.CandidateFamilyImage2TmbCa === null) {
      self.ui.ShowFamilyImage2Tmb = false;
    }
    else {
      self.ui.ShowFamilyImage2Tmb = true;
    }

    self.showLoader = false;
  }

  saveInformation() {
    const self = this;
    self.ui.Candidate_Username = self.userloggedIn;

    self.ui.CandidateProfileImageTmb = self.ui.ShowProfileImageTmb ? self.ui.CandidateProfileImageTmbCa : "";
    self.ui.CandidateAlbumImage1Tmb = self.ui.ShowAlbumImage1Tmb ? self.ui.CandidateAlbumImage1TmbCa : "";
    self.ui.CandidateAlbumImage2Tmb = self.ui.ShowAlbumImage2Tmb ? self.ui.CandidateAlbumImage2TmbCa : "";
    self.ui.CandidateAlbumImage3Tmb = self.ui.ShowAlbumImage3Tmb ? self.ui.CandidateAlbumImage3TmbCa : "";
    self.ui.CandidateAlbumImage4Tmb = self.ui.ShowAlbumImage4Tmb ? self.ui.CandidateAlbumImage4TmbCa : "";
    self.ui.CandidateFamilyImage1Tmb = self.ui.ShowFamilyImage1Tmb ? self.ui.CandidateFamilyImage1TmbCa : "";
    self.ui.CandidateFamilyImage2Tmb = self.ui.ShowFamilyImage2Tmb ? self.ui.CandidateFamilyImage2TmbCa : "";
    self.showLoader = true;
    self.cs.savevaultinfo(self.ui).subscribe(
      data => {
        self.srm = data;

        if (self.srm.InsertWorked) {
          self.getUserInfo();
          self.refreshBasicProfile();
        }
        else {

        }
      },
      error => { }
    );
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    self.showLoader = true;
    if (imageThumbnailId != null) {
      self.cs.getLargeImageEx(imageThumbnailId).subscribe(
        data => {
          self.showLargeImage = true;
          self.showLoader = false;
          self.imageToDisplay = [];
          self.imageToDisplay.push(...data);
          self.imageUrls = [];
          self.activeIndex = data.findIndex(x => x.Name === imageThumbnailId);
          self.imageUrls.push(...data.map(x => x.MainImage));
        },
        error => {
          self.showLargeImage = true;
        }
      );
    }
    else {
      self.showLoader = false;
      this.messageService.add({ severity: 'info', summary: 'Service Message', detail: "no image" });
    }
  }

  // Convert base64 string to blob
  base64toBlob(base64String, contentType = "image/jpeg") {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // Convert blob to object URL
  blobToObjectURL(blob) {
    return URL.createObjectURL(blob);
  }

  getImages(thumbNailImages: Array<LargerImage>): Array<LargerImage> {
    let something: Array<LargerImage> = [];
    this.images = [];
    this.images.push(...thumbNailImages?.map((base64String: LargerImage) => {
      const blob = this.base64toBlob(base64String.MainImage);
      return this.blobToObjectURL(blob);
    }));
    this.images?.forEach(image => {
      let img = new LargerImage();
      img.MainImage = image;
      something.push(img);
    });

    return something;
  }

  userUnLockOrUnlockImage(image: UserVaultModel, from: string) {
    this.toggleImageLock(image, from).subscribe(response => {
      this.getUserInfo();
      if (response.InsertWorked) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.Message });
      }
      else {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: response.Message });
      }
      this.showLoader = false;
    },
      () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Due to technical issue, the action failed" });
        this.showLoader = false;
      });
  };

  toggleImageLock(image: UserVaultModel, imageType: string) {
    const config = IMAGE_CONFIGS[imageType];
    if (!config) {
      throw new Error(`Invalid image type: ${imageType}`);
    }

    const userLockField = config.userLockField as keyof UserVaultModel;
    const adminLockField = config.adminLockField as keyof UserVaultModel;
    const imageNameField = config.imageName as keyof UserVaultModel;

    const ilr: ImageLockRequest = {
      imageId: image.ImageId,
      UserLocked: !(image[userLockField] as boolean),
      lockedBy: this.userloggedIn,
      imageName: image[imageNameField] as string | ArrayBuffer | null
    };

    this.showLoader = true;

    return this.cs.UpdateImageLockStatusAsync(ilr).pipe(
      tap(response => {
        // Store the boolean values in separate variables
        const newUserLockStatus = !(image[userLockField] as boolean);
        const newAdminLockStatus = !(image[adminLockField] as boolean);

        // Assign the new values
        (image[userLockField] as boolean) = newUserLockStatus;
        (image[adminLockField] as boolean) = newAdminLockStatus;

        this.messageService.add({
          severity: response.InsertWorked ? 'success' : 'error',
          summary: response.InsertWorked ? 'Success' : 'Error',
          detail: response.Message
        });
      }),
      catchError(error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Due to technical issue, the action failed'
        });
        return throwError(error);
      }),
      finalize(() => {
        this.showLoader = false;
      })
    );
  }
}

const IMAGE_CONFIGS: Record<string, ImageConfig> = {
  'profile': {
    imageName: 'CandidateProfileImageTmb',
    userLockField: 'CandidateProfileImageTmbUserLocked',
    adminLockField: 'CandidateProfileImageTmbAdminLocked'
  },
  'album1': {
    imageName: 'CandidateAlbumImage1Tmb',
    userLockField: 'CandidateAlbumImage1TmbUserLocked',
    adminLockField: 'CandidateAlbumImage1TmbAdminLocked'
  },
  'album2': {
    imageName: 'CandidateAlbumImage2Tmb',
    userLockField: 'CandidateAlbumImage2TmbUserLocked',
    adminLockField: 'CandidateAlbumImage2TmbAdminLocked'
  },
  'album3': {
    imageName: 'CandidateAlbumImage3Tmb',
    userLockField: 'CandidateAlbumImage3TmbUserLocked',
    adminLockField: 'CandidateAlbumImage3TmbAdminLocked'
  },
  'album4': {
    imageName: 'CandidateAlbumImage4Tmb',
    userLockField: 'CandidateAlbumImage4TmbUserLocked',
    adminLockField: 'CandidateAlbumImage4TmbAdminLocked'
  },
  'family1': {
    imageName: 'CandidateFamilyImage1Tmb',
    userLockField: 'CandidateFamilyImage1TmbUserLocked',
    adminLockField: 'CandidateFamilyImage1TmbAdminLocked'
  },
  'family2': {
    imageName: 'CandidateFamilyImage2Tmb',
    userLockField: 'CandidateFamilyImage2TmbUserLocked',
    adminLockField: 'CandidateFamilyImage2TmbAdminLocked'
  }
};

interface ImageConfig {
  imageName: keyof UserVaultModel;
  userLockField: keyof UserVaultModel;
  adminLockField: keyof UserVaultModel;
}
